import {
  AGENTS_OPEN_FORM_DIALOG,
  CHANGE_AGENT_DELETE_DIALOG,
  OPEN_SNACKBAR,
  GET_AGENTS_SUCCESS,
  GET_AGENTS_FAILED,
  LOADING_AGENTS,
  AGENTS_FAILED,
  AGENTS_CREATED_SUCCESS,
  DELETED_AGENTS_SUCCESS,
  CHANGE_DISABLED_DIALOG,
  AGENTS_UPADTE_FORM_DIALOG,
  UPDATE_AGENTS_SUCCESS,
  ERROR_AGENT_PHONE,
  GET_AGENTS,
} from '../types';
import {
  ENDPOINT_IMPORT,
  ENDPOINT_AGENT,
  PREFIX_ADMIN,
  PAGE,
  ENDPOINT_PER_PAGE,
  SEARCH,
  ORDER_DIRECTION,
  ORDER_BY,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  getFirstErrorFromResponse,
  ERROR_NETWORK,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
  DEFAULT_ORDER_DIRECTION,
} from '../../helpers';

/**
 * Action Creator to get all agents
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchAgents(
  page,
  perPage = 15,
  search = '',
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENT}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${SEARCH}${search}`;
    if (orderBy)
      apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENT}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${SEARCH}${search}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_AGENTS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_AGENTS_FAILED });
      });
  };
}
/**
 * Action Creator to create agent
 *
 * @param {String} name
 * @param {String} adress
 * @param {String} email
 * @param {string} phone
 * @param {int} codeP
 * @param {int} deleg
 * @param {string} note
 * @param {int} page
 * @param {int} perPage
 * @param {string} search
 *
 * @returns function contains an action
 */
export function createAgent(
  name,
  phone,
  address,
  codeP,
  note,
  deleg,
  page,
  perPage,
  search,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    dispatch({ type: LOADING_AGENTS });
    let formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('phone_num', phone);
    formData.append('delegation_id', deleg);
    formData.append('note', note);

    if (codeP) {
      formData.append('zip_code', codeP);
    }

    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENT}`;
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchAgents(page, perPage, search, orderBy, orderDirection));
        dispatch({ type: AGENTS_CREATED_SUCCESS, payload: response.data.data });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'agents.created',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: AGENTS_FAILED });
      });
  };
}

/**
 * Action Creator to delete agent and call fetchAgents action to reload data
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 * @param {string} search
 *
 * @returns function contains an action
 */
export const deleteAgent = (
  id,
  currentPage,
  perPage,
  search,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENT}/${id}`;
    methodsServices
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(
          fetchAgents(currentPage, perPage, search, orderBy, orderDirection)
        );
        dispatch({ type: DELETED_AGENTS_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'common.deleted',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      });
  };
};
/**
 * Action Creator to update agent
 *
 * @param {String} name
 * @param {String} adress
 * @param {String} email
 * @param {string} phone
 * @param {int} codeP
 * @param {int} deleg
 * @param {string} note
 * @param {int} page
 * @param {int} perPage
 * @param {string} search
 * @param {int} id
 *
 * @returns function contains an action
 */
export function updateAgent(
  name,
  phone,
  address,
  codeP,
  note,
  deleg,
  page,
  perPage,
  search,
  id,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    dispatch({ type: LOADING_AGENTS });
    let formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('phone_num', phone);
    formData.append('delegation_id', deleg);
    formData.append('note', note);

    if (codeP) {
      formData.append('zip_code', codeP);
    }

    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENT}/${id}`;
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchAgents(page, perPage, search, orderBy, orderDirection));
        dispatch({ type: UPDATE_AGENTS_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'clients.updated',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        if (getFirstErrorFromResponse(error) === 'phone_num.unique')
          dispatch(errorAgentPhone('agents.unique.phone'));
        dispatch({ type: AGENTS_FAILED });
      });
  };
}
/**
 * Action creator to upload csv file
 * @param {file} value
 *
 * @returns an action
 */
export const uploadCsv = (
  value,
  page,
  perPage,
  search,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('file', value);
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENT}${ENDPOINT_IMPORT}`;

    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        const {
          insertedAgentsCount,
          duplicatePhoneLines,
          invalidLines,
        } = response.data.data;
        if (insertedAgentsCount !== 0) {
          dispatch({ type: GET_AGENTS });
          dispatch(fetchAgents(page, perPage, search, orderBy, orderDirection));
          //CASE ALL THE LINES IN THE FILE IMPORTED
          if (duplicatePhoneLines.length === 0 && invalidLines.length === 0)
            dispatch({
              type: OPEN_SNACKBAR,
              payload: 'agents.uploaded',
              varaible: insertedAgentsCount,
              severity: SERVERITY_SUCCESS,
            });
          //CASE NOT ALL THE LINES IN THE FILE IMPORTED
          else
            dispatch({
              type: OPEN_SNACKBAR,
              payload: 'agents.notAll.uploaded',
              varaible: {
                lignes:
                  duplicatePhoneLines.length !== 0
                    ? duplicatePhoneLines
                    : invalidLines,
              },
              severity: SERVERITY_SUCCESS,
            });
        }
        //CASE  ALL THE FILE IS INVALID
        else if (invalidLines.length !== 0 && duplicatePhoneLines.length === 0)
          dispatch({
            type: OPEN_SNACKBAR,
            payload: 'agents.notUploaded',
            severity: SERVERITY_ERROR,
          });
        //CASE  INVALID LINE IN THE FILE ( EMPTY NAME ...)
        else if (invalidLines.length !== 0)
          dispatch({
            type: OPEN_SNACKBAR,
            payload: 'common.csv.invalid.line',
            varaible: { lignes: invalidLines },
            severity: SERVERITY_ERROR,
          });
        //CASE WHEN WE HAVE A DUPLICATE PHONE
        else if (duplicatePhoneLines.length !== 0) {
          dispatch({
            type: OPEN_SNACKBAR,
            payload: 'common.duplicate.phone',
            varaible: { lignes: duplicatePhoneLines },
            severity: SERVERITY_ERROR,
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
      });
  };
};
/**
 * Action Creator to open and close the Form dialog (agent)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const agentFormDialog = (value) => {
  return {
    type: AGENTS_OPEN_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the delete dialog (agent)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogAgent = (value) => {
  return {
    type: CHANGE_AGENT_DELETE_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the update dialog (agent)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const AgentUpdateFormDialog = (value) => {
  return {
    type: AGENTS_UPADTE_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to handle the error of phone (agent)
 *
 * @param {string} value
 *
 * @returns an action
 */
export const errorAgentPhone = (value) => {
  return {
    type: ERROR_AGENT_PHONE,
    payload: value,
  };
};

import React, { Fragment } from "react";
import { withI18n } from "react-i18next";
import {
	AppBar,
	Tabs,
	Tab,
	createMuiTheme,
	MuiThemeProvider,
	makeStyles,
	Grid,
	Paper,
} from "@material-ui/core";
import {
	SECONDARY_COLOR,
	RUBY_COLOR,
	FONT_SIZE_LARGE,
	getAccessiblePanels,
} from "../helpers";
import { dashboardPanels } from "../helpers/dashboardPanels";
import { TabPanel } from "../components";

/**
 * Component Home
 *
 * @component
 *
 * @example
 * return (
 *   <Home/>
 * )
 */
const useStyles = makeStyles((theme) => ({
	root: {
		margin: "auto",
		[theme.breakpoints.down("md")]: {
			margin: 0,
		},
	},
	appBar: {
		backgroundColor: "#fec94e",
	},
	gridStyle: {
		display: "none",
	},

	"@media screen and (max-width:957px)": {
		gridStyle: {
			display: "block",
		},
		paperTitleStyle: { display: "flex", justifyContent: "center" },
		typographyStyle: {
			marginTop: 10,
			marginBottom: 10,
			color: RUBY_COLOR,
			" font-size": FONT_SIZE_LARGE,
			"font-weight": "700",
		},
	},
}));

function Home({ t }) {
	const [value, setValue] = React.useState(0);
	const classes = useStyles();
	const panels = getAccessiblePanels();
	const theme = createMuiTheme({
		palette: {
			secondary: {
				main: RUBY_COLOR,
			},
		},
	});
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Fragment>
			<Grid className={classes.gridStyle}>
				<Paper className={classes.paperTitleStyle}>
					<div className={classes.typographyStyle}>{t("dashboard.title")}</div>
				</Paper>
			</Grid>
			<MuiThemeProvider theme={theme}>
				<AppBar position="static" className={classes.appBar}>
					<Tabs
						variant="fullWidth"
						className={classes.root}
						value={value}
						onChange={handleChange}
						indicatorColor={SECONDARY_COLOR}
						textColor={SECONDARY_COLOR}
						variant="scrollable"
						scrollButtons="auto"
					>
						{panels.map((item, index) => {
							return <Tab key={index} label={t(item.title)} />;
						})}
					</Tabs>
				</AppBar>
			</MuiThemeProvider>
			{panels.map((item, index) => {
				return (
					<TabPanel value={value} index={index} key={"panel-" + index}>
						{item.panelComponent}
					</TabPanel>
				);
			})}
		</Fragment>
	);
}
const connectedHomePage = withI18n()(Home); //Higher-Order Component
export { connectedHomePage as Home };

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  CssBaseline,
  Container,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { authActions } from '../../../store/actions';
import { withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import '../../../assets/sass/login.scss';
import logo from '../../../assets/img/logo-trans.png';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { CLEAR_ERRORS } from '../../../store/types';
import { styles } from './style';
import {
  history,
  VARIANT_CONTAINED,
  VARIANT_OUTLINED,
  SECONDARY_COLOR,
  VARIANT_SUBTITLE_ONE,
  ERROR_COLOR,
  INPUT_REQUIRED,
} from '../../../helpers';

/**
 * Component Forgot password
 *
 * @component
 *
 * @example
 * return (
 *   <ForgotPassword />
 * )
 */
class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      loading: props.loading,
      emailError: '',
    };

    const { dispatch } = this.props;
    dispatch({ type: CLEAR_ERRORS });
  }
  handleChange = (prop) => (event) => {
    const { dispatch } = this.props;
    dispatch({ type: CLEAR_ERRORS });
    this.setState({
      emailError: '',
      [prop]: event.target.value,
    });
  };
  goToLogin = (prop) => (event) => {
    const { dispatch } = this.props;
    dispatch({ type: CLEAR_ERRORS });
    history.push('/');
  };
  sendMail = (event) => {
    const { email } = this.state;
    const { dispatch } = this.props;
    if (!email) {
      this.setState({
        emailError: 'login.email.required.error',
      });
    } else {
      dispatch(authActions.sendMail(email));
    }
  };

  render() {
    const { classes, t, loading, errors } = this.props;
    return (
      <div className="login">
        <Box>
          <Box px={5} pt={5} pb={3} className={classes.loginButtonContainer}>
            <img src={logo} alt="My Logo" />
          </Box>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <Grid item md={8} xs={12}>
                <Paper>
                  <Grid
                    container
                    alignContent="center"
                    alignItems="center"
                    justify="center"
                  >
                    <Grid item md={1}>
                      <IconButton
                        className="return-btn"
                        size="medium"
                        onClick={this.goToLogin()}
                      >
                        <ArrowBackIosIcon />
                      </IconButton>
                    </Grid>
                    <Grid item md={11}>
                      <Box textAlign="center" py={5}>
                        <Typography variant="h4">
                          <Box fontWeight={600} letterSpacing={3}>
                            {t('login.forgot.password')}
                          </Box>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box
                    textAlign="center"
                    className={classes.loginFormContainer}
                  >
                    <TextField
                      variant={VARIANT_OUTLINED}
                      margin="none"
                      value={this.state.email}
                      fullWidth
                      id="email"
                      label={t('login.email') + INPUT_REQUIRED}
                      name="email"
                      type="email"
                      onChange={this.handleChange('email')}
                      helperText={t(this.state.emailError)}
                      error={this.state.emailError || errors ? true : false}
                    />

                    {errors && (
                      <Typography
                        variant={VARIANT_SUBTITLE_ONE}
                        color={ERROR_COLOR}
                        gutterBottom
                      >
                        {t(errors)}
                      </Typography>
                    )}
                    <Box mt={2}>
                      <Button
                        disabled={loading}
                        type="submit"
                        className="login-btn"
                        variant={VARIANT_CONTAINED}
                        onClick={(event) => {
                          this.sendMail();
                        }}
                      >
                        {t('common.confirm')}
                        {loading && (
                          <CircularProgress size={20} color={SECONDARY_COLOR} />
                        )}
                      </Button>
                    </Box>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    );
  }
}
ForgotPassword.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication;
  const { loading, errors } = state.ui;
  return {
    loggingIn,
    loading,
    errors,
  };
};
const connectedForgotPasswordPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withI18n()(withStyles(styles)(ForgotPassword)))
);
export { connectedForgotPasswordPage as ForgotPassword };

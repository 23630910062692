import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicTable } from '..';
import {
  RESET_KEYS,
  PANEL_INFO_TABLE_HEADER,
  INFO_TABLE_TRI_BY,
  PANEL_INFO_KEYS,
  INFO_KEYS,
  KEYS_CREATED_AT,
} from '../../helpers/constantes';
import { fetchInfoPanel, downloadFile } from '../../store/actions';
import {
  changeFormatDate,
  chartData,
  ENDPOINT_STATISTIC,
  ENDPOINT_STATISTIC_INFO,
  EXPORT,
} from '../../helpers';
import { LineChart } from '../lineChart/LineChart';

/**
 * Component ResetPasswordPanel
 *
 * @component
 *
 * @example
 * return (
 *   <ResetPasswordPanel/>
 * )
 */
function InfoRequestPanel({ t }) {
  const dispatch = useDispatch();
  const resetPassword = useSelector((state) => state.resetPassword);
  const infoPanel = useSelector((state) => state.infoPanel);
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [startAtError, setStartAtError] = useState('');
  const [endAtError, setEndAtError] = useState('');
  const [startAtFormat, setStartAtFormat] = useState('');
  const [endAtFormat, setEndAtFormat] = useState('');
  const [disbaleSecondDate, setDisabledSecondDate] = useState(true);
  const [dataChart, setDataChart] = useState({});
  const [loadingChart, setloadingChart] = useState(true);
  const download = useSelector((state) => state.download);

  //Costomize options of lineChart
  const options = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: t('informations.statistics'),
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            precision: 0,
          },
        },
      ],
    },
  };
  useEffect(() => {
    dispatch(
      fetchInfoPanel(
        infoPanel.currentPage,
        infoPanel.perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  }, []);
  useEffect(() => {
    if (Object.values(infoPanel.statistics).length !== 0)
      setloadingChart(false);
    const data = Object.values(infoPanel.statistics);
    setDataChart(chartData(data));
  }, [infoPanel.statistics]);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchInfoPanel(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchInfoPanel(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };

  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('informations.date'):
        return KEYS_CREATED_AT;
      case t('informations.lastName'):
        return INFO_KEYS[1];
      default:
        return INFO_KEYS[2];
    }
  };
  /**
   *  get list of info using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleTriByKey = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchInfoPanel(
        infoPanel.currentPage,
        infoPanel.perPage,
        getColumnName(orderBy),
        orderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //When the user change the data start
  const handleChangeStartAt = (date) => {
    setStartAt(date.target.value);
    setStartAtFormat(changeFormatDate(date.target.value));
    setEndAt('');
    setStartAtError('');
    if (disbaleSecondDate) setDisabledSecondDate(false);
  };
  //When the user change the data start
  const handleChangeEndAt = (date) => {
    setEndAtError('');
    setEndAt(date.target.value);
    setEndAtFormat(changeFormatDate(date.target.value));
  };
  //Handle button done
  const handleBtnDone = () => {
    if (!startAt) {
      setStartAtError('common.starts_at.error');
      setEndAtError('common.ends_at.error');
    } else if (!endAt) setEndAtError('common.ends_at.error');
    else {
      dispatch(
        fetchInfoPanel(
          infoPanel.currentPage,
          infoPanel.perPage,
          tableOrderBy,
          tableOrderDirection,
          startAtFormat,
          endAtFormat
        )
      );
    }
  };
  const handleExportToExcel = () => {
    if (startAt && !endAt) {
      setEndAtError('common.ends_at.error');
    } else {
      dispatch(
        downloadFile(
          startAtFormat,
          endAtFormat,
          `${ENDPOINT_STATISTIC}${ENDPOINT_STATISTIC_INFO}${EXPORT}`
        )
      );
    }
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={PANEL_INFO_TABLE_HEADER}
        keys={PANEL_INFO_KEYS}
        visibilityIcon={false}
        editIcon={false}
        deleteIcon={false}
        displayHeaderComponent={true}
        tbBody={infoPanel.data}
        rowsPerPage={infoPanel.perPage}
        total={infoPanel.total}
        currentPage={infoPanel.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        displayAddOrApplyBtn={true}
        textBtnAddOrApply={'common.enter'}
        loading={infoPanel.loading}
        tbTriBy={INFO_TABLE_TRI_BY}
        fetchDataBy={handleTriByKey}
        displayAddIcon={false}
        secondLabelOfPicker={'common.ends_at'}
        firstLabelOfPicker={'common.starts_at'}
        selectedFirstDate={startAt}
        selectedSecondDate={endAt}
        changeFistDate={handleChangeStartAt}
        changeSecondDate={handleChangeEndAt}
        disabledSecondDatePicker={disbaleSecondDate}
        diplayPickerUi={true}
        loadingLastBtn={infoPanel.loadingLastBtn}
        openFormDialog={handleBtnDone}
        selectedFirstDateError={startAtError}
        selectedSecondDateError={endAtError}
        displayRowsInfoRequestPanel={true}
        countStatueProcessed={infoPanel.countStatueProcessed}
        countStatueWaiting={infoPanel.countStatueWaiting}
        userOffline={infoPanel.offline}
        userOnline={infoPanel.online}
        displayExportButton={true}
        handleDisplayExportButton={handleExportToExcel}
        disabledExportBtn={download.loading}
      />
      {!infoPanel.loading ? (
        infoPanel.data.length !== 0 ? (
          <LineChart
            data={dataChart}
            options={options}
            loading={loadingChart}
          />
        ) : null
      ) : (
        <LineChart data={dataChart} options={options} loading={loadingChart} />
      )}
    </Fragment>
  );
}
const connectedInfoRequestPanel = withI18n()(InfoRequestPanel); //Higher-Order Component
export { connectedInfoRequestPanel as InfoRequestPanel };

import React, { Fragment, useState } from 'react';
import { withI18n } from 'react-i18next';

import {
  Paper,
  Input,
  InputAdornment,
  IconButton,
  Button,
  FormControlLabel,
  Checkbox,
  FormLabel,
  Grid,
  CircularProgress,
  Typography,
  Tooltip,
} from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import SettingsIcon from '@material-ui/icons/Settings';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import GetAppIcon from '@material-ui/icons/GetApp';
import { useStyles } from './styles';
import {
  SECONDARY_COLOR,
  VARIANT_CONTAINED,
  SMALL_SIZE,
  SET_TIME_OUT,
  VARIANT_BUTTON,
} from '../../helpers';
import { PickersUi } from '../ui/PickersUi';

/**
 * Component global page header
 *
 * @component
 *
 * @example
 * return (
 *   <HeaderComponent/>
 * )
 */
function HeaderComponent({
  t,
  displayFilter = false,
  displaySearch = false,
  displayUploadBtn = false,
  displayConfigButton = false,
  displayAddOrApplyBtn = false,
  textBtnUpload = 'common.upload.csv',
  textBtnAddOrApply = 'common.upload.csv',
  configButtonText,
  title,
  handleAddOrApplyBtn,
  handleSearch,
  onChangeUploadBtn,
  onChangeConfigButton,
  handleFirstCheckBox,
  handleSecondCheckBox,
  checkedFirstCheckBox,
  checkedSecondtCheckBox,
  loadingLastBtn = false,
  name = '',
  diplayPickerUi = false,
  displayAddIcon = true,
  firstLabelOfPicker,
  secondLabelOfPicker,
  selectedFirstDate,
  selectedSecondDate,
  changeFistDate,
  changeSecondDate,
  disabledSecondDatePicker,
  selectedFirstDateError,
  selectedSecondDateError,
  displayExportButton = false,
  handleDisplayExportButton,
  disabledExportBtn = false,
}) {
  const classes = useStyles();
  const [typingTimeout, setTypingTimeout] = useState(0);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  const handleOnChangeSearch = (e) => {
    let message = e.target.value;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        handleSearch(message);
      }, SET_TIME_OUT)
    );
  };
  return (
    <Fragment>
      {' '}
      {title && (
        <Grid className={classes.gridStyle}>
          <Paper className={classes.paperTitleStyle}>
            <div className={classes.typographyStyle}>{t(title)}</div>
          </Paper>
        </Grid>
      )}
      <Paper className={classes.paperStyle}>
        {name && (
          <Typography variant={VARIANT_BUTTON} className={classes.nameStyle}>
            {name}
          </Typography>
        )}
        {displayConfigButton && (
          <Fragment>
            <Button
              startIcon={<SettingsIcon />}
              size={SMALL_SIZE}
              variant={VARIANT_CONTAINED}
              className={classes.configButton}
              onClick={onChangeConfigButton}
            >
              {t(configButtonText)}
            </Button>
          </Fragment>
        )}
        {diplayPickerUi && (
          <Fragment>
            <PickersUi
              label={firstLabelOfPicker}
              selectedDate={selectedFirstDate}
              handleDateChange={changeFistDate}
              selectedDateError={selectedFirstDateError}
            />
            <PickersUi
              label={secondLabelOfPicker}
              selectedDate={selectedSecondDate}
              handleDateChange={changeSecondDate}
              disabled={disabledSecondDatePicker}
              minDate={selectedFirstDate}
              selectedDateError={selectedSecondDateError}
            />
          </Fragment>
        )}
        {displaySearch ? (
          <form className={classes.searchStyle} onSubmit={handleSubmit}>
            <Input
              placeholder={t('common.search')}
              className={classes.inputSearchStyle}
              onChange={handleOnChangeSearch}
              color={SECONDARY_COLOR}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton>
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </form>
        ) : (
          <div></div>
        )}

        <div className={classes.firstButtonStyle}>
          {displayExportButton &&
            (!disabledExportBtn ? (
              <Tooltip
                title={t('common.export')}
                className={classes.tooltipStyle}
              >
                <IconButton
                  onClick={handleDisplayExportButton}
                  disabled={disabledExportBtn}
                >
                  <GetAppIcon className={classes.importExportIconStyle} />
                </IconButton>
              </Tooltip>
            ) : (
              <CircularProgress
                className={classes.tooltipStyle}
                color={SECONDARY_COLOR}
                size={25}
              />
            ))}
          {displayUploadBtn && (
            <Fragment>
              <input
                hidden
                accept=".csv"
                id="contained-button-file"
                multiple
                type="file"
                onChange={(e) => onChangeUploadBtn(e)}
              />
              <label htmlFor="contained-button-file">
                <Button
                  endIcon={<CloudUploadIcon />}
                  component="span"
                  size={SMALL_SIZE}
                  variant={VARIANT_CONTAINED}
                  className={classes.uploadButton}
                >
                  {t(textBtnUpload)}
                </Button>
              </label>
            </Fragment>
          )}
          {displayAddOrApplyBtn && (
            <div className={classes.wrapper}>
              <Button
                endIcon={displayAddIcon ? <AddIcon /> : null}
                className={classes.lastButtonStyle}
                size={SMALL_SIZE}
                variant={VARIANT_CONTAINED}
                color={SECONDARY_COLOR}
                onClick={handleAddOrApplyBtn}
                disabled={loadingLastBtn}
              >
                {t(textBtnAddOrApply)}
              </Button>
              {loadingLastBtn && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </div>
          )}
          {displayFilter && (
            <div className={classes.boxStyle}>
              <FormLabel component="legend">{t('common.etat')}</FormLabel>
              <FormControlLabel
                control={<Checkbox checked={checkedFirstCheckBox} />}
                label={t('common.waiting')}
                onChange={handleFirstCheckBox}
              />
              <FormControlLabel
                control={<Checkbox checked={checkedSecondtCheckBox} />}
                label={t('common.treaties')}
                onChange={handleSecondCheckBox}
              />
            </div>
          )}
        </div>
      </Paper>
    </Fragment>
  );
}
const HeaderComponentHOC = withI18n()(HeaderComponent); //Higher-Order Component
export { HeaderComponentHOC as HeaderComponent };

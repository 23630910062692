import React, { Fragment } from "react";
import { withI18n } from "react-i18next";
import { useSelector } from "react-redux";
import { CircularProgress, Typography, Checkbox } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {
	SECONDARY_COLOR,
	KEYS_CHECKBOX,
	ALIGN_RIGHT,
} from "../../helpers";
import { useStyles } from "./styles";

/**
 *
 * @component
 *
 * @example
 * return (
 *   <BodyFormTable/>
 * )
 */
function BodyFormTable({
	tbHeader,
	keys = [],
	tbBody = [],
	loading,
	t,
	handleChangeCheck,
	values = [],
	type,
	value = null,
}) {
	const classes = useStyles();
	const clients = useSelector((state) => state.clients);

	//function contain the logic where the columns of a particular row will be returned
	const RenderRow = (props) => {
		return keys.map((key, index) => {
			switch (key) {
				case KEYS_CHECKBOX:
					return (
						<TableCell
							align={ALIGN_RIGHT}
							key={index}
						>
							<Checkbox
								color={SECONDARY_COLOR}
								onChange={(event) => handleChangeCheck(event, props.data)}
								inputProps={{ "aria-label": "primary checkbox" }}
								checked={isChecked(props.data)}
							/>
						</TableCell>
					);
				default:
					return <TableCell key={index}>{props.data[key]}</TableCell>;
			}
		});
	};
	//return the all the row components.
	const getRowsData = () => {
		return tbBody.map((row, index) => {
			return (
				<TableRow key={index} className={classes.formRow}>
					<RenderRow key={index} data={row} keys={keys} />
				</TableRow>
			);
		});
	};
	const isChecked = (data) => {
		if (type == "single" && data.id === value) {
			return true;
		} else if (data && data.id) {
			return values.includes(data.id);
		}
		return false;
	};

	const RenderBodyTable = () => {
		if (loading)
			return (
				<Fragment>
					<TableRow>
						<TableCell
							colSpan={tbHeader.length}
							
						>
							<div className={classes.spinnerStyle}>
								<CircularProgress color={SECONDARY_COLOR} />
							</div>
						</TableCell>
					</TableRow>
				</Fragment>
			);
		else if (tbBody.length > 0) return <Fragment>{getRowsData()}</Fragment>;
		else
			return (
				<Fragment>
					<TableRow>
						<TableCell colSpan={tbHeader.length}>
							<Typography
								className={classes.spinnerStyle}
								color={SECONDARY_COLOR}
							>
								{t("common.empty.data")}
							</Typography>
						</TableCell>
					</TableRow>
				</Fragment>
			);
	};
	return <TableBody>{RenderBodyTable()}</TableBody>;
}
const BodyFormTableHOC = withI18n()(BodyFormTable); //Higher-Order Component
export { BodyFormTableHOC as BodyFormTable };

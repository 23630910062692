import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicTable } from '..';
import {
  INSCRIPTION_STATISTICS_TABLE_HEADER,
  INSCRIPTION_STATISTICS_TABLE_TRI_BY,
  INSCRIPTION_STATISTICS_KEYS,
  INSCRIPTION_DATE,
  changeFormatDate,
  chartData,
  ENDPOINT_STATISTIC,
  ENDPOINT_INSCRIPTION_STATISTICS,
  EXPORT,
} from '../../helpers';
import { fetchInscriptionStatistics, downloadFile } from '../../store/actions';
import { LineChart } from '../lineChart/LineChart';

/**
 * Component InscriptionStatisticsPanel
 *
 * @component
 *
 * @example
 * return (
 *   <InscriptionStatisticsPanel/>
 * )
 */
function InscriptionStatisticsPanel({ t }) {
  const dispatch = useDispatch();
  const resetPassword = useSelector((state) => state.resetPassword);
  const inscriptionStatistics = useSelector(
    (state) => state.inscriptionStatistics
  );
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [startAtError, setStartAtError] = useState('');
  const [endAtError, setEndAtError] = useState('');
  const [startAtFormat, setStartAtFormat] = useState('');
  const [endAtFormat, setEndAtFormat] = useState('');
  const [disbaleSecondDate, setDisabledSecondDate] = useState(true);
  const [dataChart, setDataChart] = useState({});
  const [loadingChart, setloadingChart] = useState(true);
  const download = useSelector((state) => state.download);

  //Costomize options of lineChart
  const options = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: t('clients.statistics.chart.title'),
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            precision: 0,
          },
        },
      ],
    },
  };
  useEffect(() => {
    dispatch(
      fetchInscriptionStatistics(
        inscriptionStatistics.currentPage,
        inscriptionStatistics.perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  }, []);
  useEffect(() => {
    if (Object.values(inscriptionStatistics.statistics).length !== 0)
      setloadingChart(false);
    const data = Object.values(inscriptionStatistics.statistics);
    setDataChart(chartData(data));
  }, [inscriptionStatistics.statistics]);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchInscriptionStatistics(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchInscriptionStatistics(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };

  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('clients.inscription.date'):
        return INSCRIPTION_DATE;
      case t('clients.inscription.ready.code'):
        return INSCRIPTION_STATISTICS_KEYS[1];
      case t('clients.inscription.username'):
        return INSCRIPTION_STATISTICS_KEYS[2];
      case t('clients.inscription.phone'):
        return INSCRIPTION_STATISTICS_KEYS[3];
      default:
        return INSCRIPTION_STATISTICS_KEYS[0];
    }
  };

  /**
   *  get list using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleTriByKey = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchInscriptionStatistics(
        inscriptionStatistics.currentPage,
        inscriptionStatistics.perPage,
        getColumnName(orderBy),
        orderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //When the user change the data start
  const handleChangeStartAt = (date) => {
    setStartAt(date.target.value);
    setStartAtFormat(changeFormatDate(date.target.value));
    setEndAt('');
    setStartAtError('');
    if (disbaleSecondDate) setDisabledSecondDate(false);
  };
  //When the user change the data end
  const handleChangeEndAt = (date) => {
    setEndAtError('');
    setEndAt(date.target.value);
    setEndAtFormat(changeFormatDate(date.target.value));
  };
  //Handle button done
  const handleBtnDone = () => {
    if (!startAt) {
      setStartAtError('common.starts_at.error');
      setEndAtError('common.ends_at.error');
    } else if (!endAt) setEndAtError('common.ends_at.error');
    else {
      dispatch(
        fetchInscriptionStatistics(
          inscriptionStatistics.currentPage,
          inscriptionStatistics.perPage,
          tableOrderBy,
          tableOrderDirection,
          startAtFormat,
          endAtFormat
        )
      );
    }
  };
  const handleExportToExcel = () => {
    if (startAt && !endAt) {
      setEndAtError('common.ends_at.error');
    } else {
      dispatch(
        downloadFile(
          startAtFormat,
          endAtFormat,
          `${ENDPOINT_STATISTIC}${ENDPOINT_INSCRIPTION_STATISTICS}${EXPORT}`
        )
      );
    }
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={INSCRIPTION_STATISTICS_TABLE_HEADER}
        keys={INSCRIPTION_STATISTICS_KEYS}
        visibilityIcon={false}
        editIcon={false}
        deleteIcon={false}
        displayHeaderComponent={true}
        tbBody={inscriptionStatistics.data}
        rowsPerPage={inscriptionStatistics.perPage}
        total={inscriptionStatistics.total}
        currentPage={inscriptionStatistics.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        displayAddOrApplyBtn={true}
        textBtnAddOrApply={'common.enter'}
        loading={inscriptionStatistics.loading}
        tbTriBy={INSCRIPTION_STATISTICS_TABLE_TRI_BY}
        fetchDataBy={handleTriByKey}
        displayAddIcon={false}
        secondLabelOfPicker={'common.ends_at'}
        firstLabelOfPicker={'common.starts_at'}
        selectedFirstDate={startAt}
        selectedSecondDate={endAt}
        changeFistDate={handleChangeStartAt}
        changeSecondDate={handleChangeEndAt}
        disabledSecondDatePicker={disbaleSecondDate}
        diplayPickerUi={true}
        loadingLastBtn={inscriptionStatistics.loadingLastBtn}
        openFormDialog={handleBtnDone}
        selectedFirstDateError={startAtError}
        selectedSecondDateError={endAtError}
        totalInscriptions={true}
        displayExportButton={true}
        disabledExportBtn={download.loading}
        handleDisplayExportButton={handleExportToExcel}
      />
      {!inscriptionStatistics.loading ? (
        inscriptionStatistics.data.length !== 0 ? (
          <LineChart
            data={dataChart}
            options={options}
            loading={loadingChart}
          />
        ) : null
      ) : (
        <LineChart data={dataChart} options={options} loading={loadingChart} />
      )}
    </Fragment>
  );
}

const connectedInscriptionStatisticsPanel = withI18n()(
  InscriptionStatisticsPanel
);

export { connectedInscriptionStatisticsPanel as InscriptionStatisticsPanel };

export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const STOP_LOADING_UI = "STOP_LOADING_UI";
export const OPEN_SNACKBAR = "OPEN_SNACKBAR";
export const CLOSE_SNACKBAR = "CLOSE_SNACKBAR";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const CHANGE_VALUE_FORMDIALOG = "CHANGE_VALUE_FORMDIALOG";
export const USER_CREATED_SUCCESS = "USER_CREATED_SUCCESS";
export const USER_CREATED_FAILED = "USER_CREATED_FAILED";
export const ERROR_EMAIL = "ERROR_EMAIL";
export const GET_USER = "GET_USER";
export const CHANGE_VALUE_UPDATE_FORMDIALOG = "CHANGE_VALUE_UPDATE_FORMDIALOG";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const CHANGE_VALUE_DELETE_DIALOG = "CHANGE_VALUE_DELETE_DIALOG";
export const DELETED_USER_SUCCESS = "DELETED_USER_SUCCESS";
export const ENABLE_BUTTON_USER = "ENABLE_BUTTON_USER";
export const GET_USER_FAILED = "GET_USER_FAILED";
export const SET_SECTIONS = "SET_SECTIONS";
export const EMPTY_ERRORS = "EMPTY_ERRORS";
export const ClOSE_PASSWORD_DIALOG = "ClOSE_PASSWORD_DIALOG";
export const OPEN_PASSWORD_DIALOG = "OPEN_PASSWORD_DIALOG";

/* BANNERS ACTION TYPE  */
export const GET_BANNERS_SUCCESS = "GET_BANNERS_SUCCESS";
export const GET_BANNERS_FAILED = "GET_BANNERS_FAILED";
export const BANNERS_OPEN_FORM_DIALOG = "BANNERS_OPEN_FORM_DIALOG";
export const BANNERS_CREATED_SUCCESS = "BANNERS_CREATED_SUCCESS";
export const BANNERS_UPADTE_FORM_DIALOG = "BANNERS_UPADTE_FORM_DIALOG";
export const UPDATE_BANNERS_SUCCESS = "UPDATE_BANNERS_SUCCESS";
export const DELETE_DIALOG_BANNER = "DELETE_DIALOG_BANNER";
export const DELETED_BANNER_SUCCESS = "DELETED_BANNER_SUCCESS";
export const BANNERS_FAILED = "BANNERS_FAILED";
export const LOADING_BANNER = "LOADING_BANNER";
export const URL_ERROR = "URL_ERROR";
export const SET_ERRORS = "SET_ERRORS";
export const CLOSE_DRAWER = "CLOSE_DRAWER";
export const OPEN_DRAWER = "OPEN_DRAWER";
export const CHANGE_DISABLED_DIALOG = "CHANGE_DISABLED_DIALOG";

/* CLIENTS ACTION TYPE */

export const GET_CLIENTS_SUCCESS = "GET_CLIENTS_SUCCESS";
export const GET_CLIENTS_FAILED = "GET_CLIENTS_FAILED";
export const CLIENTS_UPADTE_FORM_DIALOG = "CLIENTS_UPADTE_FORM_DIALOG";
export const GET_GOUVERNORATE_SUCCESS = "GET_GOUVERNORATE_SUCCESS";
export const GET_DELEGATION_OF_GOUVERNORAT = "GET_DELEGATION_OF_GOUVERNORAT";

export const UPDATE_CLIENT_SUCCESS = "UPDATE_CLIENT_SUCCESS";
export const UPDATE_CLIENT_FAILED = "UPDATE_CLIENT_FAILED";
export const UPDATE_CLIENT = "UPDATE_CLIENT";
export const CHANGE_CLIENT_DELETE_DIALOG = "CHANGE_CLIENT_DELETE_DIALOG";
export const CLIENT_BUTTON_DELETE = "CLIENT_BUTTON_DELETE";
export const DELETED_CLIENT_SUCCESS = "DELETED_CLIENT_SUCCESS";
export const CHANGE_CLIENT_LOADING_DELEGATION =
	"CHANGE_CLIENT_LOADING_DELEGATION";
export const LOAD_MORE_DELEGATION_ERROR = "LOAD_MORE_DELEGATION_ERROR";
export const LOAD_MORE_DELEGATION_SUCCESS = "LOAD_MORE_DELEGATION_SUCCESS";
export const GET_DELEGATION_OF_GOUVERNORAT_SUCCESS =
	"GET_DELEGATION_OF_GOUVERNORAT_SUCCESS";

/* PRODUCTS ACTION TYPE */

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS";
export const GET_PRODUCTS_FAILED = "GET_PRODUCTS_FAILED";
export const PRODUCT_OPEN_FORM_DIALOG = "PRODUCT_OPEN_FORM_DIALOG";
export const LOADING_PRODUCT = "LOADING_PRODUCT";
export const PRODUCT_CREATED_SUCCESS = "PRODUCT_CREATED_SUCCESS";
export const PRODUCT_FAILED = "PRODUCT_FAILED";
export const PRODUCT_UPADTE_FORM_DIALOG = "PRODUCT_UPADTE_FORM_DIALOG";
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const DELETE_DIALOG_PRODUCT = "DELETE_DIALOG_PRODUCT";
export const DELETED_PRODUCT_SUCCESS = "DELETED_PRODUCT_SUCCESS";

/* CREDIT ACTION TYPE */

export const GET_CREDIT_SUCCESS = "GET_CREDIT_SUCCESS";
export const GET_CREDIT_FAILED = "GET_CREDIT_FAILED";
export const CREDITS_OPEN_FORM_DIALOG = "CREDITS_OPEN_FORM_DIALOG";
export const CREDITS_UPADTE_FORM_DIALOG = "CREDITS_UPADTE_FORM_DIALOG,";
export const LOADING_CREDITS = "LOADING_CREDITS";
export const CREDITS_CREATED_SUCCESS = "CREDITS_CREATED_SUCCESS";
export const CREDITS_FAILED = "CREDITS_FAILED";
export const UPDATE_CREDITS_SUCCESS = "UPDATE_CREDITS_SUCCESS";
export const DELETED_CREDIT_SUCCESS = "DELETED_CREDIT_SUCCESS";
export const DELETE_DIALOG_CREDIT = "DELETE_DIALOG_CREDIT";
export const GET_CREDIT = "GET_CREDIT";

/* AGENT ACTION TYPE */
export const GET_AGENTS = "GET_AGENTS";
export const GET_AGENTS_SUCCESS = "GET_AGENTS_SUCCESS";
export const GET_AGENTS_FAILED = "GET_AGENTS_FAILED";
export const AGENTS_OPEN_FORM_DIALOG = "AGENTS_OPEN_FORM_DIALOG";
export const CHANGE_AGENT_DELETE_DIALOG = "CHANGE_AGENT_DELETE_DIALOG";
export const AGENTS_CREATED_SUCCESS = "AGENTS_CREATED_SUCCESS";
export const AGENTS_FAILED = "AGENTS_FAILED";
export const LOADING_AGENTS = "LOADING_AGENTS";
export const AGENTS_BUTTON_DELETE = "AGENTS_BUTTON_DELETE";
export const DELETED_AGENTS_SUCCESS = "DELETED_AGENTS_SUCCESS";
export const AGENTS_UPADTE_FORM_DIALOG = "AGENTS_UPADTE_FORM_DIALOG";
export const UPDATE_AGENTS_SUCCESS = "UPDATE_AGENTS_SUCCESS";
export const ERROR_AGENT_PHONE = "ERROR_AGENT_PHONE";

/* INFO REQUEST ACTION TYPE */

export const GET_INFO_REQUEST_SUCCESS = "GET_INFO_REQUEST_SUCCESS";
export const GET_INFO_REQUEST_FAILED = "GET_INFO_REQUEST_FAILED";
export const INFO_REQUEST_LOADING_BTN = "INFO_REQUEST_LOADING_BTN";
export const INFO_UPADTE_FORM_DIALOG = "INFO_UPADTE_FORM_DIALOG";
export const ENABLE_BTN_INFO_REAQUEST = "ENABLE_BTN_INFO_REAQUEST";
export const UPDATE_INFO_REQUEST_SUCCESS = "UPDATE_INFO_REQUEST_SUCCESS";
export const UPDATE_INFO_REQUEST_FAILED = "UPDATE_INFO_REQUEST_FAILED";
export const UPDATE_INFO_REQUEST = "UPDATE_INFO_REQUEST";
export const GET_INFO_REQUEST = "GET_INFO_REQUEST";

/* AGENCIES ACTION TYPE */
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const GET_AGENCY_FAILED = "GET_AGENCY_FAILED";
export const AGENCIES_OPEN_FORM_DIALOG = "AGENCIES_OPEN_FORM_DIALOG";
export const AGENCIES_UPADTE_FORM_DIALOG = "AGENCIES_UPADTE_FORM_DIALOG";
export const CHANGE_DELETE_DIALOG_AGENCIE = "CHANGE_DELETE_DIALOG_AGENCIE";
export const UPDATE_AGENCIE_SUCCESS = "UPDATE_AGENCIE_SUCCESS";
export const AGENCIE_FAILED = "AGENCIE_FAILED";
export const LOADING_AGENCIES = "LOADING_AGENCIES";
export const CREATE_AGENCIE_SUCCESS = "CREATE_AGENCIE_SUCCESS";
export const DELETE_AGENCIE_SUCCESS = "DELETE_AGENCIE_SUCCESS";
export const GET_AGENCIES = "GET_AGENCIES";
export const AGENCIE_UNIQUE_NAME = "AGENCIE_UNIQUE_NAME";
export const LOAD_MORE_ENDA_AGENCY_SUCCESS = "LOAD_MORE_ENDA_AGENCY_SUCCESS";
export const LOAD_MORE_ENDA_AGENCY_FAILED = "LOAD_MORE_ENDA_AGENCY_FAILED";
export const LOADING_ENDA_AGENCIES = "LOADING_ENDA_AGENCIES";
export const GET_ENDA_AGENCY_SUCCESS = "GET_ENDA_AGENCY_SUCCESS";
export const GET_ENDA_AGENCY_FAILED = "GET_ENDA_AGENCY_FAILED";

/* CLAIM REQUEST ACTION TYPE */

export const GET_CLAIM_REQUEST = "GET_CLAIM_REQUEST";
export const GET_CLAIM_REQUEST_SUCCESS = "GET_CLAIM_REQUEST_SUCCESS";
export const GET_CLAIM_REQUEST_FAILED = "GET_CLAIM_REQUEST_FAILED";
export const UPDATE_CLAIM_REQUEST = "UPDATE_CLAIM_REQUEST";
export const UPDATE_CLAIM_REQUEST_SUCCESS = "UPDATE_CLAIM_REQUEST_SUCCESS";
export const UPDATE_CLAIM_REQUEST_FAILED = "UPDATE_CLAIM_REQUEST_FAILED";
export const ENABLE_BTN_CLAIM_REAQUEST = "ENABLE_BTN_CLAIM_REAQUEST";
export const CLAIM_UPADTE_FORM_DIALOG = "CLAIM_UPADTE_FORM_DIALOG";
export const CLAIM_REQUEST_LOADING_BTN = "CLAIM_REQUEST_LOADING_BTN";

/* Notification request action type */
export const LOADING_NOTIFICATION = "LOADING_NOTIFICATION";
export const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
export const FETCH_NOTIFICATIONS_ERROR = "FETCH_NOTIFICATIONS_ERROR";
export const DELETED_NOTIFICATION_SUCCESS = "DELETED_NOTIFICATION_SUCCESS";
export const DELETE_DIALOG_NOTIFICATION = "DELETE_DIALOG_NOTIFICATION";
export const ADD_DIALOG_NOTIFICATION = "ADD_DIALOG_NOTIFICATION";
export const CONFIG_DIALOG_NOTIFICATION = "CONFIG_DIALOG_NOTIFICATION";
export const PREVIEW_DIALOG_NOTIFICATION = "PREVIEW_DIALOG_NOTIFICATION";
export const FETCH_AUTO_NOTIFICATIONS_SUCCESS =
	"FETCH_AUTO_NOTIFICATIONS_SUCCESS";
export const FETCH_AUTO_NOTIFICATIONS_ERROR = "FETCH_AUTO_NOTIFICATIONS_ERROR";
export const LOADING_AUTO_NOTIFICATION = "LOADING_AUTO_NOTIFICATION";
export const UPDATE_AUTO_NOTIFICATION_SUCCESS =
	"UPDATE_AUTO_NOTIFICATION_SUCCESS";
export const LOADING_UPDATE = "LOADING_UPDATE";
export const DELETE_NOTIFICATION_ERROR = "DELETE_NOTIFICATION_ERROR";
export const UPDATE_AUTO_NOTIFICATION_ERROR = "UPDATE_AUTO_NOTIFICATION_ERROR";

/* Reset Password action type */

export const GET_REST_PASSWORD_SUCCESS = "GET_REST_PASSWORD_SUCCESS";
export const GET_REST_PASSWORD_FAILED = "GET_REST_PASSWORD_FAILED";
export const RESET_PASSWORD_LOADING_LAST_BTN =
	"RESET_PASSWORD_LOADING_LAST_BTN";

/* INFO PANEL action type */

export const GET_INFO_PANEL_SUCCESS = "GET_INFO_PANEL_SUCCESS";
export const GET_INFO_PANEL_FAILED = "GET_INFO_PANEL_FAILED";
export const RESET_INFO_PANEL_LOADING_LAST_BTN =
	"RESET_INFO_PANEL_LOADING_LAST_BTN";

export const ADD_NOTIFICATION_SUCCESS = "ADD_NOTIFICATION_SUCCESS";
export const LOADING_ADD = "LOADING_ADD";
export const ADD_NOTIFICATION_ERROR = "ADD_NOTIFICATION_ERROR";

/** Goverorate request action types */
export const LOADING_GOVERNORATES = "LOADING_GOVERNORATES";
export const GET_GOVERNORATE_SUCCESS = "GET_GOVERNORATE_SUCCESS";
export const GET_GOVERNORATE_ERROR = "GET_GOVERNORATE_ERROR";
export const LOAD_MORE_GOVERNORATE_SUCCESS = "LOAD_MORE_GOVERNORATE_SUCCESS";
export const LOAD_MORE_GOVERNORATE_ERROR = "LOAD_MORE_GOVERNORATE_ERROR";

/** Delegation request action types */
export const GET_DELEGATION_ERROR = "GET_DELEGATION_ERROR";
export const GET_DELEGATION_SUCCESS = "GET_DELEGATION_SUCCESS";
export const LOADING_DELEGATIONS = "LOADING_DELEGATIONS";

/**Enda products action types */
export const GET_ENDA_PRODUCTS_SUCCESS = "GET_ENDA_PRODUCTS_SUCCESS";
export const GET_ENDA_PRODUCTS_FAILED = "GET_ENDA_PRODUCTS_FAILED";
export const LOADING_ENDA_PRODUCTS = "LOADING_ENDA_PRODUCTS";
export const LOAD_MORE_ENDA_PRODUCTS_SUCCESS =
	"LOAD_MORE_ENDA_PRODUCTS_SUCCESS";
export const LOAD_MORE_ENDA_PRODUCTS_FAILED = "LOAD_MORE_ENDA_PRODUCTS_FAILED";

/* CLAIM PANEL action type */

export const GET_CLAIM_PANEL_SUCCESS = "GET_CLAIM_PANEL_SUCCESS";
export const GET_CLAIM_PANEL_FAILED = "GET_CLAIM_PANEL_FAILED";
export const RESET_CLAIM_PANEL_LOADING_LAST_BTN =
	"RESET_CLAIM_PANEL_LOADING_LAST_BTN";
export const CHANGE_DELEGATION_TO_EMPTY = "CHANGE_DELEGATION_TO_EMPTY";

/** Statistics of inscription types */
export const GET_INSCRIPTION_STATISTICS_FAILED =
	"GET_INSCRIPTION_STATISTICS_FAILED";
export const GET_INSCRIPTION_STATISTICS_SUCCESS =
	"GET_INSCRIPTION_STATISTICS_SUCCESS";
export const INSCRIPTION_STATISTICS_LOADING_LAST_BTN =
	"INSCRIPTION_STATISTICS_LOADING_LAST_BTN";

/** Statistics of credit requests types */
export const GET_CREDIT_REQUEST_STATISTICS_SUCCESS =
	"GET_CREDIT_REQUEST_STATISTICS_SUCCESS";
export const GET_CREDIT_REQUEST_STATISTICS_FAILED =
	"GET_CREDIT_REQUEST_STATISTICS_FAILED";
export const CREDIT_REQUEST_STATISTICS_LOADING_LAST_BTN =
	"CREDIT_REQUEST_STATISTICS_LOADING_LAST_BTN";

/** Download action type  */
export const LOADIGN_DOWNLOAD = "LOADIGN_DOWNLOAD";
export const DOWNLOAD_ACTION = "DOWNLOAD_ACTION";

/** Profile action types */
export const GET_PROFILES_FAILED = "GET_PROFILES_FAILED";
export const GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS";
export const PROFILE_OPEN_FORM_DIALOG = "PROFILE_OPEN_FORM_DIALOG";
export const LOADING_PROFILE = "LOADING_PROFILE";
export const PROFILE_CREATED_SUCCESS = "PROFILE_CREATED_SUCCESS";
export const PROFILE_FAILED = "PROFILE_FAILED";
export const PROFILE_UPADTE_FORM_DIALOG = "PROFILE_UPADTE_FORM_DIALOG";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const DELETE_DIALOG_PROFILE = "DELETE_DIALOG_PROFILE";
export const DELETED_PROFILE_SUCCESS = "DELETED_PROFILE_SUCCESS";
export const LOADING_PROFILES = "LOADING_PROFILES";
export const LOAD_MORE_PROFILES_FAILED = "LOAD_MORE_PROFILES_FAILED";
export const LOAD_MORE_PROFILES_SUCCESS = "LOAD_MORE_PROFILES_SUCCESS";

/** sections action types */
export const GET_SECTIONS_FAILED = "GET_SECTIONS_FAILED";
export const GET_SECTIONS_SUCCESS = "GET_SECTIONS_SUCCESS";
export const INIT_SECTIONS = "INIT_SECTIONS";

import {
  PREFIX_ADMIN,
  ENDPOINT_CLIENT,
  PAGE,
  ENDPOINT_PER_PAGE,
  ENDPOINT_GOUVERNORATE,
  SEARCH,
  ENDPOINT_DELEGATION,
  ORDER_DIRECTION,
  ORDER_BY,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  OPEN_SNACKBAR,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  CLIENTS_UPADTE_FORM_DIALOG,
  GET_GOUVERNORATE_SUCCESS,
  GET_DELEGATION_OF_GOUVERNORAT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT_FAILED,
  UPDATE_CLIENT,
  CHANGE_CLIENT_DELETE_DIALOG,
  DELETED_CLIENT_SUCCESS,
  CHANGE_DISABLED_DIALOG,
  CHANGE_CLIENT_LOADING_DELEGATION,
  GET_DELEGATION_OF_GOUVERNORAT_SUCCESS,
  CHANGE_DELEGATION_TO_EMPTY,
} from '../types';
import {
  getFirstErrorFromResponse,
  ERROR_NETWORK,
  DEFAULT_ORDER_DIRECTION,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';

/**
 * Action Creator to get all Clients
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchClients(
  page = 1,
  perPage = 5,
  search,
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION
) {
  return (dispatch) => {
    let apiEndpoint;
    if (search)
      apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_CLIENT}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${SEARCH}${search}`;
    else
      apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_CLIENT}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;
    if (orderBy)
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_CLIENTS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_CLIENTS_FAILED });
      });
  };
}
/**
 * get the list of Governorate
 * @param {int} id
 *
 * @returns function contains an action
 */
export const getGovernorate = () => {
  return (dispatch) => {
    let apiEndpoint = `${ENDPOINT_GOUVERNORATE}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_GOUVERNORATE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
      });
  };
};
/**
 * get the delegations related to GOUVERNORATE
 * @param {int} id
 *
 * @returns function contains an action
 */
export const getDelegationOfGrouv = (id) => {
  return (dispatch) => {
    dispatch({ type: GET_DELEGATION_OF_GOUVERNORAT });
    let apiEndpoint = `${ENDPOINT_GOUVERNORATE}/${id}${ENDPOINT_DELEGATION}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_DELEGATION_OF_GOUVERNORAT_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
      });
  };
};
/**
 * Action Creator to update client
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} email
 * @param {string} phone
 * @param {string} deleg
 * @param {string} secondPhone
 * @param {string} adress
 * @param {string} codeP
 * @param {int} id
 * @param {int} page
 * @param {int} perPage
 * @param {string} search
 *
 * @returns function contains an action
 */
export function updateClient(
  firstName,
  lastName,
  id,
  phone,
  deleg,
  address,
  email,
  secondPhone,
  codeP,
  page,
  perPage,
  search,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CLIENT });
    let formData = new FormData();
    formData.append('first_name', firstName);
    formData.append('last_name', lastName);
    formData.append('phone', phone);
    formData.append('address', address);
    formData.append('delegation_id', deleg);
    if (email) formData.append('email', email);
    if (secondPhone) formData.append('optional_phone', secondPhone);
    if (codeP) formData.append('zip_code', codeP);
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_CLIENT}/${id}`;
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchClients(page, perPage, search, orderBy, orderDirection));
        dispatch({ type: UPDATE_CLIENT_SUCCESS, payload: response.data.data });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'clients.updated',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: UPDATE_CLIENT_FAILED });
      });
  };
}
/**
 * Action Creator to delete client and call fetchClients action to reload data
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 * @param {string} search
 *
 * @returns function contains an action
 */
export const deleteClient = (
  id,
  currentPage,
  perPage,
  search,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_CLIENT}/${id}`;
    methodsServices
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(
          fetchClients(currentPage, perPage, search, orderBy, orderDirection)
        );
        dispatch({ type: DELETED_CLIENT_SUCCESS, payload: id });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'clients.deleted',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      });
  };
};
/**
 * Action Creator to open and close the update dialog (clients)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const ClientUpdateFormDialog = (value) => {
  return {
    type: CLIENTS_UPADTE_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the delete dialog (client)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogClient = (value) => {
  return {
    type: CHANGE_CLIENT_DELETE_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to change the value of loadingDelegation
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeLoadingDelegation = (value) => {
  return {
    type: CHANGE_CLIENT_LOADING_DELEGATION,
    payload: value,
  };
};
/**
 * Change the delagation state to empty
 *
 * @returns an action
 */
export const changeDelegation = () => {
  return {
    type: CHANGE_DELEGATION_TO_EMPTY,
  };
};

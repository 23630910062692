import {
  GET_PRODUCTS_SUCCESS,
  PRODUCT_OPEN_FORM_DIALOG,
  PRODUCT_CREATED_SUCCESS,
  PRODUCT_FAILED,
  PRODUCT_UPADTE_FORM_DIALOG,
  UPDATE_PRODUCT_SUCCESS,
  LOADING_PRODUCT,
  DELETE_DIALOG_PRODUCT,
  DELETED_PRODUCT_SUCCESS,
  GET_PRODUCTS_FAILED,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  openDialogForm: false,
  disableButton: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
};

const productsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        total: action.payload.total,
        perPage: Number(action.payload.per_page),
        loading: false,
      };
    case GET_PRODUCTS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case PRODUCT_OPEN_FORM_DIALOG:
      return { ...state, openDialogForm: action.payload };
    case PRODUCT_CREATED_SUCCESS:
      return { ...state, openDialogForm: false, disableButton: false };
    case PRODUCT_UPADTE_FORM_DIALOG:
      return { ...state, openUpdateDialogForm: action.payload };
    case UPDATE_PRODUCT_SUCCESS:
      return { ...state, openUpdateDialogForm: false, disableButton: false };
    case LOADING_PRODUCT:
      return { ...state, disableButton: true };
    case PRODUCT_FAILED:
      return { ...state, disableButton: false };
    case DELETE_DIALOG_PRODUCT:
      return { ...state, openDeleteDialog: action.payload };
    case DELETED_PRODUCT_SUCCESS:
      return { ...state, openDeleteDialog: false };
    default:
      return state;
  }
};

export default productsReducer;

import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  ENDPOINT_ENDA_PRODUCT,
  getFirstErrorFromResponse,
  ORDER_BY,
  ORDER_DIRECTION,
  ORDER_DIRECTION_ASC,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
  SEARCH,
  SERVERITY_ERROR,
} from '../../helpers';
import { methodsServices } from '../services';
import {
  GET_ENDA_PRODUCTS_FAILED,
  GET_ENDA_PRODUCTS_SUCCESS,
  LOADING_ENDA_PRODUCTS,
  LOAD_MORE_ENDA_PRODUCTS_FAILED,
  LOAD_MORE_ENDA_PRODUCTS_SUCCESS,
  OPEN_SNACKBAR,
} from '../types';

/**
 * Action Creator to get all agencies
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchEndaProducts(
  page,
  perPage = 15,
  orderBy = 'name',
  orderDirection = ORDER_DIRECTION_ASC,
  search = ''
) {
  return (dispatch) => {
    dispatch({
      type: LOADING_ENDA_PRODUCTS,
    });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_ENDA_PRODUCT}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}${SEARCH}${search}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        console.log('response ===', response.data.data);
        dispatch({
          type: GET_ENDA_PRODUCTS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_ENDA_PRODUCTS_FAILED });
      });
  };
}

/**
 * Action Creator to load more agencies
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function loadMoreEndaProductsAction(
  page = DEFAULT_PAGE,
  perPage = 15,
  orderBy = 'name',
  orderDirection = ORDER_DIRECTION_ASC
) {
  return (dispatch) => {
    dispatch({
      type: LOADING_ENDA_PRODUCTS,
    });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_ENDA_PRODUCT}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: LOAD_MORE_ENDA_PRODUCTS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: LOAD_MORE_ENDA_PRODUCTS_FAILED });
      });
  };
}

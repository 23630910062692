import {
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
  FormHelperText,
  LinearProgress,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import React, { useEffect, useState, Fragment } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  SECONDARY_COLOR,
  VARIANT_OUTLINED,
  VARIANT_CONTAINED,
  SMALL_SIZE,
  isNotNumber,
  CheckLengthNumber,
  MIN_LENGHT_PHONE,
  MIN_LENGHT_CODE,
  ValidateEmail,
  customizeDate,
  INPUT_REQUIRED,
} from '../../helpers';
import { useStyles } from './styles';
import { getDelegationOfGrouv, changeDelegation } from '../../store/actions';

/**
 * Component Client form
 *
 * @component
 *
 * @example
 * return (
 *   <ClientsForm/>
 * )
 */
function ClientsForm({ t, textButton, client, handleButton, gourvernorate }) {
  const [phone, setPhone] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [secondPhone, setSecondPhone] = useState('');
  const [address, setAddress] = useState('');
  const [codeP, setCodeP] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [date] = useState(customizeDate(client.created_at));
  const [id, setId] = useState(null);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [adressError, setAdressError] = useState('');
  const [secondPhoneError, setSecondPhoneError] = useState('');
  const [codePError, setCodePError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [delegError, setDelegError] = useState('');
  const [cin, setCin] = useState('');
  const [gouver, setGouver] = useState(client.governorate_id.toString());
  const [deleg, setDeleg] = useState(client.delegation_id.toString());
  const dispatch = useDispatch(); //returns a reference to the dispatch function from the Redux store
  const classes = useStyles();
  const clients = useSelector((state) => state.clients); //extract state.clients from the Redux store

  useEffect(() => {
    setFirstName(client.first_name);
    setLastName(client.last_name);
    setId(client.id);
    if (client.phone_num) setPhone(client.phone_num);
    if (client.email) setEmail(client.email);
    if (client.phone_num_optional) setSecondPhone(client.phone_num_optional);
    if (client.address) setAddress(client.address);
    if (client.zip_code) setCodeP(client.zip_code);
    if (client.cin) setCin(client.cin);

    dispatch(getDelegationOfGrouv(gouver));
  }, [gouver, client, dispatch]);

  useEffect(() => {
    // before a component is unmounted change the value state
    return () => dispatch(changeDelegation());
  }, []);
  //get the phone number entered by user
  const handlePhone = (e) => {
    if (isNotNumber(e.target.value))
      setPhoneError('clients.phone.number.error');
    else setPhoneError('');
    setPhone(e.target.value);
  };

  //get the firstName entered by user
  const handleFristName = (e) => {
    setFirstNameError('');
    setFirstName(e.target.value);
  };

  //get the lastName entered by user
  const handleLastName = (e) => {
    setLastNameError('');
    setLastName(e.target.value);
  };
  //get the email entered by user
  const handleEmail = (e) => {
    setEmailError('');
    setEmail(e.target.value);
  };
  //get the second phone number entered by user
  const handleSecondPhone = (e) => {
    if (isNotNumber(e.target.value))
      setSecondPhoneError('clients.phone.number.error');
    else setSecondPhoneError('');

    setSecondPhone(e.target.value);
  };
  //get the adress entered by user
  const handleAddress = (e) => {
    setAdressError('');
    setAddress(e.target.value);
  };
  //get the code postal number entered by user
  const handleCodeP = (e) => {
    if (isNotNumber(e.target.value))
      setCodePError('clients.phone.number.error');
    else setCodePError('');
    setCodeP(e.target.value);
  };
  //check the values ​​entered by the user
  const validationForm = () => {
    let isValidate = false;
    if (!firstName) {
      setFirstNameError('clients.firstName.required.error');
      isValidate = true;
    }
    if (!lastName) {
      setLastNameError('clients.lastName.required.error');
      isValidate = true;
    }
    if (!phone) {
      setPhoneError('clients.phone.required.error');
      isValidate = true;
    }
    if (!address) {
      setAdressError('clients.adress.required.error');
      isValidate = true;
    }
    if (!deleg) {
      setDelegError('clients.deleg.required.error');
      isValidate = true;
    }
    if (phone) {
      if (CheckLengthNumber(phone, MIN_LENGHT_PHONE)) {
        setPhoneError('clients.lenght.phone.error');
        isValidate = true;
      }
    }
    if (secondPhone) {
      if (CheckLengthNumber(phone, MIN_LENGHT_PHONE)) {
        setSecondPhoneError('clients.lenght.phone.error');
        isValidate = true;
      }
    }
    if (codeP) {
      if (CheckLengthNumber(codeP, MIN_LENGHT_CODE)) {
        setCodePError('clients.lenght.codeP.error');
        isValidate = true;
      }
    }
    if (email) {
      if (!ValidateEmail(email)) setEmailError('common.email.validate');
    }
    return isValidate;
  };
  const handleClickButton = (e) => {
    if (!validationForm())
      handleButton(
        firstName,
        lastName,
        id,
        phone,
        deleg,
        address,
        email,
        secondPhone,
        codeP
      );
  };

  const handleChangeGouvernment = (event) => {
    setGouver(event.target.value);
    setDeleg('');
  };

  const handleChangedeleg = (event) => {
    setDeleg(event.target.value);
    setDelegError('');
  };
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            defaultValue={date}
            variant={VARIANT_OUTLINED}
            label={t('clients.createdAt')}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handlePhone}
            value={phone}
            variant={VARIANT_OUTLINED}
            label={t('clients.phone') + INPUT_REQUIRED}
            helperText={t(phoneError)}
            error={phoneError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleFristName}
            value={firstName}
            variant={VARIANT_OUTLINED}
            label={t('clients.firstName') + INPUT_REQUIRED}
            helperText={t(firstNameError)}
            error={firstNameError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleLastName}
            value={lastName}
            variant={VARIANT_OUTLINED}
            label={t('clients.lastName') + INPUT_REQUIRED}
            helperText={t(lastNameError)}
            error={lastNameError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            value={cin}
            variant={VARIANT_OUTLINED}
            label={t('informations.cin')}
            disabled
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleEmail}
            value={email}
            variant={VARIANT_OUTLINED}
            label={t('clients.email')}
            helperText={t(emailError)}
            error={emailError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleSecondPhone}
            value={secondPhone}
            variant={VARIANT_OUTLINED}
            label={t('clients.second.phone')}
            helperText={t(secondPhoneError)}
            error={secondPhoneError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>

        <Grid item xs={12} sm={12} className={classes.gridGenderStyle}>
          <FormControl className={classes.formControl}>
            <InputLabel>{t('clients.gouvernment') + INPUT_REQUIRED}</InputLabel>
            <Select value={gouver} onChange={handleChangeGouvernment}>
              {gourvernorate.map((items, index) => {
                return (
                  <MenuItem key={index} value={items.id.toString()}>
                    {items.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={delegError ? true : false}
          >
            {clients.loadigDelegation ? (
              <Fragment>
                <InputLabel>{t('clients.deleg') + INPUT_REQUIRED}</InputLabel>
                <Select value={deleg} onChange={handleChangedeleg}>
                  {clients.delegation.map((items, index) => {
                    return (
                      <MenuItem key={index} value={items.id.toString()}>
                        {items.description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Fragment>
            ) : (
              <LinearProgress color={SECONDARY_COLOR} />
            )}
            <FormHelperText>{t(delegError)}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleAddress}
            value={address}
            variant={VARIANT_OUTLINED}
            label={t('clients.adress') + INPUT_REQUIRED}
            helperText={t(adressError)}
            error={adressError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleCodeP}
            value={codeP}
            variant={VARIANT_OUTLINED}
            label={t('clients.codeP')}
            helperText={t(codePError)}
            error={codePError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Button
        className={classes.btnStyle}
        onClick={handleClickButton}
        disabled={clients.disableButton}
        size={SMALL_SIZE}
        variant={VARIANT_CONTAINED}
        color={SECONDARY_COLOR}
      >
        {clients.disableButton && (
          <CircularProgress size={15} color={SECONDARY_COLOR} />
        )}
        {t(textButton)}
      </Button>
    </div>
  );
}
const connectedClientsForm = withI18n()(ClientsForm); //Higher-Order Component
export { connectedClientsForm as ClientsForm };

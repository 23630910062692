import React from "react";
import {
	Group as GroupIcon,
	Dashboard as DashboardIcon,
	Mail as MailIcon,
	Error as ErrorIcon,
	Notifications as NotificationsIcon,
	Apartment as ApartmentIcon,
	List as ListIcon,
	CropOriginal as CropOriginalIcon,
	Lock as LockIcon,
	SupervisedUserCircle as SupervisedUserCircleIcon,
	AssignmentInd as AssignmentIndIcon,
} from "@material-ui/icons";
import { SECTIONS } from "./";

export const menu = [
	{
		id: SECTIONS.DASHBOAD,
		name: "menu.dashboard",
		icon: <DashboardIcon />,
		route: "/",
	},
	{
		id: SECTIONS.CLIENTS,
		name: "menu.clients",
		icon: <GroupIcon />,
		route: "/clients",
	},
	{
		id: SECTIONS.INFO_REQUESTS,
		name: "menu.informations",
		icon: <MailIcon />,
		route: "/info-request",
	},
	{
		id: SECTIONS.CLAIMS,
		name: "menu.claims",
		icon: <ErrorIcon />,
		route: "/claims",
	},
	{
		id: SECTIONS.NOTIFICATIONS,
		name: "menu.notifications",
		icon: <NotificationsIcon />,
		route: "/notifications",
	},
	{
		id: SECTIONS.AGENCIES,
		name: "menu.agencies",
		icon: <ApartmentIcon />,
		route: "/agencies",
	},
	{
		id: SECTIONS.AGENTS,
		name: "menu.agents",
		icon: <SupervisedUserCircleIcon />,
		route: "/agents",
	},
	{
		id: SECTIONS.PRODUCTS,
		name: "menu.products",
		icon: <ListIcon />,
		route: "/products",
	},
	{
		id: SECTIONS.BANNERS,
		name: "menu.banners",
		icon: <CropOriginalIcon />,
		route: "/banners",
	},
	{
		id: -1,
		name: "menu.users",
		icon: <LockIcon />,
		route: "/users",
	},
	{
		id: -2,
		name: "menu.profiles",
		icon: <AssignmentIndIcon />,
		route: "/profiles",
	},
];

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  gridDivStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnStyle: {
    marginTop: 25,
    left: '98%',
    position: 'sticky',
  },
  gridStyle: { display: 'flex', justifyContent: 'center' },
  gridGenderStyle: { display: 'flex', alignItems: 'center' },
  radioStyle: { display: 'flex', flexDirection: 'row', marginLeft: 10 },
  checkIconStyle: { marginLeft: 8 },
  formControl: {
    margin: 10,

    width: '100%',
  },
  endaClientStyle: { alignSelf: 'center', display: 'flex' },
  iconEndaStyle: { marginLeft: 5, marginTop: 1 },
  spinnerDisablebtn: { marginRight: 5 },
  treatiesStyle: { display: 'flex', alignItems: 'center', marginBottom: 28 },
});

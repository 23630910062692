import {
  GET_CLAIM_REQUEST,
  GET_CLAIM_REQUEST_SUCCESS,
  GET_CLAIM_REQUEST_FAILED,
  UPDATE_CLAIM_REQUEST_FAILED,
  UPDATE_CLAIM_REQUEST_SUCCESS,
  UPDATE_CLAIM_REQUEST,
  ENABLE_BTN_CLAIM_REAQUEST,
  CLAIM_UPADTE_FORM_DIALOG,
  CLAIM_REQUEST_LOADING_BTN,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  disableButton: false,
  spinnerDisablebtn: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
  loadingBtn: false,
};

const claimReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CLAIM_REQUEST:
      return { ...state, loading: true };
    case GET_CLAIM_REQUEST_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        perPage: Number(action.payload.per_page),
        total: action.payload.total,
        loading: false,
        loadingBtn: false,
      };
    case GET_CLAIM_REQUEST_FAILED:
      return { ...state, loading: false, loadingBtn: false };
    case UPDATE_CLAIM_REQUEST:
      return { ...state, disableButton: true, spinnerDisablebtn: true };
    case UPDATE_CLAIM_REQUEST_SUCCESS:
      return {
        ...state,
        openUpdateDialogForm: false,
        disableButton: false,
        spinnerDisablebtn: false,
      };
    case UPDATE_CLAIM_REQUEST_FAILED:
      return {
        ...state,
        disableButton: false,
        spinnerDisablebtn: false,
      };
    case ENABLE_BTN_CLAIM_REAQUEST:
      return { ...state, disableButton: action.payload };
    case CLAIM_UPADTE_FORM_DIALOG:
      return { ...state, openUpdateDialogForm: action.payload };
    case CLAIM_REQUEST_LOADING_BTN:
      return { ...state, loadingBtn: true };
    default:
      return state;
  }
};

export default claimReducer;

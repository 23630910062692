export const DEFAULT_ORDER_BY = "created_at";
export const DEFAULT_ORDER_DIRECTION = "desc";

export const VALUE_OF_GENDER_MALE = "2";
export const VALUE_OF_GENDER_FEMALE = "1";

export const UNAUTHORISED_STATUS = 403;

export const DEFAULT_PER_PAGE = "5";
export const DEFAULT_PAGE = "1";

export const TYPE_OF_IMAGES = "image/png, image/jpeg, image/jpg";

export const COLORS = [
	{ line: "#cc0066", back: "#cc006682" },
	{ line: "#fec94e", back: "#fec94e82" },
	{ line: "#077da4", back: "#077da482" },
	{ line: "#01895d", back: "#01895d82" },
	{ line: "#e80c20", back: "#e80c2082" },
	{ line: "#9c27b0", back: "#9c27b082" },
	{ line: "#1c516a", back: "#1c516a82" },
	{ line: "#795548", back: "#79554882" },
	{ line: "#7ea100", back: "#7ea10082" },
	{ line: "#3F51B5", back: "#3F51B582" },
	{ line: "#e905ff", back: "#e905ff82" },
	{ line: "#009688", back: "#00968882" },
	{ line: "#34048a", back: "#34048a82" },
	{ line: "#06632d", back: "#06632d82" },
	{ line: "#2196f3", back: "#2196f382" },
];

// KEYS VALUE used in dynamic component
export const KEYS_ROLE = "role";
export const KEYS_CHECKBOX = "checkbox";
export const KEYS_PROFILE = "profile";
export const KEYS_PROFILE_SECTIONS = "sections";
export const KEYS_DELEGATION = "delegation";
export const KEYS_GOVERNORATE = "governorate";
export const KEYS_DESCRIPTION = "description";
export const KEYS_STATUS = "status";
export const KEYS_CLIENT_ID = "client_id";
export const KEYS_CREATED_AT = "created_at";
export const KEYS_SEND_DATE = "send_date";
export const KEY_GOUVERNORAT_ID = "governorate_id";
export const KEY_COUNT_BY_CLIENT = "countByClient";
export const INSCRIPTION_DATE = "inscription_date";
export const KEYS_NAME = "name";
export const KEYS_ENDA_AGENCY = "enda_agency";
export const PROCESSED_AT = "processed_at";
export const CLIENT_DELEGATION = "client_delagation";
// USER_KEYS AND USER_TABLE_HEADER USED INSIDE UserPage COMPONENT
export const USER_KEYS = ["last_name", "first_name", "email", KEYS_PROFILE];
export const USER_TABLE_HEADER = [
	"users.lastName",
	"users.firstName",
	"users.email",
	"users.profile",
	"common.actions",
];
export const USER_TABLE_TRI_BY = ["users.firstName", "users.lastName"];
// BANNERS_KEYS AND BANNERS_TABLE_HEADER USED INSIDE BannerPage COMPONENT
export const BANNERS_TABLE_HEADER = [
	"common.title",
	"common.subTitle",
	"common.createdAt",
	"common.actions",
];
export const BANNERS_KEYS = ["title", "sub_title", KEYS_CREATED_AT];
export const BANNERS_TABLE_TRI_BY = [
	"common.title",
	"common.subTitle",
	"common.createdAt",
];
// THE ROLE OF AN ADMIN
export const VALUE_ROLE_ADMIN = "1";
export const VALUE_ROLE_AGENT = "2";

// COLOR
export const SECONDARY_COLOR = "secondary";
export const ERROR_COLOR = "error";
export const ACTION_COLOR = "action";
export const RUBY_COLOR = "#cc0066";
export const CHART_BACK_GROUND_COLOR = "rgba(204,0,102,0.1)";
export const CHART_BORDER_COLOR = "rgba(204,0,102,1)";
export const GAMBOGE_COLOR = "#ea910b";

// TYPE OF VARIANT
export const VARIANT_OUTLINED = "outlined";
export const VARIANT_CONTAINED = "contained";
export const VARIANT_SUBTITLE_ONE = "subtitle1";
export const VARIANT_SUBTITLE_TWO = "subtitle2";
export const VARIANT_HEAD = "head";
export const VARIANT_BUTTON = "button";

// SIZE OF BUTTON
export const SMALL_SIZE = "small";

// CLIENTS_KEYS AND CLIENTS_TABLE_HEADER USED INSIDE ClientsPage COMPONENT
export const CLIENTS_TABLE_HEADER = [
	"clients.userName",
	"clients.lastName",
	"clients.firstName",
	"informations.cin",
	"clients.phone",
	"clients.adress",
	"clients.deleg",
	"clients.gouvernment",
	"common.actions",
];
export const CLIENTS_KEYS = [
	"user_name",
	"last_name",
	"first_name",
	"cin",
	"phone_num",
	"address",
	"delegation",
	"governorate",
];
export const CLIENT_TABLE_TRI_BY = [
	"clients.userName",
	"clients.firstName",
	"clients.lastName",
];
// PACK_KEYS AND PACK_TABLE_HEADER USED INSIDE BannerPage COMPONENT
export const PACK_TABLE_HEADER = [
	"common.title",
	"common.createdAt",
	"products.packs",
	"common.actions",
];
export const PACK_KEYS = ["name", KEYS_CREATED_AT];
export const PACK_TABLE_TRI_BY = ["common.title", "common.createdAt"];
// CREDIT_KEYS AND CREDIT_TABLE_HEADER USED INSIDE BannerPage COMPONENT

export const CREDIT_TABLE_HEADER = [
	"credits.list",
	"common.createdAt",
	"common.actions",
];
export const CREDIT_KEYS = ["name", KEYS_CREATED_AT];
export const CREDIT_TABLE_TRI_BY = ["credits.list", "common.createdAt"];

export const MIN_LENGHT_PHONE = 8;
export const MIN_LENGHT_CODE = 4;
// AGENT_KEYS AND AGENT_TABLE_HEADER USED INSIDE AgentPage COMPONENT
export const AGENTS_TABLE_HEADER = [
	"agents.name",
	"agents.governorate",
	"agents.delegation",
	"agents.adress",

	"agents.phone",
	"common.actions",
];
export const AGENTS_KEYS = [
	"name",
	KEYS_GOVERNORATE,
	KEYS_DELEGATION,
	"address",

	"phone_num",
];
export const AGENTS_TABLE_TRI_BY = ["agents.name", "agents.governorate"];
// INFO_KEYS AND INFO_TABLE_HEADER USED INSIDE InfoRequestPage COMPONENT
export const INFO_TABLE_HEADER = [
	"informations.date",
	"informations.lastName",
	"informations.firstName",
	"informations.email",
	"informations.cin",
	"clients.inscription.delegation",
	"informations.client.enda",
	"informations.status",
	"common.actions",
];
export const INFO_KEYS = [
	KEYS_CREATED_AT,
	"client_last_name",
	"client_first_name",
	"client_email",
	"client_cin",
	CLIENT_DELEGATION,
	"client_id",
	"status",
];
export const INFO_TABLE_TRI_BY = [
	"informations.date",
	"informations.lastName",
	"informations.firstName",
];
// CLAIM_TABLE_HEADER USED INSIDE InfoRequestPage COMPONENT
export const CLAIM_TABLE_HEADER = [
	"claims.date",
	"informations.lastName",
	"informations.firstName",
	"informations.email",
	"informations.cin",
	"clients.inscription.delegation",
	"informations.client.enda",
	"informations.status",
	"common.actions",
];
export const CLAIM_TABLE_TRI_BY = [
	"claims.date",
	"informations.lastName",
	"informations.firstName",
];
// STATUS TYPE OF INFO/CLAIM REQUEST
export const TYPE_STATUS_WAITING = "1";
export const TYPE_STATUS_PROCESSED = "2";

// TYPE INFO OR CLAIM REQUEST
export const TYPE_INFO_REQUEST = 2;
export const TYPE_CLAIM_REQUEST = 1;
// CONSTANT TO CHECK THE  ERROR NETWORK
export const ERROR_NETWORK = "common.networkerror";

//  AGENCIES_TABLE_HEADER USED INSIDE AgentPage COMPONENT
export const AGENCIES_TABLE_HEADER = [
	"agencies.name",
	"agents.governorate",
	"agents.adress",
	"agents.phone",
	"common.actions",
];
export const AGENCIES_KEYS = [
	"name",
	KEY_GOUVERNORAT_ID,
	"address",
	"phone_num",
];
export const AGENCIES_TABLE_TRI_BY = ["agencies.name"];

export const PRODUCTS_PATH = "products/";
export const CREDITS_PATH = "/credits";
export const MIN_VALUE_LONGITUDE = -180;
export const MAX_VALUE_LONGITUDE = 180;

// NOTIFICATION_KEYS AND NOTIFICATION_TABLE_HEADER USED INSIDE NotificationPage COMPONENT
export const NOTIFICATION_TABLE_HEADER = [
	"common.title",
	"notifications.sendDate",
	"common.actions",
];
export const NOTIFICATION_KEYS = ["title", "send_date"];
export const NOTIFICATIONS_TABLE_TRI_BY = [
	"common.title",
	"notifications.sendDate",
];

export const ORDER_DIRECTION_DESC = "desc";
export const ORDER_DIRECTION_ASC = "asc";

export const DASHBORAD_TAB_HEADER = [
	"dashboard.inscription",
	"dashboard.reset.password",
	"dashboard.credit.request",
	"dashboard.info-request",
	"dashboard.claim",
];

export const DASHBORAD_TAB_HEADER_TITLE_FOR_AGENT = [
	"dashboard.info-request",
	"dashboard.claim",
];

// RESET_KEYS AND RESET_TABLE_HEADER USED INSIDE RESETPanel COMPONENT
export const RESET_TABLE_HEADER = [
	"resetPassword.code",
	"resetPassword.userName",
	"informations.cin",
	"resetPassword.phone",
	"resetPassword.gouvernment",
	"resetPassword.deleg",
	"resetPassword.nbr.changement",
	"",
];

export const RESET_KEYS = [
	"ready_code_inscrit",
	"user_name",
	"cin",
	"phone_num",
	KEYS_GOVERNORATE,
	KEYS_DELEGATION,
	KEY_COUNT_BY_CLIENT,
];

export const RESET_TABLE_TRI_BY = [
	"resetPassword.code",
	"resetPassword.userName",
];

export const KEY_CLIENT = "client";
// INFO_KEYS AND INFO_TABLE_HEADER USED INSIDE InfoRequestPage COMPONENT
export const PANEL_INFO_TABLE_HEADER = [
	"informations.date",
	"informations.lastName",
	"informations.firstName",
	"informations.phone",
	"informations.cin",
	"common.client",
	"common.Notclient",
	"common.status",
	"common.processedAt",
];
export const PANEL_INFO_KEYS = [
	KEYS_CREATED_AT,
	"client_last_name",
	"client_first_name",
	"client_phone",
	"client_cin",
	KEY_CLIENT,
	"status",
	PROCESSED_AT,
];

// Keys for the synamoc table in inscription statistics
export const INSCRIPTION_STATISTICS_TABLE_HEADER = [
	"clients.inscription.date",
	"clients.inscription.ready.code",
	"clients.inscription.username",
	"informations.cin",
	"clients.inscription.phone",
	"clients.inscription.governorate",
	"clients.inscription.delegation",
	"",
];
export const INSCRIPTION_STATISTICS_KEYS = [
	INSCRIPTION_DATE,
	"ready_code_inscrit",
	"user_name",
	"cin",
	"phone_num",
	KEYS_GOVERNORATE,
	KEYS_DELEGATION,
];
export const INSCRIPTION_STATISTICS_TABLE_TRI_BY = [
	"clients.inscription.date",
	"clients.inscription.ready.code",
	"clients.inscription.username",
	"clients.inscription.phone",
];

// Keys for the synamoc table in inscription statistics
export const CREDIT_REQUEST_STATISTICS_TABLE_HEADER = [
	"credit.requests.date",
	"credit.requests.lastname",
	"credit.requests.firstname",
	"credit.requests.phone",
	"credit.requests.governorate",
	"credit.requests.delegation",
	"credit.requests.agency",
	"credit.requests.product",
	"common.client",
	"common.Notclient",
	"common.actions",
];
export const CREDIT_REQUEST_STATISTICS_KEYS = [
	KEYS_CREATED_AT,
	"client_last_name",
	"client_first_name",
	"client_phone_num",
	KEYS_GOVERNORATE,
	KEYS_DELEGATION,
	KEYS_ENDA_AGENCY,
	"product_name",
	KEY_CLIENT,
];
export const CREDIT_REQUEST_STATISTICS_TABLE_TRI_BY = [
	"credit.requests.date",
	"credit.requests.lastname",
	"credit.requests.firstname",
	"credit.requests.phone",
];

export const PANEL_CLAIM_TABLE_HEADER = [
	"claims.date",
	"informations.lastName",
	"informations.firstName",
	"informations.phone",
	"informations.cin",
	"common.client",
	"common.Notclient",
	"common.status",
	"common.processedAt",
];

export const SET_TIME_OUT = 500;
export const ICON_MAX_HEIGHT = 48;
export const ICON_MAX_WIDTH = 48;

export const IMAGE_MAX_HEIGHT = 150;
export const IMAGE_MAX_WIDTH = 200;

export const ENDA_CLIENT = 1;
export const NOT_ENDA_CLIENT = 2;

export const MIN_CLIENT_AGE = 18;
export const MAX_CLIENT_AGE = 65;

export const SLIDE_MAX_HEIGHT = 600;
export const SLIDE_MAX_WIDTH = 1140;

export const PRODUCT_IMAGE_MAX_HEIGHT = 128;
export const PRODUCT_IMAGE_MAX_WIDTH = 105;

export const ALIGN_CENTER = "center";
export const ALIGN_LEFT = "left";
export const ALIGN_RIGHT = "right";

export const INPUT_REQUIRED = " *";

export const FONT_SIZE_LARGE = "large";
export const POSITION_END = "end";

export const MAX_WIDTH_LARGE = "lg";
export const SERVERITY_ERROR = "error";
export const SERVERITY_SUCCESS = "success";

export const PER_PAGE = 5;
export const CURRENT_PAGE = 1;

export const PER_PAGE_SECTIONS = 3;

export const JUSTIFY_CENTER = "center";

export const TWO_DASHES = "--";

export const FEMALE = "Femme";
export const MALE = "Homme";
export const YES = "Oui";
export const NO = "Non";

// list of sections ids
export const SECTIONS = {
	DASHBOAD: 1,
	CLIENTS: 2,
	INFO_REQUESTS: 3,
	CLAIMS: 4,
	NOTIFICATIONS: 5,
	AGENCIES: 6,
	AGENTS: 7,
	PRODUCTS: 8,
	BANNERS: 9,
	STATISTICS_INSCRIPTION: 10,
	STATISTICS_RESET_PASSWORD: 11,
	STATISTICS_CREDIT_REQUESTS: 12,
	STATISTICS_INFO_REQUEST: 13,
	STATISTICS_CLAIMS: 14,
};

//profile constants
export const PROFILES_TABLE_HEADER = [
	"profiles.name",
	"profiles.sections",
	"common.actions",
];
export const PROFILES_KEYS = ["name", KEYS_PROFILE_SECTIONS];
export const PROFILES_TABLE_TRI_BY = ["profiles.name"];

/** sections keys */
export const SECTIONS_FORM_TABLE_KEYS = [KEYS_CHECKBOX,"name"];
export const SECTIONS_FORM_TABLE_TRI_BY = [];
export const SECTIONS_FORM_TABLE_HEADER = ["sections.name"];
import {
  DEFAULT_ORDER_BY,
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  ENDPOINT_AUTO_NOTIFICATION,
  ENDPOINT_NOTIFICATION,
  getFirstErrorFromResponse,
  ORDER_BY,
  ORDER_DIRECTION,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
  SEARCH,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';
import { methodsServices } from '../services';
import {
  ADD_DIALOG_NOTIFICATION,
  ADD_NOTIFICATION_ERROR,
  ADD_NOTIFICATION_SUCCESS,
  CHANGE_DISABLED_DIALOG,
  CONFIG_DIALOG_NOTIFICATION,
  DELETED_NOTIFICATION_SUCCESS,
  DELETE_DIALOG_NOTIFICATION,
  DELETE_NOTIFICATION_ERROR,
  FETCH_AUTO_NOTIFICATIONS_ERROR,
  FETCH_AUTO_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_SUCCESS,
  LOADING_ADD,
  LOADING_AUTO_NOTIFICATION,
  LOADING_NOTIFICATION,
  LOADING_UPDATE,
  OPEN_SNACKBAR,
  PREVIEW_DIALOG_NOTIFICATION,
  UPDATE_AUTO_NOTIFICATION_ERROR,
  UPDATE_AUTO_NOTIFICATION_SUCCESS,
} from '../types';

/**
 * Action Creator to paginate the notifications
 *
 * @param {int} page
 * @param {int} perPage
 * @param {string} search
 *
 * @returns function contains an action
 */
export function fetchNotifications(
  page = DEFAULT_PAGE,
  perPage = DEFAULT_PER_PAGE,
  search = '',
  orderBy = DEFAULT_ORDER_BY,
  orderDirection = DEFAULT_ORDER_DIRECTION
) {
  return (dispatch) => {
    dispatch({
      type: LOADING_NOTIFICATION,
    });

    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_NOTIFICATION}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${SEARCH}${search}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;

    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: FETCH_NOTIFICATIONS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: FETCH_NOTIFICATIONS_ERROR });
      });
  };
}

/**
 * Action Creator to get auto notifications
 *
 * @returns function contains an action
 */
export function fetchAutoNotifications() {
  return (dispatch) => {
    dispatch({
      type: LOADING_AUTO_NOTIFICATION,
    });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AUTO_NOTIFICATION}`;

    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: FETCH_AUTO_NOTIFICATIONS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: FETCH_AUTO_NOTIFICATIONS_ERROR });
      });
  };
}

/**
 * deletes a notification
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 */
export function deleteNotification(id, currentPage, perPage) {
  return (dispatch) => {
    dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_NOTIFICATION}/${id}`;
    methodsServices
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(fetchNotifications(currentPage, perPage));
        dispatch({ type: DELETED_NOTIFICATION_SUCCESS, payload: id });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'notifications.deleted',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: DELETE_NOTIFICATION_ERROR, payload: false });
      });
  };
}

/**
 * updates the auto notifications
 *
 * @param {int} deadlineBefore
 * @param {int} deadlineAfter
 * @param {int} cardBefore
 * @param {int} cardAfter
 */
export function updateAutoNotification(
  deadlineBefore,
  deadlineAfter,
  cardBefore,
  cardAfter
) {
  return (dispatch) => {
    dispatch({ type: LOADING_UPDATE });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AUTO_NOTIFICATION}`;
    let formData = new FormData();
    formData.append('deadline_before_days', deadlineBefore);
    formData.append('deadline_after_days', deadlineAfter);
    formData.append('card_before_days', cardBefore);
    formData.append('card_after_days', cardAfter);
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch({ type: UPDATE_AUTO_NOTIFICATION_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'notifications.auto.updated',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CONFIG_DIALOG_NOTIFICATION, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: UPDATE_AUTO_NOTIFICATION_ERROR, payload: false });
      });
  };
}

/**
 * Action Creator to open and close the delete dialog (notifications)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogNotification = (value) => {
  return {
    type: DELETE_DIALOG_NOTIFICATION,
    payload: value,
  };
};

/**
 * updates the auto notifications
 *
 * @param {int} deadlineBefore
 * @param {int} deadlineAfter
 * @param {int} cardBefore
 * @param {int} cardAfter
 */
export function createNotificationAction(
  filterAgencies,
  filterGovernorates,
  filterDelegations,
  filterProducts,
  minAge,
  maxAge,
  gender,
  isEndaClient,
  title,
  url,
  message,
  toBeSendAt,
  icon,
  image,
  page,
  perPage
) {
  return (dispatch) => {
    dispatch({ type: LOADING_ADD });

    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_NOTIFICATION}`;
    let formData = new FormData();
    formData.append('title', title);
    formData.append('message', message);
    formData.append('min_age', minAge);
    formData.append('max_age', maxAge);
    formData.append('gender', gender);
    formData.append('send_date', toBeSendAt);
    formData.append('is_client', isEndaClient);
    formData.append('icon', icon);

    if (url) {
      formData.append('url', url);
    }
    if (image) {
      formData.append('image', image);
    }

    filterAgencies.forEach((element, index) => {
      formData.append('agency_ids[' + index + ']', element);
    });
    filterGovernorates.forEach((element, index) => {
      formData.append('governorate_ids[' + index + ']', element);
    });
    filterDelegations.forEach((element, index) => {
      formData.append('delegation_ids[' + index + ']', element);
    });
    filterProducts.forEach((element, index) => {
      formData.append('credit_names[' + index + ']', element);
    });

    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch({ type: ADD_NOTIFICATION_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'notifications.added',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: ADD_DIALOG_NOTIFICATION, payload: false });
        dispatch(fetchNotifications(page, perPage));
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({
          type: ADD_NOTIFICATION_ERROR,
          payload: false,
        });
      });
  };
}

/**
 * Action Creator to open and close the add dialog (notifications)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeAddDialogNotification = (value) => {
  return {
    type: ADD_DIALOG_NOTIFICATION,
    payload: value,
  };
};

/**
 * Action Creator to open and close the configuration dialog (notifications)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeConfigDialogNotification = (value) => {
  return {
    type: CONFIG_DIALOG_NOTIFICATION,
    payload: value,
  };
};

/**
 * Action Creator to open and close the preview dialog (notifications)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changePreviewDialogNotification = (value) => {
  return {
    type: PREVIEW_DIALOG_NOTIFICATION,
    payload: value,
  };
};

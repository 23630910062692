import React, { Fragment, useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	Button,
	CircularProgress,
	Grid,
	TextField,
	FormControl,
	InputLabel,
	FormHelperText,
} from "@material-ui/core";
import { useStyles } from "./styles";
import {
	SECONDARY_COLOR,
	SMALL_SIZE,
	VARIANT_CONTAINED,
	VARIANT_OUTLINED,
	INPUT_REQUIRED,
	SECTIONS_FORM_TABLE_KEYS,
	SECTIONS_FORM_TABLE_HEADER,
} from "../../helpers";
import { fetchSections } from "../../store/actions";
import { FormTable } from "../dynamicTable/FormTable";

/**
 * Component Profile Form
 *
 * @component
 *
 * @example
 * return (
 *   <ProfilesForm/>
 * )
 */
function ProfilesForm({ t, textButton, profile, handleButton }) {
	const dispatch = useDispatch();

	const [name, setName] = useState(""); // to retrieve the name entered by the user (initial value empty string)
	const [nameError, setNameError] = useState(""); // state to display the name error

	const [selectedSections, setSelectedSections] = useState([]); // to retrieve the sections selected by the user (initial value empty array)
	const [sectionsError, setSectionsError] = useState(""); // state to display the sections error

	const classes = useStyles();

	const profiles = useSelector((state) => state.profiles); //extract state.profiles from the Redux store
	const sections = useSelector((state) => state.sections);

	const [tableOrderDirection, setTableOrderDirection] = useState("");
	const [tableOrderBy, setTableOrderBy] = useState("");

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	useEffect(() => {
		//check if the profile props is not empty
		if (profile) {
			//change the default value by the values of profile
			setName(profile.name);
			if (profile.sections) {
				let listSections = [];
				profile.sections.forEach((section) => {
					listSections.push(section.id);
				});
				setSelectedSections(listSections);
			}
		}
		dispatch(
			fetchSections(
				1,
				sections.perPage,
				tableOrderBy,
				tableOrderDirection
			)
		);
	}, [profile]);

	//Function to validate the inputs
	const validationForm = () => {
		let isValidate = false;
		if (!name) {
			setNameError("profiles.name.required.error");
			isValidate = true;
		}
		if (selectedSections.length <= 0) {
			setSectionsError("profiles.sections.required.error");
			isValidate = true;
		}

		return isValidate;
	};
	//get the name entered by user
	const handleName = (e) => {
		setNameError("");
		setName(e.target.value);
	};
	const handleChangeSections = (event, prop) => {
		setSectionsError("");
		let sectionsVar = [...selectedSections];
		if (event.target.checked) {
			sectionsVar.push(prop.id);
		} else {
			const index = sectionsVar.indexOf(prop.id);
			if (index >= 0) {
				sectionsVar.splice(index, 1);
			}
		}
		setSelectedSections([...sectionsVar]);
	};

	//handle submit form
	const handleClickButton = (e) => {
		if (!validationForm()) {
			handleButton(name, selectedSections);
		}
	};

	//when the user change the page
	const handleChangePage = (page, perPage) => {
		dispatch(fetchSections(page, perPage, tableOrderBy, tableOrderDirection));
	};

	return (
		<div className={classes.profileForm}>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						onChange={handleName}
						value={name}
						variant={VARIANT_OUTLINED}
						placeholder={t("profiles.placeholder.name")}
						label={t("profiles.name") + INPUT_REQUIRED}
						helperText={t(nameError)}
						error={nameError ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<FormControl
						className={classes.formControl}
						error={sectionsError ? true : false}
					>
						<Fragment>
							<InputLabel shrink htmlFor="select-multiple-table">
								{t("profiles.sections") + INPUT_REQUIRED}
							</InputLabel>
							<FormTable
								type="multiple"
								id="select-multiple-table"
								handleChangeCheck={handleChangeSections}
								tbHeader={SECTIONS_FORM_TABLE_HEADER}
								keys={SECTIONS_FORM_TABLE_KEYS}
								tbBody={sections.data}
								rowsPerPage={sections.perPage}
								total={sections.total}
								currentPage={sections.currentPage}
								changePage={handleChangePage}
								loading={sections.loading}
								pages={sections.lastPage}
								values={selectedSections}
							/>
						</Fragment>
						<FormHelperText>{t(sectionsError)}</FormHelperText>
					</FormControl>
				</Grid>
			</Grid>
			<Button
				className={classes.btnStyle}
				onClick={handleClickButton}
				disabled={profiles.disableButton}
				size={SMALL_SIZE}
				variant={VARIANT_CONTAINED}
				color={SECONDARY_COLOR}
			>
				{profiles.disableButton && (
					<CircularProgress size={15} color={SECONDARY_COLOR} />
				)}
				{t(textButton)}
			</Button>
		</div>
	);
}
const connectedProfilesForm = withI18n()(ProfilesForm); //Higher-Order Component
export { connectedProfilesForm as ProfilesForm };

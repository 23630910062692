import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn, canAccessRoute, getFirstRoute } from "../helpers";

/**
 * Route gaurd for connected users
 *
 * @param {*} param0
 */
export const PrivateRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLoggedIn() ? (
				canAccessRoute(rest.id) ? (
					<Component {...props} />
				) : (
					<Redirect to={getFirstRoute()} />
				)
			) : (
				<Redirect to="/login" />
			)
		}
	/>
);

import {
	GET_USERS_SUCCESS,
	USER_CREATED_SUCCESS,
	CHANGE_VALUE_FORMDIALOG,
	UPDATE_USER_SUCCESS,
	USER_CREATED_FAILED,
	ERROR_EMAIL,
	GET_USER,
	CHANGE_VALUE_UPDATE_FORMDIALOG,
	CHANGE_VALUE_DELETE_DIALOG,
	DELETED_USER_SUCCESS,
	ENABLE_BUTTON_USER,
	GET_USER_FAILED,
	EMPTY_ERRORS,
	ClOSE_PASSWORD_DIALOG,
	OPEN_PASSWORD_DIALOG,
} from "../types";
import { CURRENT_PAGE, PER_PAGE } from "../../helpers";

const INITIAL_STATE = {
	data: [],
	loading: true,
	currentPage: CURRENT_PAGE,
	total: 1,
	perPage: PER_PAGE,
	openDialogForm: false,
	errorEmail: "",
	disableButton: false,
	openUpdateDialogForm: false,
	openDeleteDialog: false,
	errors: "",
	resetPasswordDialog: false,
};

const userReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case UPDATE_USER_SUCCESS:
			return {
				...state,
				openUpdateDialogForm: false,
				data: state.data.map((item) =>
					item.id === action.payload.id ? { ...item, ...action.payload } : item
				),
				disableButton: false,
			};
		case GET_USER:
			return { ...state, disableButton: true };
		case GET_USERS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				currentPage: action.payload.current_page,
				total: action.payload.total,
				perPage: Number(action.payload.per_page),
				loading: false,
			};
		case GET_USER_FAILED:
			return { ...state, loading: false };
		case EMPTY_ERRORS:
			return { ...state, errors: "" };
		case USER_CREATED_SUCCESS:
			return { ...state, openDialogForm: false, disableButton: false };
		case USER_CREATED_FAILED:
			return {
				...state,
				errors: action.playload,
				disableButton: false,
			};
		case CHANGE_VALUE_FORMDIALOG:
			return { ...state, openDialogForm: action.payload, errorEmail: "" };
		case ERROR_EMAIL:
			return { ...state, errorEmail: action.payload };
		case CHANGE_VALUE_UPDATE_FORMDIALOG: {
			return { ...state, openUpdateDialogForm: action.payload };
		}
		case CHANGE_VALUE_DELETE_DIALOG:
			return { ...state, openDeleteDialog: action.payload };
		case DELETED_USER_SUCCESS:
			return {
				...state,
				openDeleteDialog: false,
				data: state.data.filter((item) => item.id !== action.payload),
				total: state.total - 1,
			};
		case ENABLE_BUTTON_USER:
			return { ...state, disableButton: false };
		case ClOSE_PASSWORD_DIALOG:
			return { ...state, resetPasswordDialog: false };
		case OPEN_PASSWORD_DIALOG:
			return { ...state, resetPasswordDialog: true };
		default:
			return state;
	}
};

export default userReducer;

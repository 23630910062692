import {
	GET_SECTIONS_FAILED,
	GET_SECTIONS_SUCCESS,
	INIT_SECTIONS,
} from "../types";
import { CURRENT_PAGE, PER_PAGE_SECTIONS } from "../../helpers";

const INITIAL_STATE = {
	data: [],
	loading: true,
	currentPage: CURRENT_PAGE,
	total: 1,
	perPage: PER_PAGE_SECTIONS,
	lastPage: 0,
};

const sectionsReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_SECTIONS_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				currentPage: action.payload.current_page,
				total: action.payload.total,
				perPage: Number(action.payload.per_page),
				lastPage: Number(action.payload.last_page),
				loading: false,
			};
		case GET_SECTIONS_FAILED:
			return {
				...state,
				loading: false,
			};
		case INIT_SECTIONS:
			return INITIAL_STATE;
		default:
			return state;
	}
};

export default sectionsReducer;

import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  DeleteDialog,
  DynamicTable,
  FormDialog,
  ProductsForm,
} from '../components';
import {
  CREDITS_PATH,
  KEYS_CREATED_AT,
  PACK_KEYS,
  PACK_TABLE_HEADER,
  PACK_TABLE_TRI_BY,
  PRODUCTS_PATH,
} from '../helpers/constantes';
import {
  changeDeleteDialogProduct,
  CreatePack,
  deleteProduct,
  fetchProducts,
  productFormDialog,
  productsUpdateFormDialog,
  updateProduct,
} from '../store/actions';

/**
 * Component Products
 *
 * @component
 *
 * @example
 * return (
 *   <Products/>
 * )
 */
function Products({ t }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const history = useHistory();
  const [productId, setProductId] = useState(null);
  const products = useSelector((state) => state.products);
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  useEffect(() => {
    dispatch(
      fetchProducts(
        products.currentPage,
        products.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  }, []);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(fetchProducts(page, perPage, tableOrderBy, tableOrderDirection));
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(fetchProducts(page, perPage, tableOrderBy, tableOrderDirection));
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('common.createdAt'):
        return KEYS_CREATED_AT;
      case t('common.title'):
        return PACK_KEYS[0];
      default:
        return PACK_KEYS[0];
    }
  };

  /**
   *  get list of banners using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchBannersBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);

    dispatch(
      fetchProducts(
        products.currentPage,
        products.perPage,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  //Close the Form dialog  (add new pack )
  const handleCloseFormDialog = () => {
    dispatch(productFormDialog(false));
  };
  //Open the Form dialog(add new pack)
  const openFormDialog = () => {
    dispatch(productFormDialog(true));
  };
  //Function to create a new pack
  const handleCreatePack = (
    name,
    description,
    eligibilityCriteria,
    treatmenDelays,
    documents
  ) => {
    dispatch(
      CreatePack(
        name,
        description,
        eligibilityCriteria,
        treatmenDelays,
        documents,
        products.currentPage,
        products.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //Function to upadate a pack
  const handleUpdateProduct = (
    name,
    description,
    eligibilityCriteria,
    treatmenDelays,
    documents
  ) => {
    dispatch(
      updateProduct(
        name,
        description,
        eligibilityCriteria,
        treatmenDelays,
        documents,
        data.id,
        products.currentPage,
        products.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  const listOfCredit = (product) => {
    history.push(`${PRODUCTS_PATH}${product.id}${CREDITS_PATH}`, {
      name: product.name,
    });
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (product) => {
    setData(product);
    dispatch(productsUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(productsUpdateFormDialog(false));
  };
  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setProductId(id);
    dispatch(changeDeleteDialogProduct(true));
  };
  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialogProduct(false));
  };
  //Action to delete pack
  const handleDeleteProdcut = () => {
    dispatch(
      deleteProduct(
        productId,
        products.currentPage,
        products.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={PACK_TABLE_HEADER}
        listIcon={true}
        keys={PACK_KEYS}
        visibilityIcon={false}
        displayHeaderComponent={true}
        tbBody={products.data}
        rowsPerPage={products.perPage}
        total={products.total}
        currentPage={products.currentPage}
        displayAddOrApplyBtn={true}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openFormDialog={openFormDialog}
        openUpdateFormDialog={openUpdateFormDialog}
        openDeleteDialog={openDeleteDialog}
        textBtnAddOrApply="products.addNew"
        title={'products.title'}
        loading={products.loading}
        handleListIcon={listOfCredit}
        tbTriBy={PACK_TABLE_TRI_BY}
        fetchDataBy={handleFetchBannersBy}
      />
      {/* Dialog To create new pack */}
      <FormDialog
        openFormDialog={products.openDialogForm}
        handleCloseFormDialog={handleCloseFormDialog}
        buttonTitle={'products.addNew'}
        title={'products.addNew'}
      >
        <ProductsForm
          passwordRequired={true}
          textButton={'products.create'}
          handleButton={handleCreatePack}
        />
      </FormDialog>
      {/* Dialog To update pack */}
      <FormDialog
        openFormDialog={products.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        buttonTitle={'banners.text.button.update'}
        title={'users.update'}
      >
        <ProductsForm
          textButton={'products.text.button.update'}
          product={data}
          handleButton={handleUpdateProduct}
        />
      </FormDialog>
      {/* Dialog To delete pack */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="products.delete"
        open={products.openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteProdcut}
      />
    </Fragment>
  );
}
const connectedProductsPage = withI18n()(Products); //Higher-Order Component
export { connectedProductsPage as Products };

import { Divider, Grid } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableContainer from '@material-ui/core/TableContainer';
import TablePagination from '@material-ui/core/TablePagination';
import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { ORDER_DIRECTION_DESC } from '../../helpers';
import { HeaderComponent } from '../headerComponent/HeaderComponent';
import { BodyDynamicTable } from './BodyDynamicTable';
import { HearderDynamicTable } from './HearderDynamicTable';
import { useStyles } from './styles';

/**
 * Component global table
 * this component contains 3 components BodyDynamicTable , HearderDynamicTable and HeaderComponent
 *
 * @component
 *
 * @example
 * return (
 *   <DynamicTable/>
 * )
 */
function DynamicTable({
  listIcon = false,
  title = '',
  tbHeader,
  keys = [],
  tbBody = [],
  visibilityIcon = true,
  editIcon = true,
  deleteIcon = true,
  currentPage = 1,
  rowsPerPage = 5,
  changePage,
  total,
  changeRowsPerPage,
  displayFilter,
  displaySearch,
  displayUploadBtn,
  displayAddOrApplyBtn,
  textBtnUpload,
  textBtnAddOrApply,
  openFormDialog,
  openUpdateFormDialog,
  openDeleteDialog,
  IdOfAdminConnected = null,
  displayHeaderComponent = false,
  loading,
  handleSearch,
  handleListIcon,
  name,
  handleFirstBtn,
  onChangeUploadBtn,
  handleFirstCheckBox,
  handleSecondCheckBox,
  t,
  checkedFirstCheckBox,
  checkedSecondtCheckBox,
  loadingLastBtn,
  handleShowBtn,
  displayConfigButton,
  configButtonText,
  onChangeConfigButton,
  orderBy = '',
  order = ORDER_DIRECTION_DESC,
  tbTriBy = [],
  fetchDataBy,
  displayAddIcon,
  firstLabelOfPicker,
  secondLabelOfPicker,
  selectedFirstDate,
  selectedSecondDate,
  changeFistDate,
  changeSecondDate,
  disabledSecondDatePicker,
  diplayPickerUi,
  countClientByid,
  selectedFirstDateError,
  selectedSecondDateError,
  displayRowsInfoRequestPanel = false,
  countStatueProcessed,
  countStatueWaiting,
  userOffline,
  userOnline,
  infoRequestPanelFirstRowsTitle = 'informations.statistics.processed',
  infoRequestPanelSecondRowsTitle = 'informations.statistics.waiting',
  infoRequestPanelThirdRowsTitle = 'informations.statistics.total',
  totalInscriptions,
  displayRowsOfCreditRequestPanel,
  creditRequestPanelRowTitle,
  displayExportButton,
  handleDisplayExportButton,
  disabledExportBtn,
  userEdit = false,
}) {
  const classes = useStyles();
  const [rowsPage, setRowsPage] = useState(rowsPerPage);

  //Handle the case when the body of table is empty and the currentPage diffrent to 1
  useEffect(() => {
    if (tbBody.length === 0 && currentPage > 1) changePage(1, rowsPage);
  }, [tbBody, currentPage, rowsPage, changePage]);

  //function to change the page
  const handleChangePage = (event, page) => {
    changePage(page + 1, rowsPage);
  };
  //get the value of rowPerPage from props
  useEffect(() => {
    setRowsPage(rowsPerPage);
  }, [rowsPerPage]);

  const handleChangeRowsPerPage = (event) => {
    if (event.target.value > rowsPage) {
      let page = Math.round(
        ((event.target.value / rowsPage) * currentPage) / event.target.value
      );
      changeRowsPerPage(page, event.target.value);
    } else changeRowsPerPage(1, event.target.value);
    //   setRowsPage(event.target.value);
  };
  //customize the message of pagination
  const displayedRows = ({ from, to, count }) =>
    `${from}-${to} ${t('common.of')} ${count}`;

  return (
    <Fragment>
      <Grid item xs={12} className={classes.gridStyle}>
        {displayHeaderComponent && (
          <HeaderComponent
            displayFilter={displayFilter}
            displaySearch={displaySearch}
            displayConfigButton={displayConfigButton}
            configButtonText={configButtonText}
            displayUploadBtn={displayUploadBtn}
            displayAddOrApplyBtn={displayAddOrApplyBtn}
            textBtnUpload={textBtnUpload}
            textBtnAddOrApply={textBtnAddOrApply}
            handleAddOrApplyBtn={openFormDialog}
            title={title}
            handleSearch={handleSearch}
            handleFirstBtn={handleFirstBtn}
            onChangeUploadBtn={onChangeUploadBtn}
            onChangeConfigButton={onChangeConfigButton}
            handleFirstCheckBox={handleFirstCheckBox}
            handleSecondCheckBox={handleSecondCheckBox}
            checkedFirstCheckBox={checkedFirstCheckBox}
            checkedSecondtCheckBox={checkedSecondtCheckBox}
            loadingLastBtn={loadingLastBtn}
            name={name}
            displayAddIcon={displayAddIcon}
            firstLabelOfPicker={firstLabelOfPicker}
            secondLabelOfPicker={secondLabelOfPicker}
            selectedFirstDate={selectedFirstDate}
            selectedSecondDate={selectedSecondDate}
            changeFistDate={changeFistDate}
            changeSecondDate={changeSecondDate}
            disabledSecondDatePicker={disabledSecondDatePicker}
            diplayPickerUi={diplayPickerUi}
            selectedFirstDateError={selectedFirstDateError}
            selectedSecondDateError={selectedSecondDateError}
            displayExportButton={displayExportButton}
            handleDisplayExportButton={handleDisplayExportButton}
            disabledExportBtn={disabledExportBtn}
          />
        )}
        <Divider component="hr" light={true} className={classes.dividerStyle} />
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table className={classes.table}>
            <Fragment>
              <HearderDynamicTable
                tbHeader={tbHeader}
                orderBy={orderBy}
                order={order}
                tbTriBy={tbTriBy}
                fetchDataBy={fetchDataBy}
              />
              <BodyDynamicTable
                listIcon={listIcon}
                tbHeader={tbHeader}
                keys={keys}
                tbBody={tbBody}
                visibilityIcon={visibilityIcon}
                editIcon={editIcon}
                deleteIcon={deleteIcon}
                total={total}
                openUpdateFormDialog={openUpdateFormDialog}
                openDeleteDialog={openDeleteDialog}
                IdOfAdminConnected={IdOfAdminConnected}
                loading={loading}
                handleListIcon={handleListIcon}
                handleShowBtn={handleShowBtn}
                countClientByid={countClientByid}
                displayRowsInfoRequestPanel={displayRowsInfoRequestPanel}
                countStatueProcessed={countStatueProcessed}
                countStatueWaiting={countStatueWaiting}
                userOffline={userOffline}
                userOnline={userOnline}
                infoRequestPanelFirstRowsTitle={infoRequestPanelFirstRowsTitle}
                infoRequestPanelSecondRowsTitle={
                  infoRequestPanelSecondRowsTitle
                }
                infoRequestPanelThirdRowsTitle={infoRequestPanelThirdRowsTitle}
                totalInscriptions={totalInscriptions}
                displayRowsOfCreditRequestPanel={
                  displayRowsOfCreditRequestPanel
                }
                creditRequestPanelRowTitle={creditRequestPanelRowTitle}
                userEdit={userEdit}
              />
            </Fragment>
          </Table>
        </TableContainer>

        <Paper>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            backIconButtonText={t('common.backIconButtonText')}
            nextIconButtonText={t('common.backIconButtonText')}
            count={total}
            rowsPerPage={rowsPage}
            page={currentPage - 1}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
            labelRowsPerPage={t('common.rows.per.page')}
            labelDisplayedRows={displayedRows}
          />
        </Paper>
      </Grid>
    </Fragment>
  );
}
const DynamicTableHOC = withI18n()(DynamicTable); //Higher-Order Component
export { DynamicTableHOC as DynamicTable };

import axios from 'axios';
import config from '../../config/config';
import { UNAUTHORISED_STATUS, history } from '../../helpers';
import aes from 'crypto-js/aes';
import encHex from 'crypto-js/enc-hex';
import padZeroPadding from 'crypto-js/pad-zeropadding';

export const methodsServices = {
  get,
  post,
  put,
  deleteDetail,
};
const cryptApiKey = (apiKey) => {
  const keyApi = apiKey + Math.floor(Date.now() / 1000);
  let key = encHex.parse(config.privateKey);
  let iv = encHex.parse(config.privateIV);
  let encrypted = aes
    .encrypt(keyApi, key, { iv: iv, padding: padZeroPadding })
    .toString();

  return encrypted;
};

async function get(apiEndpoint, store) {
  const authStr = `Bearer ${localStorage.getItem('token')}`; //Prepare the authorization with the token
  return axios
    .get(config.baseUrl + apiEndpoint, {
      headers: {
        Authorization: authStr,
        'x-api-key': cryptApiKey(config.appKey),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (
        err.response &&
        err.response.status &&
        UNAUTHORISED_STATUS == err.response.status
      ) {
        localStorage.clear();
        history.push('/login');
        return Promise.reject('common.unauthorized');
      } else {
        return Promise.reject(err.response);
      }
    });
}

async function post(apiEndpoint, payload, headers = false) {
  let headersData = {
    'x-api-key': cryptApiKey(config.appKey),
  };

  if (headers) {
    const authStr = `Bearer ${localStorage.getItem('token')}`; //Prepare the authorization with the token

    headersData['Authorization'] = authStr;
  }

  return await axios
    .post(config.baseUrl + apiEndpoint, payload, {
      headers: headersData,
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (
        err.response &&
        err.response.status &&
        UNAUTHORISED_STATUS == err.response.status
      ) {
        localStorage.clear();
        history.push('/login');
        return Promise.reject('common.unauthorized');
      } else {
        return Promise.reject(err.response);
      }
    });
}

async function put(apiEndpoint, payload) {
  return axios
    .put(config.baseUrl + apiEndpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (
        err.response &&
        err.response.status &&
        UNAUTHORISED_STATUS == err.response.status
      ) {
        localStorage.clear();
        history.push('/login');
        return Promise.reject('common.unauthorized');
      } else {
        return Promise.reject(err.response);
      }
    });
}
async function deleteDetail(apiEndpoint) {
  const authStr = `Bearer ${localStorage.getItem('token')}`; //Prepare the authorization with the token
  return axios
    .delete(config.baseUrl + apiEndpoint, {
      headers: {
        Authorization: authStr,
        'x-api-key': cryptApiKey(config.appKey),
      },
    })
    .then((response) => {
      return response;
    })
    .catch((err) => {
      if (
        err.response &&
        err.response.status &&
        UNAUTHORISED_STATUS == err.response.status
      ) {
        localStorage.clear();
        history.push('/login');
        return Promise.reject('common.unauthorized');
      } else {
        return Promise.reject(err.response);
      }
    });
}

import {
  GET_AGENCY_FAILED,
  GET_AGENCY_SUCCESS,
  AGENCIES_OPEN_FORM_DIALOG,
  AGENCIES_UPADTE_FORM_DIALOG,
  CHANGE_DELETE_DIALOG_AGENCIE,
  UPDATE_AGENCIE_SUCCESS,
  LOADING_AGENCIES,
  CREATE_AGENCIE_SUCCESS,
  AGENCIE_FAILED,
  DELETE_AGENCIE_SUCCESS,
  GET_AGENCIES,
  AGENCIE_UNIQUE_NAME,
} from '../types';
import { PER_PAGE, CURRENT_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  openDialogForm: false,
  disableButton: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
  phoneError: '',
  uniqueName: '',
};

const agenciesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_AGENCY_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        perPage: Number(action.payload.per_page),
        total: action.payload.total,
        loading: false,
      };
    case GET_AGENCY_FAILED:
      return { ...state, loading: false };
    case AGENCIES_OPEN_FORM_DIALOG:
      return { ...state, openDialogForm: action.payload };
    case AGENCIES_UPADTE_FORM_DIALOG:
      return { ...state, openUpdateDialogForm: action.payload };
    case CHANGE_DELETE_DIALOG_AGENCIE:
      return { ...state, openDeleteDialog: action.payload };
    case UPDATE_AGENCIE_SUCCESS:
      return { ...state, openUpdateDialogForm: false, disableButton: false };
    case LOADING_AGENCIES:
      return { ...state, disableButton: true };
    case CREATE_AGENCIE_SUCCESS:
      return { ...state, openDialogForm: false, disableButton: false };
    case AGENCIE_FAILED:
      return { ...state, disableButton: false };
    case DELETE_AGENCIE_SUCCESS:
      return { ...state, openDeleteDialog: false };
    case GET_AGENCIES:
      return { ...state, loading: true };
    case AGENCIE_UNIQUE_NAME:
      return { ...state, uniqueName: action.payload };
    default:
      return state;
  }
};

export default agenciesReducer;

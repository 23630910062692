import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { withI18n } from 'react-i18next';
import { history, VARIANT_CONTAINED, ALIGN_CENTER } from '../helpers';
import Button from '@material-ui/core/Button';
import piggyBank from '../assets/img/piggy-bank.jpg';
import Grid from '@material-ui/core/Grid';
import '../assets/sass/common.scss';
import logo from '../assets/img/enda-logo.png';

class NotFound extends Component {
  goHome = (prop) => (event) => {
    history.push('/');
  };
  render() {
    const { t } = this.props;

    return (
      <div className="not-found-page-container">
        <Grid container direction="column">
          <img src={logo} alt="enda logo" className="not-found-page-logo" />
          <Grid
            className="not-found-page-content"
            container
            alignContent={ALIGN_CENTER}
            alignItems={ALIGN_CENTER}
            justify={ALIGN_CENTER}
          >
            <Grid item md={5} xs={12}>
              <img src={piggyBank} alt="404" />
            </Grid>
            <Grid item md={5} xs={12}>
              <div>
                <Typography component={'span'} variant={'h1'}>
                  {t('common.ops')}
                </Typography>
              </div>
              <div>
                <Typography component={'span'} variant={'body1'}>
                  {t('common.notfound')}
                </Typography>
              </div>

              <Button
                className="home-btn"
                variant={VARIANT_CONTAINED}
                onClick={this.goHome()}
              >
                {t('common.gohome')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return state;
}

const connectedNotFoundPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withI18n()(NotFound))
);
export { connectedNotFoundPage as NotFound };

let token = localStorage.getItem("token");
let role = localStorage.getItem("role");
let user = JSON.parse(localStorage.getItem("user"));
let sections = user && user.profile ? user.profile.sections : [];

const initialState = token ? { loggedIn: true, role, token, sections } : {};
export function authentication(state = initialState, action) {
	switch (action.type) {
		case "LOGIN_SUCCESS":
			return {
				loggingIn: true,
				role: action.role,
				token: action.token,
				sections: action.sections,
			};

		case "LOGOUT_SUCCESS":
			return {
				role: "",
				token: "",
			};

		default:
			return state;
	}
}
export const reducer = (state = initialState, action) => {};

export const styles = (theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	container: {
		display: "flex",
		flexWrap: "wrap",
	},
	margin: {
		margin: theme.spacing(3),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		width: 200,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	button: {
		margin: theme.spacing(3),
	},
	input: {
		display: "none",
	},
	loginButtonContainer: {
		display: "flex",
		[theme.breakpoints.down("xs")]: {
			justifyContent: "center",
		},
	},
	loginFormContainer: {
		[theme.breakpoints.down("xs")]: {
			padding: 10,
		},
		paddingRight: 60,
		paddingLeft: 60,
		paddingBottom: 20,
	},
	resetPaswordContainer: {
		[theme.breakpoints.down("xs")]: {
			padding: 10,
			justifyContent: "center",
			alignItems: "center",
		},
		alignItems: "flex-end",
		paddingBottom: 20,
	},
});

import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicTable } from '..';
import {
  CREDIT_REQUEST_STATISTICS_TABLE_HEADER,
  CREDIT_REQUEST_STATISTICS_TABLE_TRI_BY,
  CREDIT_REQUEST_STATISTICS_KEYS,
  changeFormatDate,
  getChartColor,
  ENDPOINT_STATISTIC,
  ENDPOINT_CREDIT_REQUEST_STATISTICS,
  EXPORT,
} from '../../helpers';
import {
  fetchCreditRequestStatistics,
  downloadFile,
} from '../../store/actions';
import { LineChart } from '../lineChart/LineChart';
import { FormDialog } from '../formDialog/FormDialog';
import { CreditRequestPreview } from '../creditRequestPreview/CreditRequestPreview';
/**
 * Component CreditRequestStatisticsPanel
 *
 * @component
 *
 * @example
 * return (
 *   <CreditRequestStatisticsPanel/>
 * )
 */
function CreditRequestStatisticsPanel({ t }) {
  const dispatch = useDispatch();
  const resetPassword = useSelector((state) => state.resetPassword);
  const creditRequestStatistics = useSelector(
    (state) => state.creditRequestStatistics
  );
  const download = useSelector((state) => state.download);
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [startAtError, setStartAtError] = useState('');
  const [endAtError, setEndAtError] = useState('');
  const [startAtFormat, setStartAtFormat] = useState('');
  const [endAtFormat, setEndAtFormat] = useState('');
  const [disbaleSecondDate, setDisabledSecondDate] = useState(true);
  const [dataChart, setDataChart] = useState({});
  const [loadingChart, setloadingChart] = useState(true);
  const [open, setOpen] = useState(false);
  const [creditRequest, setCreditRequest] = useState({});

  //Costomize options of lineChart
  const options = {
    legend: {
      display: true,
    },
    title: {
      display: true,
      text: t('credit.requests.statistics.chart.title'),
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            precision: 0,
          },
        },
      ],
    },
  };
  useEffect(() => {
    dispatch(
      fetchCreditRequestStatistics(
        creditRequestStatistics.currentPage,
        creditRequestStatistics.perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  }, []);
  useEffect(() => {
    if (Object.values(creditRequestStatistics.statistics).length !== 0) {
      setloadingChart(false);
    }

    let datasets = [];
    let colorIndex = 0;
    let labels = [];
    let data = Object.values(creditRequestStatistics.statistics);
    data.forEach((element) => {
      labels.push(element.date);
    });

    labels.forEach((label, index) => {
      data[index]['groupByTypes'].forEach((element) => {
        let data = [];
        labels.forEach((label, i) => {
          data[i] = 0;
        });

        const isSameProduct = (product) =>
          product.label == (element.product_name || t('common.unknow'));
        const sameElemtIndex = datasets.findIndex(isSameProduct);

        if (sameElemtIndex !== -1) {
          datasets[sameElemtIndex].data[index] = element.total;
        } else {
          const chartColor = getChartColor(colorIndex);
          data[index] = element.total;
          datasets.push({
            label: element.product_name || t('common.unknow'),
            data: data,
            fill: true,
            backgroundColor: chartColor.color.back,
            borderColor: chartColor.color.line,
          });
          colorIndex++;
        }
      });
    });

    setDataChart({ labels: labels, datasets: datasets });
  }, [creditRequestStatistics.statistics]);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchCreditRequestStatistics(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchCreditRequestStatistics(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };

  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('credit.requests.date'):
        return CREDIT_REQUEST_STATISTICS_KEYS[0];
      case t('credit.requests.lastname'):
        return CREDIT_REQUEST_STATISTICS_KEYS[1];
      case t('credit.requests.firstname'):
        return CREDIT_REQUEST_STATISTICS_KEYS[2];
      case t('credit.requests.phone'):
        return CREDIT_REQUEST_STATISTICS_KEYS[3];
      default:
        return CREDIT_REQUEST_STATISTICS_KEYS[0];
    }
  };

  /**
   *  get list using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleTriByKey = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchCreditRequestStatistics(
        creditRequestStatistics.currentPage,
        creditRequestStatistics.perPage,
        getColumnName(orderBy),
        orderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //When the user change the data start
  const handleChangeStartAt = (date) => {
    setStartAt(date.target.value);
    setStartAtFormat(changeFormatDate(date.target.value));
    setEndAt('');
    setStartAtError('');
    if (disbaleSecondDate) setDisabledSecondDate(false);
  };
  //When the user change the data end
  const handleChangeEndAt = (date) => {
    setEndAtError('');
    setEndAt(date.target.value);
    setEndAtFormat(changeFormatDate(date.target.value));
  };
  //Handle button done
  const handleBtnDone = () => {
    if (!startAt) {
      setStartAtError('common.starts_at.error');
      setEndAtError('common.ends_at.error');
    } else if (!endAt) {
      setEndAtError('common.ends_at.error');
    } else {
      dispatch(
        fetchCreditRequestStatistics(
          creditRequestStatistics.currentPage,
          creditRequestStatistics.perPage,
          tableOrderBy,
          tableOrderDirection,
          startAtFormat,
          endAtFormat
        )
      );
    }
  };
  const handleExportToExcel = () => {
    if (startAt && !endAt) {
      setEndAtError('common.ends_at.error');
    } else {
      dispatch(
        downloadFile(
          startAtFormat,
          endAtFormat,
          `${ENDPOINT_STATISTIC}${ENDPOINT_CREDIT_REQUEST_STATISTICS}${EXPORT}`
        )
      );
    }
  };
  const openDialog = (data) => {
    setCreditRequest(data);
    setOpen(true);
  };
  const handleCloseDialog = () => {
    setOpen(false);
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={CREDIT_REQUEST_STATISTICS_TABLE_HEADER}
        keys={CREDIT_REQUEST_STATISTICS_KEYS}
        visibilityIcon={true}
        editIcon={false}
        deleteIcon={false}
        displayHeaderComponent={true}
        tbBody={creditRequestStatistics.data}
        rowsPerPage={creditRequestStatistics.perPage}
        total={creditRequestStatistics.total}
        currentPage={creditRequestStatistics.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        displayAddOrApplyBtn={true}
        textBtnAddOrApply={'common.enter'}
        loading={creditRequestStatistics.loading}
        tbTriBy={CREDIT_REQUEST_STATISTICS_TABLE_TRI_BY}
        fetchDataBy={handleTriByKey}
        displayAddIcon={false}
        secondLabelOfPicker={'common.ends_at'}
        firstLabelOfPicker={'common.starts_at'}
        selectedFirstDate={startAt}
        selectedSecondDate={endAt}
        changeFistDate={handleChangeStartAt}
        changeSecondDate={handleChangeEndAt}
        disabledSecondDatePicker={disbaleSecondDate}
        diplayPickerUi={true}
        loadingLastBtn={creditRequestStatistics.loadingLastBtn}
        openFormDialog={handleBtnDone}
        selectedFirstDateError={startAtError}
        selectedSecondDateError={endAtError}
        userOffline={creditRequestStatistics.offline}
        userOnline={creditRequestStatistics.online}
        displayRowsOfCreditRequestPanel={true}
        creditRequestPanelRowTitle={'credit.requests.total'}
        displayExportButton={true}
        handleDisplayExportButton={handleExportToExcel}
        disabledExportBtn={download.loading}
        handleShowBtn={openDialog}
      />
      {!creditRequestStatistics.loading ? (
        creditRequestStatistics.data.length !== 0 ? (
          <LineChart
            data={dataChart}
            options={options}
            loading={loadingChart}
          />
        ) : null
      ) : (
        <LineChart data={dataChart} options={options} loading={loadingChart} />
      )}

      <FormDialog
        openFormDialog={open}
        handleCloseFormDialog={handleCloseDialog}
        title={'common.preview'}
      >
        <CreditRequestPreview creditRequest={creditRequest} />
      </FormDialog>
    </Fragment>
  );
}

const connectedCreditRequestStatisticsPanel = withI18n()(
  CreditRequestStatisticsPanel
);

export { connectedCreditRequestStatisticsPanel as CreditRequestStatisticsPanel };

import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import clsx from "clsx";
import { history, FONT_SIZE_LARGE, isAgent } from "../../helpers";
import {
	authActions,
	openDialogResetPassword,
	closeDialogResetPassword,
	resetPasswordAction,
} from "../../store/actions";
import { OPEN_DRAWER, CLOSE_DRAWER } from "../../store/types";
import {
	IconButton,
	Tooltip,
	withStyles,
	ListItemText,
	ListItemIcon,
	ListItem,
	Drawer,
	CssBaseline,
	AppBar,
	Toolbar,
	List,
	Grid,
	Typography,
	Divider,
	Hidden,
} from "@material-ui/core";
import {
	ExitToApp as ExitToAppIcon,
	Menu as MenuIcon,
	ChevronLeft as ChevronLeftIcon,
	Lock as LockIcon,
	ArrowBack as ArrowBackIcon,
} from "@material-ui/icons";
import logo from "../../assets/img/logo-trans.png";
import { styles } from "./style";
import { getMenu } from "../../helpers";
import { FormDialog, ResetPasswordForm } from "../";

/**
 * Component side bar for the admin
 *
 * @component
 *
 * const route = '/home'
 * const name = 'home'
 * @example
 * return (
 *   <AdminSidebar route={route} name={name}/>
 * )
 */
class AdminSidebar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			route: props.route,
		};
	}
	handleDrawerOpen = (prop) => (event) => {
		const { dispatch } = this.props;
		dispatch({ type: OPEN_DRAWER });
	};
	handleDrawerClose = (prop) => (event) => {
		const { dispatch } = this.props;
		dispatch({ type: CLOSE_DRAWER });
	};
	changePage = (prop) => (event) => {
		history.push(prop);
	};
	logout = (prop) => (event) => {
		const { dispatch } = this.props;
		dispatch(authActions.logout());
	};
	//open ResetPassword dialog
	resetPassword = () => () => {
		const { dispatch } = this.props;
		dispatch(openDialogResetPassword());
	};
	handleCloseDialogResetPassword = () => () => {
		const { dispatch } = this.props;
		dispatch(closeDialogResetPassword());
	};
	handleResetPassword = (currentPassword, password, passwordConfirm) => {
		const { dispatch } = this.props;
		dispatch(resetPasswordAction(currentPassword, password, passwordConfirm));
	};

	render() {
		const {
			classes,
			t,
			route,
			name,
			drawer,
			displayArrowBack = false,
			sections,
			resetPasswordDialog,
		} = this.props;
		const menu = getMenu(sections);
		return (
			<div>
				<CssBaseline />
				<AppBar
					position="fixed"
					className={clsx(classes.appBar, {
						[classes.appBarShift]: drawer,
					})}
				>
					<Toolbar>
						<IconButton
							color="inherit"
							aria-label="open drawer"
							onClick={this.handleDrawerOpen()}
							edge="start"
							className={clsx(classes.menuButton, {
								[classes.hide]: drawer,
							})}
						>
							<MenuIcon />
						</IconButton>
						<Grid container justify="space-between" alignItems="center">
							<Hidden smDown>
								<Grid className="logout-container">
									{displayArrowBack && (
										<Tooltip title={t("common.arrow.back")} aria-label="add">
											<span>
												<IconButton onClick={() => history.goBack()}>
													<ArrowBackIcon fontSize={FONT_SIZE_LARGE} />
												</IconButton>
											</span>
										</Tooltip>
									)}
								</Grid>

								<Grid>
									<Typography variant="h5" noWrap className="menu-page-title">
										{t(name)}
									</Typography>
								</Grid>
								<Grid className="logout-container">
									{isAgent() && (
										<Tooltip
											title={t("common.reset.password")}
											aria-label="add"
										>
											<span>
												<IconButton onClick={this.resetPassword()}>
													<LockIcon fontSize={FONT_SIZE_LARGE} />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<Tooltip title={t("common.logout")} aria-label="add">
										<span>
											<IconButton onClick={this.logout()}>
												<ExitToAppIcon fontSize={FONT_SIZE_LARGE} />
											</IconButton>
										</span>
									</Tooltip>
								</Grid>
							</Hidden>

							<Hidden mdUp>
								<Grid
									sm={12}
									container
									item
									justify="flex-end"
									className="logout-container"
								>
									{isAgent() && (
										<Tooltip
											title={t("common.reset.password")}
											aria-label="add"
										>
											<span>
												<IconButton onClick={this.resetPassword()}>
													<LockIcon fontSize={FONT_SIZE_LARGE} />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<Tooltip title={t("common.logout")} aria-label="add">
										<span>
											<IconButton onClick={this.logout()}>
												<ExitToAppIcon fontSize={FONT_SIZE_LARGE} />
											</IconButton>
										</span>
									</Tooltip>
								</Grid>
							</Hidden>
						</Grid>
					</Toolbar>
				</AppBar>
				<Hidden mdUp>
					<Drawer
						variant="temporary"
						anchor="left"
						open={drawer}
						onClose={this.handleDrawerClose()}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						<Grid container className="drawer--logo-container">
							<Grid
								xs={11}
								className="logo-img"
								container
								item
								justify="center"
							>
								<img src={logo} alt="My Logo" />
							</Grid>
							<Grid xs={1} container item className={classes.toolbar}>
								<IconButton onClick={this.handleDrawerClose()}>
									<ChevronLeftIcon fontSize={FONT_SIZE_LARGE} />
								</IconButton>
							</Grid>
						</Grid>

						<Divider />
						<List>
							{menu.map((item, index) => (
								<ListItem
									button
									key={item.name}
									selected={route == item.route}
									onClick={this.changePage(item.route)}
								>
									<ListItemIcon className="menu-item-icon">
										{item.icon}
									</ListItemIcon>
									<ListItemText
										primary={t(item.name)}
										className="menu-item-text"
									/>
								</ListItem>
							))}
						</List>
					</Drawer>
				</Hidden>
				<Hidden smDown implementation="css">
					<Drawer
						variant="permanent"
						className={clsx(classes.drawer, {
							[classes.drawerOpen]: drawer,
							[classes.drawerClose]: !drawer,
						})}
						classes={{
							paper: clsx({
								[classes.drawerOpen]: drawer,
								[classes.drawerClose]: !drawer,
							}),
						}}
					>
						<Grid container className="drawer--logo-container">
							<Grid
								sm={10}
								className="logo-img"
								container
								item
								justify="center"
							>
								<img src={logo} alt="My Logo" />
							</Grid>
							<Grid sm={2} container item className={classes.toolbar}>
								<IconButton onClick={this.handleDrawerClose()}>
									<ChevronLeftIcon fontSize={FONT_SIZE_LARGE} />
								</IconButton>
							</Grid>
						</Grid>

						<Divider />
						<List>
							{menu.map((item, index) => (
								<ListItem
									button
									key={item.name}
									selected={route == item.route}
									onClick={this.changePage(item.route)}
								>
									<ListItemIcon className="menu-item-icon">
										{item.icon}
									</ListItemIcon>
									<ListItemText
										primary={t(item.name)}
										className="menu-item-text"
									/>
								</ListItem>
							))}
						</List>
					</Drawer>
				</Hidden>{" "}
				{/* Dialog To reset password */}
				<FormDialog
					openFormDialog={resetPasswordDialog}
					handleCloseFormDialog={this.handleCloseDialogResetPassword()}
					buttonTitle={"users.button.reset.password"}
					title={"user.text.button.reset.password"}
				>
					<ResetPasswordForm
						textButton={"user.text.button.reset.password"}
						handleButton={this.handleResetPassword}
					/>
				</FormDialog>
			</div>
		);
	}
}

AdminSidebar.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	const { drawer } = state.ui;
	const { sections } = state.authentication;
	const { resetPasswordDialog } = state.users;
	return {
		drawer,
		sections,
		resetPasswordDialog,
	};
}

const connectedAdminSidebarPage = withRouter(
	connect(mapStateToProps, null, null, {
		pure: false,
	})(withI18n()(withStyles(styles)(AdminSidebar)))
);
export { connectedAdminSidebarPage as AdminSidebar };

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAdmin, isLoggedIn } from "../helpers";

/**
 * Route gaurd for admins
 *
 * @param {*} param0
 */
export const AdminRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLoggedIn() && isAdmin() ? (
				<Component {...props} />
			) : (
				<Redirect to="/login" />
			)
		}
	/>
);

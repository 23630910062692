export const PREFIX_ADMIN = 'admin';
export const ENDPOINT_USER = '/user';
export const ENDPOINT_BANNER = '/banner';
export const PAGE = '?page=';
export const ENDPOINT_PER_PAGE = ' &perPage=';
export const SEARCH = ' &search=';
export const ENDPOINT_LOGIN = '/login';
export const ENDPOINT_LOGOUT = 'logout';
export const ENDPOINT_PASSWORD_EMAIL = '/password/email';
export const ENDPOINT_PASSWORD_RESET = '/password/reset';
export const ENDPOINT_CLIENT = '/client';
export const ENDPOINT_GOUVERNORATE = 'governorate';
export const ENDPOINT_DELEGATION = '/delegation';
export const ENDPOINT_PACK = '/pack';
export const ENDPOINT_CREDIT = '/product';
export const ENDPOINT_AGENT = '/agent';
export const ENDPOINT_IMPORT = '/import ';
export const ENDPOINT_INFO_REQUEST = "/info-request";
export const ENDPOINT_CLAIM = '/claim';
export const ENDPOINT_TYPE = '&type=';
export const ENDPOINT_AGENCY = '/agency';
export const ENDPOINT_STATUS = '&status=';
export const ENDPOINT_AGENCY_ID = '&agency_id=';
export const ENDPOINT_NOTIFICATION = '/notification';
export const ENDPOINT_AUTO_NOTIFICATION = '/notification/auto';
export const ORDER_BY = '&orderBy=';
export const ORDER_DIRECTION = '&orderDirection=';
export const ENDPOINT_STATISTIC = '/statistic';
export const ENDPOINT_REST_PASSWORD = '/reset-password';
export const STARTS_AT = '&starts_at=';
export const ENDS_AT = '&ends_at=';
export const ENDPOINT_STATISTIC_INFO = '/info-request';
export const ENDPOINT_PAGINATED_GOUVERNORATE = '/governorate';
export const ENDPOINT_PAGINATED_DELEGATION = '/delegation';
export const ENDPOINT_ENDA_AGENCY = '/enda-agency';
export const ENDPOINT_ENDA_PRODUCT = '/enda-product';
export const ENDPOINT_INSCRIPTION_STATISTICS = '/inscription';
export const ENDPOINT_STATISTIC_CLAIM = '/claim';
export const ENDPOINT_CREDIT_REQUEST_STATISTICS = '/credit-request';
export const BEGIN_AT = '?starts_at=';
export const EXPORT = '/export';
export const ENDPOINT_PROFILE = "/user-profile";
export const ENDPOINT_SECTION = "/section";
export const ORDER_BY_ID = "id";
export const ENDPOINT_PASSWORD = "/password";

import {
  GET_ENDA_PRODUCTS_FAILED,
  GET_ENDA_PRODUCTS_SUCCESS,
  LOADING_ENDA_PRODUCTS,
  LOAD_MORE_ENDA_PRODUCTS_SUCCESS,
  LOAD_MORE_ENDA_PRODUCTS_FAILED,
} from '../types';

import { ORDER_DIRECTION_ASC, CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: 15,
  openDialogForm: false,
  disableButton: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
  phoneError: '',
  lastPage: 1,
  orderBy: 'name',
  orderDirection: ORDER_DIRECTION_ASC,
};

const endaProductReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_ENDA_PRODUCTS:
      return {
        ...state,
        loading: true,
      };
    case GET_ENDA_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        perPage: Number(action.payload.per_page),
        total: action.payload.total,
        lastPage: action.payload.last_page,
        loading: false,
      };
    case LOAD_MORE_ENDA_PRODUCTS_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.payload.data),
        currentPage: action.payload.current_page,
        total: action.payload.total,
        lastPage: action.payload.last_page,
        loading: false,
      };
    case GET_ENDA_PRODUCTS_FAILED:
      return { ...state, loading: false };
    case LOAD_MORE_ENDA_PRODUCTS_FAILED:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default endaProductReducer;

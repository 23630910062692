import {
  GET_INSCRIPTION_STATISTICS_SUCCESS,
  GET_INSCRIPTION_STATISTICS_FAILED,
  INSCRIPTION_STATISTICS_LOADING_LAST_BTN,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  countByClient: null,
  loadingLastBtn: false,
  statistics: {},
};

const inscriptionStatisticsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INSCRIPTION_STATISTICS_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        currentPage: action.payload.data.current_page,
        perPage: Number(action.payload.data.per_page),
        total: action.payload.data.total,
        countByClient: action.payload.countByClient,
        statistics: action.payload.statistics,
        loading: false,
      };
    case GET_INSCRIPTION_STATISTICS_FAILED:
      return { ...state, loading: false };
    case INSCRIPTION_STATISTICS_LOADING_LAST_BTN:
      return { ...state, loadingLastBtn: action.payload };
    default:
      return state;
  }
};
export default inscriptionStatisticsReducer;

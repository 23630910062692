import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicTable, FormDialog, RequestForm } from '../components';
import {
  INFO_TABLE_HEADER,
  INFO_KEYS,
  INFO_TABLE_TRI_BY,
  KEYS_CREATED_AT,
  SET_TIME_OUT,
} from '../helpers/constantes';
import {
  upadteInfoRequest,
  fetchInfoRequest,
  infoUpdateFormDialog,
  enableBtnInfoRequest,
} from '../store/actions';

/**
 * Component InfoRequest
 *
 * @component
 *
 * @example
 * return (
 *   <InfoRequest/>
 * )
 */
function InfoRequest({ t }) {
  const dispatch = useDispatch();
  const infoRequest = useSelector((state) => state.infoRequest);
  const [data, setData] = useState({});
  const [waiting, setWaiting] = useState(true);
  const [processed, setProcessed] = useState(true);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);

  useEffect(() => {
    dispatch(
      fetchInfoRequest(
        infoRequest.currentPage,
        infoRequest.perPage,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  }, []);

  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchInfoRequest(
        page,
        perPage,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchInfoRequest(
        page,
        perPage,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };

  //Function to upadate a agent
  const handleUpdateInfoRequest = (statusOfRequest, note) => {
    dispatch(
      upadteInfoRequest(
        statusOfRequest,
        note,
        infoRequest.currentPage,
        infoRequest.perPage,
        data.id,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  const handleSearch = (message) => {
    setSearch(message);
    dispatch(
      fetchInfoRequest(
        infoRequest.currentPage,
        infoRequest.perPage,
        infoRequest.status,
        message,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (info) => {
    setData(info);
    dispatch(infoUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(infoUpdateFormDialog(false));
  };
  //when the user select the Awating box
  const handleAwatingCheckBox = (e) => {
    let checked = e.target.checked;
    setWaiting(e.target.checked);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        handleStatusChange(checked, processed);
      }, SET_TIME_OUT)
    );
  };
  //when the user select the processed box
  const handleSecondCheckBox = (e) => {
    let checked = e.target.checked;
    setProcessed(e.target.checked);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        handleStatusChange(waiting, checked);
      }, SET_TIME_OUT)
    );
  };
  //when the user change status
  const handleStatusChange = (wait, process) => {
    let status;
    //case waiting and processed checked
    if ((wait && process) || (!wait && !process)) {
      status = 0;
      setStatus(0);
    } else {
      //case waiting is checked
      if (wait) {
        status = 1;
        setStatus(1);
      } else {
        //case processed is checked
        status = 2;
        setStatus(3);
      }
    }

    //get the new data with new status
    dispatch(
      fetchInfoRequest(
        1,
        infoRequest.perPage,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('informations.date'):
        return KEYS_CREATED_AT;
      case t('informations.lastName'):
        return INFO_KEYS[1];
      default:
        return INFO_KEYS[2];
    }
  };
  /**
   *  get list of agent using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchInfoRequestBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);

    dispatch(
      fetchInfoRequest(
        infoRequest.currentPage,
        infoRequest.perPage,
        status,
        search,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };

  return (
    <Fragment>
      <DynamicTable
        tbHeader={INFO_TABLE_HEADER}
        keys={INFO_KEYS}
        displayFilter={true}
        visibilityIcon={false}
        deleteIcon={false}
        displayHeaderComponent={true}
        tbBody={infoRequest.data}
        rowsPerPage={infoRequest.perPage}
        total={infoRequest.total}
        currentPage={infoRequest.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openUpdateFormDialog={openUpdateFormDialog}
        title={'informations.title'}
        displayAddOrApplyBtn={false}
        handleSearch={handleSearch}
        textBtnAddOrApply={'informations.search'}
        loading={infoRequest.loading}
        handleFirstCheckBox={handleAwatingCheckBox}
        handleSecondCheckBox={handleSecondCheckBox}
        checkedFirstCheckBox={waiting}
        checkedSecondtCheckBox={processed}
        loadingLastBtn={infoRequest.loadingBtn}
        displaySearch={true}
        tbTriBy={INFO_TABLE_TRI_BY}
        fetchDataBy={handleFetchInfoRequestBy}
      />

      {/* Dialog To update  infoRequest */}
      <FormDialog
        openFormDialog={infoRequest.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        title={'agents.update'}
      >
        <RequestForm
          disableButton={infoRequest.disableButton}
          spinnerDisablebtn={infoRequest.spinnerDisablebtn}
          textButton={'informations.btn.update'}
          infoRequest={data}
          handleButton={handleUpdateInfoRequest}
          enableBtn={enableBtnInfoRequest}
        />
      </FormDialog>
    </Fragment>
  );
}
const connectedInfoRequestPage = withI18n()(InfoRequest); //Higher-Order Component
export { connectedInfoRequestPage as InfoRequest };

import {
  LOADING_NOTIFICATION,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_ERROR,
  DELETED_NOTIFICATION_SUCCESS,
  DELETE_DIALOG_NOTIFICATION,
  ADD_DIALOG_NOTIFICATION,
  CONFIG_DIALOG_NOTIFICATION,
  PREVIEW_DIALOG_NOTIFICATION,
  LOADING_AUTO_NOTIFICATION,
  FETCH_AUTO_NOTIFICATIONS_SUCCESS,
  FETCH_AUTO_NOTIFICATIONS_ERROR,
  UPDATE_AUTO_NOTIFICATION_SUCCESS,
  UPDATE_AUTO_NOTIFICATION_ERROR,
  LOADING_UPDATE,
  DELETE_NOTIFICATION_ERROR,
  ADD_NOTIFICATION_SUCCESS,
  LOADING_ADD,
  ADD_NOTIFICATION_ERROR,
} from '../types';
import {
  DEFAULT_ORDER_DIRECTION,
  DEFAULT_ORDER_BY,
  CURRENT_PAGE,
  PER_PAGE,
} from '../../helpers';

const initialState = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  error: null,
  deleteDialog: false,
  addDialog: false,
  configDialog: false,
  previewDialog: false,
  autoNotifications: [],
  loadingAutoNotifications: false,
  disableButton: false,
  search: '',
  orderBy: DEFAULT_ORDER_BY,
  orderDirection: DEFAULT_ORDER_DIRECTION,
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOADING_NOTIFICATION:
      return {
        ...state,
        loading: true,
      };
    case FETCH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        perPage: Number(action.payload.per_page),
        total: action.payload.total,
        loading: false,
      };
    case ADD_NOTIFICATION_SUCCESS:
      return {
        ...state,
        disableButton: false,
      };
    case ADD_NOTIFICATION_ERROR:
      return {
        ...state,
        disableButton: false,
        error: action.error,
      };
    case FETCH_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    case LOADING_AUTO_NOTIFICATION:
      return {
        ...state,
        loadingAutoNotifications: true,
      };
    case FETCH_AUTO_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        autoNotifications: action.payload,
        loadingAutoNotifications: false,
      };
    case FETCH_AUTO_NOTIFICATIONS_ERROR:
      return {
        ...state,
        loadingAutoNotifications: false,
        error: action.error,
        configDialog: false,
      };
    case DELETED_NOTIFICATION_SUCCESS:
      return {
        ...state,
        deleteDialog: false,
        data: state.data.filter((item) => item.id !== action.payload),
        total: state.total - 1,
        notificationToDelete: 0,
      };
    case DELETE_NOTIFICATION_ERROR:
      return {
        ...state,
        deleteDialog: false,
        loading: false,
      };
    case DELETE_DIALOG_NOTIFICATION:
      return {
        ...state,
        deleteDialog: action.payload,
      };
    case ADD_DIALOG_NOTIFICATION:
      return {
        ...state,
        addDialog: action.payload,
      };
    case LOADING_ADD:
      return {
        ...state,
        disableButton: true,
      };
    case CONFIG_DIALOG_NOTIFICATION:
      return {
        ...state,
        configDialog: action.payload,
      };
    case PREVIEW_DIALOG_NOTIFICATION:
      return {
        ...state,
        previewDialog: action.payload,
      };
    case LOADING_UPDATE:
      return { ...state, disableButton: true };
    case UPDATE_AUTO_NOTIFICATION_SUCCESS:
      return { ...state, disableButton: false };
    case UPDATE_AUTO_NOTIFICATION_ERROR:
      return {
        ...state,
        configDialog: false,
        disableButton: false,
      };
    default:
      return state;
  }
};

export default notificationReducer;

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { withI18n } from "react-i18next";

import {
	Button,
	CircularProgress,
	Grid,
	RadioGroup,
	FormLabel,
	FormControlLabel,
	Radio,
	Typography,
	TextField,
} from "@material-ui/core";
import CheckCircleOutlineOutlinedIcon from "@material-ui/icons/CheckCircleOutlineOutlined";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import { green } from "@material-ui/core/colors";

import {
	SECONDARY_COLOR,
	SMALL_SIZE,
	VARIANT_CONTAINED,
	VARIANT_OUTLINED,
	TYPE_STATUS_PROCESSED,
	customizeDate,
	ERROR_COLOR,
	TYPE_STATUS_WAITING,
} from "../../helpers";
import { useStyles } from "./styles";
import { DeleteForeverOutlined } from "@material-ui/icons";

/**
 * Component request form (claim or info)
 *
 * @component
 *
 * @example
 * return (
 *   <RequestForm />
 * )
 */
function RequestForm({
	t,
	textButton,
	infoRequest,
	handleButton,
	dateTitle = "informations.date",
	spinnerDisablebtn,
	disableButton,
	enableBtn,
}) {
	const [date, setDate] = useState(""); // to retrieve the creation date
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [phone, setPhone] = useState("");
	const [email, setEmail] = useState("");
	const [cin, setCin] = useState("");
	const [status, setStatus] = useState("");
	const [clientEnda, setClientEnda] = useState("");
	const [client, setClient] = useState(null);
	const [message, setMessage] = useState("");
	const [note, setNote] = useState(""); // to retrieve the note entered by the user (initial value empty string)
	const [dateUpdate, setDateUpdate] = useState(""); // to retrieve the update date
	const dispatch = useDispatch(); //returns a reference to the dispatch function from the Redux store
	const classes = useStyles();

	useEffect(() => {
		console.log(infoRequest)
		if (infoRequest) {
			setDate(customizeDate(infoRequest.created_at));
			setLastName(infoRequest.client_last_name);
			setFirstName(infoRequest.client_first_name);
			setEmail(infoRequest.client_email ? infoRequest.client_email : "");
			setCin(infoRequest.client_cin);
			setClientEnda(infoRequest.client_id);
			setStatus(infoRequest.status);
			setMessage(infoRequest.message);
			setPhone(infoRequest.client_phone);
			setClient(infoRequest.client);
			setNote(infoRequest.note);
			setDateUpdate(customizeDate(infoRequest.updated_at));
		}
		return () => {
			dispatch(enableBtn(true));
		};
	}, [infoRequest, dispatch, enableBtn]);

	const handleClickButton = () => {
		handleButton(status, note);
	};
	const handleChangeStatus = (e) => {
		setStatus(e.target.value);
		if (disableButton) dispatch(enableBtn(false));
	};
	const handleChangeNote = (e) => {
		setNote(e.target.value);
		if (disableButton) dispatch(enableBtn(false));
	};
	return (
		<div>
			<Grid container spacing={2}>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						value={date}
						variant={VARIANT_OUTLINED}
						label={t(dateTitle)}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>

				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						value={firstName}
						variant={VARIANT_OUTLINED}
						label={t("informations.lastName")}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						value={lastName}
						variant={VARIANT_OUTLINED}
						label={t("informations.firstName")}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						value={phone || ""}
						variant={VARIANT_OUTLINED}
						label={t("informations.phone")}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						value={email}
						variant={VARIANT_OUTLINED}
						label={t("informations.email")}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						value={cin}
						variant={VARIANT_OUTLINED}
						label={t("informations.cin")}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				{clientEnda && infoRequest.client && <Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						value={infoRequest.client.delegation.description}
						variant={VARIANT_OUTLINED}
						label={t('clients.inscription.delegation')}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>}
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						multiline
						rows={4}
						value={message || ""}
						variant={VARIANT_OUTLINED}
						label={t("informations.message")}
						disabled
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12} className={classes.endaClientStyle}>
					<Typography variant="subtitle1" component="h2">
						{t("informations.clientEnda")}
					</Typography>
					<div className={classes.iconEndaStyle}>
						{clientEnda ? (
							client ?
								<CheckCircleOutlineOutlinedIcon style={{ color: green[500] }} />
								: <DeleteForeverOutlined color={ERROR_COLOR} />
						) : (
							<CancelOutlinedIcon color={ERROR_COLOR} />
						)}
					</div>
				</Grid>

				{infoRequest.status === TYPE_STATUS_WAITING ? (
					<Grid item xs={12} sm={6} className={classes.gridGenderStyle}>
						<FormLabel className={classes.labelStyle}>
							{t("informations.status.message")}
						</FormLabel>
						<RadioGroup
							onChange={handleChangeStatus}
							value={status}
							className={classes.radioStyle}
						>
							<FormControlLabel
								value={TYPE_STATUS_WAITING}
								control={<Radio />}
								label={t("common.waiting")}
							/>
							<FormControlLabel
								value={TYPE_STATUS_PROCESSED}
								control={<Radio />}
								label={t("common.treaties")}
							/>
						</RadioGroup>
					</Grid>
				) : (
					<Grid item xs={12} sm={6}>
						<div className={classes.treatiesStyle}>
							<Typography variant="subtitle1" component="h2">
								{t("informations.status.message")} {t("common.treaties")}
							</Typography>
							<CheckCircleOutlineOutlinedIcon
								className={classes.iconEndaStyle}
								style={{ color: green[500] }}
							/>
						</div>
						<TextField
							fullWidth
							value={dateUpdate || ""}
							variant={VARIANT_OUTLINED}
							label={t("informations.date.update")}
							disabled
							InputLabelProps={{
								shrink: true,
							}}
						/>
					</Grid>
				)}

				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						multiline
						value={note || ""}
						onChange={handleChangeNote}
						rows={4}
						disabled={infoRequest.status === TYPE_STATUS_WAITING ? false : true}
						variant={VARIANT_OUTLINED}
						label={t("informations.note")}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
			</Grid>

			<Button
				className={classes.btnStyle}
				onClick={handleClickButton}
				disabled={disableButton}
				size={SMALL_SIZE}
				variant={VARIANT_CONTAINED}
				color={SECONDARY_COLOR}
			>
				{spinnerDisablebtn && (
					<CircularProgress size={15} color={SECONDARY_COLOR} />
				)}
				{t(textButton)}
			</Button>
		</div>
	);
}
const connectedRequestForm = withI18n()(RequestForm); //Higher-Order Component
export { connectedRequestForm as RequestForm };

import {
	ERROR_NETWORK,
	CHART_BACK_GROUND_COLOR,
	CHART_BORDER_COLOR,
	COLORS,
} from "./constantes";
import { PREFIX_ADMIN, BEGIN_AT, ENDS_AT } from "./endPoint";
import { methodsServices } from "../store/services";
import { menu } from "./adminMenu";
import { dashboardPanels } from "./dashboardPanels";

/**
 * Returns the first error from the ws response
 *
 * @param {any} e
 *
 * @returns {string}
 */
export const getFirstErrorFromResponse = (e) => {
	const defaultError = "common.unknow-error";
	let message = null;
	if (typeof e === "object" && e.message) {
		message = e;
	} else if (typeof e === "string") {
		message = e || defaultError;
	} else if (e && e && e.data) {
		let eData = e.data;
		message = eData.message;
		if (eData.errors) {
			const errorsKeys = Object.keys(eData.errors);
			if (errorsKeys.length) {
				if (typeof eData.errors[errorsKeys[0]][0] === "string") {
					message = eData.errors[errorsKeys[0]][0];
				}
			}
		}
	} else {
		message = ERROR_NETWORK;
	}

	return message || defaultError;
};

/**
 * Confirms if the connected user id an admin or not
 *
 * @returns {boolean}
 */
export const isAdmin = () => {
	return localStorage.getItem("role") === "1";
};

/**
 * Confirms if the connected user id an agent or not
 *
 * @returns {boolean}
 */
export const isAgent = () => {
	return localStorage.getItem("role") === "2";
};

/**
 * Confirms if there is a connected user or not
 *
 * @returns {boolean}
 */
export const isLoggedIn = () => {
	return localStorage.getItem("token") ? true : false;
};

/**
 * Validate the email format
 *
 * @param {string} value
 *
 * @returns {boolean}
 */
export const ValidateEmail = (value) => {
	if (new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/).test(value)) {
		return true;
	} else {
		return false;
	}
};

/**
 * Validate the URL format
 *
 * @param {string} str
 *
 * @returns {boolean}
 */
export const validURL = (str) => {
	var pattern = new RegExp(
		"^(https?:\\/\\/)?" + // protocol
			"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
			"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
			"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
			"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
			"(\\#[-a-z\\d_]*)?$",
		"i"
	); // fragment locator
	return !!pattern.test(str);
};

/**
 * Check is number
 * @param {string} value
 *
 * @returns {boolean}
 */
export const isNotNumber = (value) => {
	if (isNaN(value)) return true;
	else return false;
};

/**
 * Check length of value
 * @param {string} value
 *
 * @returns {boolean}
 */
export const CheckLengthNumber = (value, minLenght) => {
	if (value.length !== minLenght) return true;
	else return false;
};
/**
 * function to customize date
 *
 * @param {string} value
 */
export const customizeDate = (value) => {
	const options = {
		year: "numeric",
		month: "numeric",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
		hour12: false,
		timeZone: "Africa/Tunis",
	};
	const date = new Date(value * 1000);
	return new Intl.DateTimeFormat("GMT", options).format(date);
};

/**
 * Get the width and height of a file
 *
 * @param {*} file
 */
export const getWidthHeightOfImage = (file) => {
	return new Promise((resolve, reject) => {
		if (file) {
			var _URL = window.URL || window.webkitURL;

			let img = new Image();
			img.onload = function () {
				resolve({ width: this.width, height: this.height });
			};
			img.src = _URL.createObjectURL(file);
		} else {
			reject();
		}
	});
};
/**
 * function to check latitude value
 * @param {float} value
 */
export const checkLatitudeNumber = (value) => {
	let pattern = new RegExp(/^[-]?(([0-8]?[0-9])\.(\d+))|(90(\.0+)?)$/);
	if (pattern.test(value)) return true;
	else return false;
};

/**
 * function to check longitude value
 * @param {float} value
 */
export const checkLongitudeNumber = (value) => {
	let pattern = new RegExp(
		/^[-]?((((1[0-7][0-9])|([0-9]?[0-9]))\.(\d+))|180(\.0+)?)$/
	);
	if (pattern.test(value)) return true;
	else return false;
};

/**
 *
 * ENDA-149
 */
/**
 * Change the format of date
 * @param {string} date
 */
export const changeFormatDate = (date) => {
	date = date.split("-");
	return date[0] + "/" + date[1] + "/" + date[2];
};

/**
 * Data form of react-chartjs
 * @param {Array} labels
 * @param {Array} data
 */
export const chartData = (data) => {
	let values = [];
	let labels = [];
	data.forEach((element) => {
		values.push(element.count);
		labels.push(element.date);
	});

	return {
		labels: labels,
		datasets: [
			{
				backgroundColor: CHART_BACK_GROUND_COLOR,
				borderColor: CHART_BORDER_COLOR,
				data: values,
			},
		],
	};
};

/**
 * get a custom color for the charts
 * @param {*} index
 */
export const getChartColor = (index) => {
	let color = COLORS[index];
	return {
		color,
	};
};

/**
 * Filter the side menu and return only the accessed routes
 * @param {Array} sections
 */
export const getMenu = (sections) => {
	if (isAdmin()) {
		return menu;
	}
	const user = JSON.parse(localStorage.getItem("user"));
	const sectionsFromLocal = user && user.profile ? user.profile.sections : [];

	let finalSections = sections || sectionsFromLocal;

	return menu.filter((elem) => {
		return finalSections.some((section) => section.id === elem.id);
	});
};

/**
 * Verify if an admin can access a route
 * @param {int} id
 */
export const canAccessRoute = (id) => {
	if (isAdmin()) {
		return true;
	}
	const user = JSON.parse(localStorage.getItem("user"));
	const sections = user && user.profile ? user.profile.sections : [];

	return sections.some((section) => section.id === id);
};

/**
 * return the first allowed route
 */
export const getFirstRoute = () => {
	const user = JSON.parse(localStorage.getItem("user"));
	const sections = user && user.profile ? user.profile.sections : [];

	if (!sections[0]) {
		localStorage.removeItem("role");
		localStorage.removeItem("token");
		localStorage.removeItem("idUserConnected");
		localStorage.removeItem("user");
		return "/login";
	}

	return menu[sections[0].id - 1].route;
};

/**
 * return the list of accissible panels
 */
export const getAccessiblePanels = () => {
	if (isAdmin()) {
		return dashboardPanels;
	}

	const user = JSON.parse(localStorage.getItem("user"));
	const sectionsFromLocal = user && user.profile ? user.profile.sections : [];

	return dashboardPanels.filter((elem) => {
		return sectionsFromLocal.some((section) => section.id === elem.id);
	});
};

/**
 * return the list of accissible panels
 */
export const getSectionsString = (sections) => {
	let sectionsString = "";
	sections.forEach((section, index) => {
		sectionsString += "- " + section.name;
		if (index < sections.length - 1) {
			sectionsString += "\n";
		}
	});
	return sectionsString;
};

/**
 * Verify if the password is valid or not
 */
export const passwordIsValid = (password) => {
	if (
		new RegExp(
			/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/
		).test(password)
	) {
		return true;
	} else {
		return false;
	}
};

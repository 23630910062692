import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicTable, FormDialog, RequestForm } from '../components';
import {
  CLAIM_TABLE_HEADER,
  INFO_KEYS,
  KEYS_CREATED_AT,
  CLAIM_TABLE_TRI_BY,
  SET_TIME_OUT,
} from '../helpers/constantes';
import { fetchClaimRequest } from '../store/actions';
import {
  upadteClaimRequest,
  enableBtnClaimRequest,
  claimUpdateFormDialog,
} from '../store/actions/claimAction';

/**
 * Component Claims
 *
 * @component
 *
 * @example
 * return (
 *   <Claims/>
 * )
 */
function Claims({ t }) {
  const dispatch = useDispatch();
  const claimRequest = useSelector((state) => state.claim);
  const [data, setData] = useState({});
  const [waiting, setWaiting] = useState(true);
  const [processed, setProcessed] = useState(true);
  const [status, setStatus] = useState(null);
  const [search, setSearch] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [typingTimeout, setTypingTimeout] = useState(0);

  useEffect(() => {
    dispatch(
      fetchClaimRequest(
        claimRequest.currentPage,
        claimRequest.perPage,
        status,
        search
      )
    );
  }, []);

  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchClaimRequest(
        page,
        perPage,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchClaimRequest(
        page,
        perPage,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };

  //Function to upadate a in
  const handleUpdateClaimRequest = (statusOfRequest, note) => {
    dispatch(
      upadteClaimRequest(
        statusOfRequest,
        note,
        claimRequest.currentPage,
        claimRequest.perPage,
        data.id,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (info) => {
    setData(info);
    dispatch(claimUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(claimUpdateFormDialog(false));
  };
  //when the user select the Awating box
  const handleAwatingCheckBox = (e) => {
    let checked = e.target.checked;
    setWaiting(e.target.checked);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        handleStatusChange(checked, processed);
      }, SET_TIME_OUT)
    );
  };
  //when the user select the processed box
  const handleSecondCheckBox = (e) => {
    let checked = e.target.checked;
    setProcessed(e.target.checked);
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }
    setTypingTimeout(
      setTimeout(function () {
        handleStatusChange(waiting, checked);
      }, SET_TIME_OUT)
    );
  };
  const handleSearch = (message) => {
    setSearch(message);
    dispatch(
      fetchClaimRequest(
        claimRequest.currentPage,
        claimRequest.perPage,
        status,
        message,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //when the user change status
  const handleStatusChange = (wait, process) => {
    let status;
    //case waiting and processed checked
    if ((wait && process) || (!wait && !process)) {
      status = 0;
      setStatus(0);
    } else {
      //case waiting is checked
      if (wait) {
        status = 1;
        setStatus(1);
      } else {
        //case processed is checked
        status = 2;
        setStatus(2);
      }
    }

    //get the new data with new status
    dispatch(
      fetchClaimRequest(
        1,
        claimRequest.perPage,
        status,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('claims.date'):
        return KEYS_CREATED_AT;
      case t('informations.lastName'):
        return INFO_KEYS[1];
      default:
        return INFO_KEYS[2];
    }
  };
  /**
   *  get list of agent using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const HandleFetchClaimOrderBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchClaimRequest(
        claimRequest.currentPage,
        claimRequest.perPage,
        status,
        search,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={CLAIM_TABLE_HEADER}
        keys={INFO_KEYS}
        displayFilter={true}
        visibilityIcon={false}
        deleteIcon={false}
        displayHeaderComponent={true}
        tbBody={claimRequest.data}
        rowsPerPage={claimRequest.perPage}
        total={claimRequest.total}
        currentPage={claimRequest.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openUpdateFormDialog={openUpdateFormDialog}
        title={'claims.title'}
        displayAddOrApplyBtn={false}
        handleSearch={handleSearch}
        loading={claimRequest.loading}
        displaySearch={true}
        handleFirstCheckBox={handleAwatingCheckBox}
        handleSecondCheckBox={handleSecondCheckBox}
        checkedFirstCheckBox={waiting}
        checkedSecondtCheckBox={processed}
        tbTriBy={CLAIM_TABLE_TRI_BY}
        fetchDataBy={HandleFetchClaimOrderBy}
      />

      {/* Dialog To update  claimRequest */}
      <FormDialog
        openFormDialog={claimRequest.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        title={'agents.update'}
      >
        <RequestForm
          disableButton={claimRequest.disableButton}
          spinnerDisablebtn={claimRequest.spinnerDisablebtn}
          dateTitle={'claims.date'}
          textButton={'informations.btn.update'}
          infoRequest={data}
          handleButton={handleUpdateClaimRequest}
          enableBtn={enableBtnClaimRequest}
        />
      </FormDialog>
    </Fragment>
  );
}
const connectedClaimsPage = withI18n()(Claims); //Higher-Order Component
export { connectedClaimsPage as Claims };

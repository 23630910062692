import React, { Fragment, useState, useEffect } from "react";
import { withI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import {
	Grid,
	Button,
	CircularProgress,
	TextField,
	FormControl,
	InputLabel,
	FormHelperText,
	Select,
	MenuItem,
	InputAdornment,
	IconButton,
	OutlinedInput,
} from "@material-ui/core";
import {
	ValidateEmail,
	SECONDARY_COLOR,
	VARIANT_OUTLINED,
	VARIANT_CONTAINED,
	SMALL_SIZE,
	INPUT_REQUIRED,
	JUSTIFY_CENTER,
	passwordIsValid,
} from "../../helpers";
import { fetchProfiles, loadMoreProfiles } from "../../store/actions";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

/**
 * Component user form ( to create and update admin or agent)
 *
 * @component
 *
 * @example
 * return (
 *   <UserForm/>
 * )
 */
function UserForm({ t, passwordRequired, textButton, user, handleButton }) {
	const classes = useStyles();
	const [firstName, setfirstName] = useState(""); // to retrieve the firstname entered by the user (initial value empty string)
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [emailError, setEmailError] = useState("");
	const [password, setPassword] = useState("");
	const [rePassword, setRePassword] = useState("");
	const [firstNameError, setfirstNameError] = useState(""); // to retrieve firstNameError
	const [lastNameError, setLastNameError] = useState("");
	const [id, setId] = useState(null);
	const [passwordError, setPasswordError] = useState("");
	const [rePasswordError, setRePasswordError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);

	const dispatch = useDispatch(); //returns a reference to the dispatch function from the Redux store
	const users = useSelector((state) => state.users); //extract state.users from the Redux store

	const [tableOrderDirection, setTableOrderDirection] = useState("");
	const [tableOrderBy, setTableOrderBy] = useState("");

	const [pageProfiles, setPageProfiles] = useState(1);

	const [selectedProfile, setSelectedProfile] = useState(null); // to retrieve the profiles selected by the user (initial value empty array)
	const [profileError, setProfileError] = useState(""); // state to display the profiles error
	const profiles = useSelector((state) => state.profiles);

	const [displayUserRole, setDisplayUserRole] = useState(true);

	const MenuProps = {
		getContentAnchorEl: null,

		PaperProps: {
			style: {
				maxHeight: 200,
				width: 250,
			},
		},
	};

	useEffect(() => {
		//check if the user props is not empty
		let userConnected = localStorage.getItem("idUserConnected");
		if (user) {
			//change the default value by the values of user
			setfirstName(user.first_name);
			setLastName(user.last_name);
			setEmail(user.email);
			setId(user.id);
			setSelectedProfile(user.profile_id);
			if (user.id.toString() === userConnected) {
				setDisplayUserRole(false);
			}
		}
		dispatch(fetchProfiles(1, 10, tableOrderBy, tableOrderDirection));
	}, [user]);

	// update errors from the store
	useEffect(() => {
		if (users.errors) {
			showError(users.errors);
		}
	}, [users.errors]);

	// show each error with its form
	const showError = (error) => {
		if (error.includes("email")) {
			setEmailError(error);
		} else if (error.includes("password")) {
			setPasswordError(error);
		} else if (error.includes("first_name")) {
			setfirstNameError(error);
		} else if (error.includes("last_name")) {
			setLastNameError(error);
		} else if (error.includes("profile_id")) {
			setProfileError(error);
		}
	};
	//get the first name entered by user (Admin or agent)
	const handleFirstName = (e) => {
		setfirstName(e.target.value);
		setfirstNameError("");
	};
	//get the last name entered by user (Admin or agent)
	const handleLastName = (e) => {
		setLastName(e.target.value);
		setLastNameError("");
	};
	//get the email entered by user (Admin or agent)
	const handleEmail = (e) => {
		setEmailError("");
		setEmail(e.target.value);
	};
	//get the password entered by user (Admin or agent)
	const handlePassword = (e) => {
		setPassword(e.target.value);
		setPasswordError("");
		if (passwordError == "users.password.error") {
			setRePasswordError("");
		}
	};
	const handleRePassword = (e) => {
		setRePassword(e.target.value);
		setRePasswordError("");
		if (rePasswordError == "users.password.error") {
			setPasswordError("");
		}
	};
	//Function to validate the inputs
	const validationForm = () => {
		let isValidate = false;
		if (!firstName) {
			setfirstNameError("users.firstName.required.error");
			isValidate = true;
		}
		if (!lastName) {
			setLastNameError("users.lastName.required.error");
			isValidate = true;
		}
		if (!email) {
			setEmailError("users.email.required.error");
			isValidate = true;
		} else if (!ValidateEmail(email)) {
			setEmailError("common.email.validate");
			isValidate = true;
		}
		if (!selectedProfile && displayUserRole) {
			setProfileError("users.profile.required.error");
			isValidate = true;
		}
		//Check if the password Required or not ( creation: password required , update: password not required)
		if (passwordRequired || password || rePassword) {
			if (!password) {
				setPasswordError("users.password.required.error");
				isValidate = true;
			} else if (!passwordIsValid(password)) {
				setPasswordError("password.format");
				isValidate = true;
			}
			if (!rePassword) {
				setRePasswordError("users.password.confirm.required.error");
				isValidate = true;
			} else if (password !== rePassword) {
				setPasswordError("users.password.error");
				setRePasswordError("users.password.error");
				isValidate = true;
			}
		}
		return isValidate;
	};

	const handleClickButton = (e) => {
		if (!validationForm()) {
			handleButton(
				firstName,
				lastName,
				email,
				password,
				rePassword,
				selectedProfile,
				id
			);
		}
	};
	//when the user change the page
	const handleChangePage = (page, perPage) => {
		dispatch(fetchProfiles(page, perPage, tableOrderBy, tableOrderDirection));
	};

	//when the user change the page
	const handleChangeProfile = (event) => {
		setProfileError("");
		setSelectedProfile(event.target.value);
	};

	// load another page of profiles
	const loadMore = (event) => {
		if (event.target.scrollTop + 200 >= event.target.scrollHeight) {
			if (profiles.lastPage > pageProfiles) {
				dispatch(
					loadMoreProfiles(
						pageProfiles + 1,
						10,
						profiles.orderBy,
						profiles.orderDirection
					)
				);
				setPageProfiles(pageProfiles + 1);
			}
		}
	};

	// verify id a profile is not in the first page
	const profileInAnotherPage = () => {
		if (
			user &&
			user.profile &&
			profiles.data &&
			!profiles.data.some((el) => el.id === selectedProfile)
		) {
			return true;
		}
		return false;
	};

	// hide or show a password type input
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowRePassword = () => {
		setShowRePassword(!showRePassword);
	};
	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						onChange={handleFirstName}
						value={firstName}
						variant={VARIANT_OUTLINED}
						placeholder={t("users.placeholder.firstName")}
						label={t("users.firstName") + INPUT_REQUIRED}
						helperText={t(firstNameError)}
						error={firstNameError ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<TextField
						fullWidth
						onChange={handleLastName}
						value={lastName}
						variant={VARIANT_OUTLINED}
						helperText={t(lastNameError)}
						error={lastNameError ? true : false}
						placeholder={t("users.placeholder.lastName")}
						label={t("users.lastName") + INPUT_REQUIRED}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item sm={12}>
					<TextField
						fullWidth
						autoComplete="new-password"
						onChange={handleEmail}
						value={email}
						helperText={t(emailError)}
						error={emailError ? true : false}
						variant={VARIANT_OUTLINED}
						placeholder={t("users.placeholder.email")}
						label={t("users.email") + INPUT_REQUIRED}
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							autoComplete: "new-password",
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						autoComplete="new-password"
						error={passwordError ? true : false}
						variant={VARIANT_OUTLINED}
						fullWidth
					>
						<InputLabel htmlFor="new-password">
							{t("users.password") + INPUT_REQUIRED}
						</InputLabel>
						<OutlinedInput
							autoComplete="new-password"
							variant={VARIANT_OUTLINED}
							id="new-password"
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={handlePassword}
							placeholder={t("users.placeholder.password")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							inputProps={{
								autoComplete: "new-password",
								form: {
									autocomplete: "off",
								},
							}}
							labelWidth={110}
						/>
						<FormHelperText>{t(passwordError)}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={6}>
					<FormControl
						autoComplete="new-password-confirmation"
						error={rePasswordError ? true : false}
						variant={VARIANT_OUTLINED}
						fullWidth
					>
						<InputLabel htmlFor="new-password-confirmation">
							{t("users.rePassword") + INPUT_REQUIRED}
						</InputLabel>
						<OutlinedInput
							autoComplete="new-password-confirmation"
							variant={VARIANT_OUTLINED}
							id="new-password-confirmation"
							type={showRePassword ? "text" : "password"}
							value={rePassword}
							onChange={handleRePassword}
							placeholder={t("users.placeholder.rePassword")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowRePassword}
									>
										{showRePassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							inputProps={{
								autoComplete: "new-password-confirmation",
								form: {
									autocomplete: "off",
								},
							}}
							labelWidth={210}
						/>
						<FormHelperText>{t(rePasswordError)}</FormHelperText>
					</FormControl>
				</Grid>
				{displayUserRole && (
					<Grid item xs={12} sm={12}>
						<FormControl
							className={classes.formControl}
							error={profileError ? true : false}
						>
							<Fragment>
								<InputLabel>{t("users.profile") + INPUT_REQUIRED}</InputLabel>
								<Select
									value={selectedProfile || ""}
									onChange={handleChangeProfile}
									MenuProps={{ ...MenuProps, onScroll: loadMore }}
								>
									{profileInAnotherPage() && (
										<MenuItem
											value={selectedProfile}
											dense
											classes={{
												dense: classes.hide,
												selected: classes.hide,
											}}
										>
											{user.profile.name}
										</MenuItem>
									)}
									{profiles.data.map((profile, index) => {
										return (
											<MenuItem key={index} value={profile.id.toString()}>
												{profile.name}
											</MenuItem>
										);
									})}
									{profiles.loading && (
										<Grid container justify={JUSTIFY_CENTER}>
											<CircularProgress size={20} color={SECONDARY_COLOR} />
										</Grid>
									)}
								</Select>
							</Fragment>
							<FormHelperText>{t(profileError)}</FormHelperText>
						</FormControl>
					</Grid>
				)}
			</Grid>

			<Button
				onClick={handleClickButton}
				disabled={users.disableButton}
				size={SMALL_SIZE}
				variant={VARIANT_CONTAINED}
				color={SECONDARY_COLOR}
				className={classes.buttonStyle}
			>
				{users.disableButton && (
					<CircularProgress
						size={15}
						color={SECONDARY_COLOR}
						className={classes.circularProgress}
					/>
				)}
				{t(textButton)}
			</Button>
		</div>
	);
}
const connectedUserForm = withI18n()(UserForm); //Higher-Order Component
export { connectedUserForm as UserForm };

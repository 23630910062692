import {
  GET_INFO_PANEL_SUCCESS,
  GET_INFO_PANEL_FAILED,
  RESET_INFO_PANEL_LOADING_LAST_BTN,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  countByClient: null,
  loadingLastBtn: false,
  statistics: {},
  offline: {},
  online: {},
  countStatueProcessed: null,
  countStatueWaiting: null,
};

const infoPanelReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_INFO_PANEL_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        currentPage: action.payload.data.current_page,
        perPage: Number(action.payload.data.per_page),
        total: action.payload.data.total,
        countByClient: action.payload.countByClient,
        statistics: action.payload.statistics,
        countStatueProcessed: action.payload.countStatueProcessed,
        countStatueWaiting: action.payload.countStatueWaiting,
        offline: action.payload.countByTypeClient.offline,
        online: action.payload.countByTypeClient.online,
        loading: false,
      };
    case GET_INFO_PANEL_FAILED:
      return { ...state, loading: false };
    case RESET_INFO_PANEL_LOADING_LAST_BTN:
      return { ...state, loadingLastBtn: action.payload };
    default:
      return state;
  }
};
export default infoPanelReducer;

import jwt from "jwt-decode";
import { history } from "../helpers";
import { OPEN_SNACKBAR } from "../store/types";

/**
 * ws gaurd that logs out the user in case of token expiration
 *
 * @param {*} store
 */
export const checkTokenExpirationMiddleware = (store) => (next) => (action) => {
	const token = localStorage.getItem("token");
	if (token && jwt(token).exp < Date.now() / 1000) {
		localStorage.clear();
		history.push("/login");

		store.dispatch({
			type: OPEN_SNACKBAR,
			payload: "common.unauthorized",
			severity: "error",
		});
	}
	next(action);
};

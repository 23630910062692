import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  label: {
    fontWeight: 'bold',
  },
  inputNumber: {
    margin: '0 10px',
    width: '50px',
  },
  inputContainer: {
    paddingLeft: '20%',
    paddingTop: '10px',
  },
  icon: {
    width: '48px',
    height: '48px',
  },
  bloc: {
    padding: '10px 0',
  },
  image: {
    maxHeight: '150px',
    maxWidth: '100%',
  },
  content: {
    paddingLeft: '10px',
    paddingTop: '10px',
  },
  label: {
    color: '#e2126d',
    fontWeight: 'bold',
    fontSize: '15px',
  },
  notifictionContainer: {
    textAlign: 'center',
    paddingBottom: 18,
  },
  content: {
    padding: '15px',
  },
  btnStyle: {
    marginTop: 50,
    left: '98%',
    position: 'sticky',
  },
  error: {
    paddingLeft: '20%',
    color: 'red',
  },
  formControl: {
    width: '200px',
    maxWidth: '100%',
  },
  selectInput: {
    padding: '15px 0',
    display: 'flex',
    justifyContent: 'center',
  },
  textNumber: {
    display: ' inline-flex',
    alignItems: 'center',
  },
  subTitleContainer: {
    padding: '25px 0',
  },
  subTitle: {
    marginTop: '-15px',
    textAlign: 'center',
    color: '#91195a',
    fontWeight: 'bold',
  },
  subTitleSecondContainer: {
    padding: '50px 0 25px 0',
  },
  divider: {
    backgroundColor: '#fdb813',
  },
  textFieldContainer: {
    padding: '10px 0',
  },
  dateTimeContainer: {
    paddingTop: '30px',
  },
  imageContainer: {
    border: ' 1px solid',
    padding: ' 10px',
    display: 'flex',
    justifyContent: 'center',
  },
  deleteIconContainer: {
    alignItems: 'center',
    display: 'flex',
  },
  loadMoreBtn: {
    width: '100%',
    color: '#fdb813',
  },
  paper: {
    display: 'flex',
    padding: 'inherit',
  },
});

import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	Typography,
} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import CheckIcon from "@material-ui/icons/Check";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import React, { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import bannerDefault from "../../assets/img/banner-default.jpg";
import config from "../../config/config";
import {
	ERROR_COLOR,
	FONT_SIZE_LARGE,
	getWidthHeightOfImage,
	INPUT_REQUIRED,
	SECONDARY_COLOR,
	SLIDE_MAX_HEIGHT,
	SLIDE_MAX_WIDTH,
	SMALL_SIZE,
	TYPE_OF_IMAGES,
	validURL,
	VARIANT_CONTAINED,
	VARIANT_OUTLINED,
	VARIANT_SUBTITLE_TWO,
} from "../../helpers";
import { errorURL } from "../../store/actions/bannersAction";
import { useStyles } from "./styles";

/**
 * Component Slide form
 *
 * @component
 *
 * @example
 * return (
 *   <BannersForm/>
 * )
 */
function BannersForm({ t, textButton, banner, handleButton }) {
	const [title, setTitle] = useState(""); // to retrieve the title entered by the user (initial value empty string)
	const [subtitle, setSubtitle] = useState("");
	const [urlRedirection, setUrlRedirection] = useState("");
	const [image, setImage] = useState("");
	const [photo, setPhoto] = useState("");
	const [uploadImage, setUploadImage] = useState(false);
	const [titleError, setTitleError] = useState("");
	const [subtitleError, setSubtitleError] = useState("");
	const [preViewImage, setPreViewImage] = useState("");
	const [id, setId] = useState(null);
	const [imageError, setImageError] = useState("");
	const dispatch = useDispatch(); //returns a reference to the dispatch function from the Redux store
	const classes = useStyles();

	const banners = useSelector((state) => state.banners); //extract state.users from the Redux store

	useEffect(() => {
		//check if the banner props is not empty
		if (banner) {
			//change the default value by the values of banner
			setTitle(banner.title);
			setSubtitle(banner.sub_title);
			setUrlRedirection(banner.url);
			setPhoto(banner.image);
			setId(banner.id);
		}
	}, [banner]);

	//Function to validate the inputs
	const validationForm = () => {
		let isValidate = false;
		if (!title) {
			setTitleError("banners.title.slide.required.error");
			isValidate = true;
		}
		if (!subtitle) {
			setSubtitleError("banners.subtitle.required.error");
			isValidate = true;
		}
		if (!urlRedirection) {
			dispatch(errorURL("banners.url.redirection.required.error"));
			isValidate = true;
		}
		if (urlRedirection)
			if (!validURL(urlRedirection)) {
				dispatch(errorURL("banners.url.redirection.error"));
				isValidate = true;
			}
		if (!image && !photo) {
			setImageError("banners.images.required.error");
			isValidate = true;
		}

		return isValidate;
	};
	//get the title entered by user (Admin or agent)
	const handleTitle = (e) => {
		setTitleError("");
		setTitle(e.target.value);
	};
	//get the Subtitle entered by user (Admin or agent)
	const handleSubtitle = (e) => {
		setSubtitleError("");
		setSubtitle(e.target.value);
	};
	//get the UrlRedirection entered by user (Admin or agent)
	const handleUrlRedirection = (e) => {
		dispatch(errorURL(""));
		setUrlRedirection(e.target.value);
	};
	//get the image entered by user (Admin or agent)
	const _onChangeImage = (e) => {
		const file = e.target.files[0];
		if (file) {
			getWidthHeightOfImage(file).then((res) => {
				setImageError("");
				setPreViewImage("");
				setImage("");
				if (res.width > SLIDE_MAX_WIDTH || res.height > SLIDE_MAX_HEIGHT) {
					setImageError("banners.images.error");
					setUploadImage(false);
				} else {
					setImage(file);
					setUploadImage(true);
					setPreViewImage(URL.createObjectURL(file));
				}
			});
		}
	};

	const handleClickButton = (e) => {
		if (!validationForm())
			handleButton(title, subtitle, urlRedirection, image, id);
	};

	//Replace img src onerror  when the image broken
	const addDefaultSrc = (ev) => {
		ev.target.src = bannerDefault;
	};
	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						onChange={handleTitle}
						value={title}
						variant={VARIANT_OUTLINED}
						placeholder={t("banners.placeholder.title")}
						label={t("banners.title.slide") + INPUT_REQUIRED}
						helperText={t(titleError)}
						error={titleError ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						onChange={handleSubtitle}
						value={subtitle}
						variant={VARIANT_OUTLINED}
						placeholder={t("banners.placeholder.subtitle")}
						label={t("banners.subtitle") + INPUT_REQUIRED}
						helperText={t(subtitleError)}
						error={subtitleError ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item sm={12}>
					<TextField
						fullWidth
						onChange={handleUrlRedirection}
						value={urlRedirection}
						variant={VARIANT_OUTLINED}
						placeholder={t("banners.placeholder.subtitle")}
						label={t("banners.url.redirection") + INPUT_REQUIRED}
						helperText={t(banners.errorURL)}
						error={banners.errorURL ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item sm={12}>
					<div className={classes.gridDivStyle}>
						<div>
							<Typography variant="h6" component="h2">
								{t("banners.image") + INPUT_REQUIRED}
								{uploadImage && (
									<CheckIcon
										className={classes.checkIconStyle}
										color={SECONDARY_COLOR}
									/>
								)}
							</Typography>
							{imageError && (
								<Typography
									variant={VARIANT_SUBTITLE_TWO}
									component="h2"
									color={ERROR_COLOR}
								>
									{t(imageError)}
								</Typography>
							)}
						</div>
						<input
							onChange={(e) => _onChangeImage(e)}
							accept={TYPE_OF_IMAGES}
							style={{ display: "none" }}
							id="icon-button-file"
							type="file"
						/>
						<label htmlFor="icon-button-file">
							<IconButton component="span">
								<CloudUploadIcon
									fontSize={FONT_SIZE_LARGE}
									color={SECONDARY_COLOR}
								/>
							</IconButton>
						</label>
					</div>
				</Grid>
				{photo || preViewImage ? (
					preViewImage ? (
						<Grid item sm={12} className={classes.gridStyle}>
							<img height="200px" width="400px" src={preViewImage} alt="new" />
						</Grid>
					) : (
						<Grid item sm={12} className={classes.gridStyle}>
							<img
								height="200px"
								width="400px"
								src={`${config.imageUrl}${photo}`}
								alt="new"
								onError={addDefaultSrc}
							/>
						</Grid>
					)
				) : (
					<div></div>
				)}
			</Grid>

			<Button
				className={classes.btnStyle}
				onClick={handleClickButton}
				disabled={banners.disableButton}
				size={SMALL_SIZE}
				variant={VARIANT_CONTAINED}
				color={SECONDARY_COLOR}
			>
				{banners.disableButton && (
					<CircularProgress size={15} color={SECONDARY_COLOR} />
				)}
				{t(textButton)}
			</Button>
		</div>
	);
}
const connectedBannersForm = withI18n()(BannersForm); //Higher-Order Component
export { connectedBannersForm as BannersForm };

import {
  OPEN_SNACKBAR,
  GET_AGENCY_SUCCESS,
  GET_AGENCY_FAILED,
  AGENCIES_OPEN_FORM_DIALOG,
  AGENCIES_UPADTE_FORM_DIALOG,
  CHANGE_DELETE_DIALOG_AGENCIE,
  AGENCIE_FAILED,
  UPDATE_AGENCIE_SUCCESS,
  LOADING_AGENCIES,
  CREATE_AGENCIE_SUCCESS,
  CHANGE_DISABLED_DIALOG,
  DELETE_AGENCIE_SUCCESS,
  GET_AGENCIES,
  AGENCIE_UNIQUE_NAME,
} from '../types';
import {
  PREFIX_ADMIN,
  PAGE,
  ENDPOINT_PER_PAGE,
  SEARCH,
  ENDPOINT_AGENCY,
  ENDPOINT_IMPORT,
  ORDER_BY,
  ORDER_DIRECTION,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  getFirstErrorFromResponse,
  ERROR_NETWORK,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';

/**
 * Action Creator to get all agencies
 *
 * @param {int} page
 * @param {int} perPage
 * @param {string} orderBy
 * @param {string} orderDirection
 *
 * @returns function contains an action
 */
export function fetchAgencies(
  page,
  perPage,
  search = '',
  orderBy = '',
  orderDirection = 'desc'
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENCY}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${SEARCH}${search}`;
    if (orderBy)
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_AGENCY_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_AGENCY_FAILED });
      });
  };
}
/**
 * Action Creator to update agencie
 *
 * @param {String} name
 * @param {String} adress
 * @param {String} email
 * @param {string} phone
 * @param {int} codeP
 * @param {int} gouver
 * @param {int} longitude
 * @param {int} latitude
 * @param {int} page
 * @param {int} perPage
 * @param {string} search
 * @param {int} id
 * @param {string} orderBy
 * @param {string} orderDirection
 *
 * @returns function contains an action
 */
export function updateAgencie(
  name,
  phone,
  address,
  codeP,
  longitude,
  latitude,
  gouver,
  page,
  perPage,
  search,
  id,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    dispatch({ type: LOADING_AGENCIES });
    let formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('phone_num', phone);
    formData.append('zip_code', codeP);
    formData.append('governorate_id', gouver);
    formData.append('longitude', longitude);
    formData.append('latitude', latitude);
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENCY}/${id}`;
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchAgencies(page, perPage, search, orderBy, orderDirection));
        dispatch({ type: UPDATE_AGENCIE_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'clients.updated',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: AGENCIE_FAILED });
      });
  };
}
/**
 * Action Creator to create agencie
 *
 * @param {String} name
 * @param {String} adress
 * @param {String} email
 * @param {string} phone
 * @param {int} codeP
 * @param {int} gouver
 * @param {string} note
 * @param {int} longitude
 * @param {int} latitude
 * @param {int} page
 * @param {int} perPage
 * @param {string} search
 * @param {string} orderBy
 * @param {string} orderDirection
 *
 * @returns function contains an action
 */
export function createAgencie(
  name,
  phone,
  address,
  codeP,
  longitude,
  latitude,
  gouver,
  page,
  perPage,
  search,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    dispatch({ type: LOADING_AGENCIES });
    let formData = new FormData();
    formData.append('name', name);
    formData.append('address', address);
    formData.append('phone_num', phone);
    formData.append('zip_code', codeP);
    formData.append('governorate_id', gouver);
    formData.append('longitude', longitude);
    formData.append('latitude', latitude);
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENCY}`;
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchAgencies(page, perPage, search, orderBy, orderDirection));
        dispatch({ type: CREATE_AGENCIE_SUCCESS, payload: response.data.data });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'agencies.created',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: AGENCIE_FAILED });
      });
  };
}
/**
 * Action Creator to delete agent and call fetchAgents action to reload data
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 * @param {string} search
 * @param {string} orderBy
 * @param {string} orderDirection
 *
 * @returns function contains an action
 */
export const deleteAgencie = (
  id,
  currentPage,
  perPage,
  search,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENCY}/${id}`;
    methodsServices
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(
          fetchAgencies(currentPage, perPage, search, orderBy, orderDirection)
        );
        dispatch({ type: DELETE_AGENCIE_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'common.deleted',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      });
  };
};
/**
 * Action creator to upload csv file
 * @param {file} value
 * @param {int} perPage
 * @param {int} page
 * @param {string} search
 * @param {string} orderBy
 * @param {string} orderDirection
 *
 * @returns an action
 */
export const uploadAgencies = (
  value,
  page,
  perPage,
  search,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('file', value);
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_AGENCY}${ENDPOINT_IMPORT}`;

    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        const {
          insertedAgenciesCount,
          invalidLines,
          duplicateNameLines,
        } = response.data.data;
        if (insertedAgenciesCount !== 0) {
          dispatch({ type: GET_AGENCIES });
          dispatch(
            fetchAgencies(page, perPage, search, orderBy, orderDirection)
          );
          //CASE ALL THE LINES IN THE FILE IMPORTED
          if (invalidLines.length === 0 && duplicateNameLines.length === 0)
            dispatch({
              type: OPEN_SNACKBAR,
              payload: 'agents.uploaded',
              varaible: insertedAgenciesCount,
              severity: SERVERITY_SUCCESS,
            });
          else if (duplicateNameLines.length !== 0)
            dispatch({
              type: OPEN_SNACKBAR,
              payload: 'agencies.notAll.uploaded.duplicate',
              varaible: { lignes: duplicateNameLines },
              severity: SERVERITY_SUCCESS,
            });
          //CASE NOT ALL THE LINES IN THE FILE IMPORTED
          else
            dispatch({
              type: OPEN_SNACKBAR,
              payload: 'agents.notAll.uploaded',
              varaible: { lignes: invalidLines },
              severity: SERVERITY_SUCCESS,
            });
        } else if (invalidLines.length === 0)
          dispatch({
            type: OPEN_SNACKBAR,
            payload: 'agencies.allLine.duplicate',
            severity: SERVERITY_ERROR,
          });
        else if (duplicateNameLines.length !== 0)
          dispatch({
            type: OPEN_SNACKBAR,
            payload: 'agencies.error.uploaded.duplicate',
            varaible: { lignes: duplicateNameLines },
            severity: SERVERITY_ERROR,
          });
        else
          dispatch({
            type: OPEN_SNACKBAR,
            payload: 'agents.notUploaded',
            severity: SERVERITY_ERROR,
          });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
      });
  };
};
/**
 * Action Creator to open and close the Form dialog (agencie)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const agenciesFormDialog = (value) => {
  return {
    type: AGENCIES_OPEN_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the update dialog (agencie)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const agenciesUpdateFormDialog = (value) => {
  return {
    type: AGENCIES_UPADTE_FORM_DIALOG,
    payload: value,
  };
};

/**
 * Action Creator to open and close the delete dialog (agencie)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogAgencie = (value) => {
  return {
    type: CHANGE_DELETE_DIALOG_AGENCIE,
    payload: value,
  };
};
/**
 * Action Creator to handle the error of name (agencie)
 * @param {string} message
 */
export const agencieUniqueName = (message) => {
  return { type: AGENCIE_UNIQUE_NAME, payload: message };
};

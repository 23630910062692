import {
  GET_REST_PASSWORD_SUCCESS,
  GET_REST_PASSWORD_FAILED,
  RESET_PASSWORD_LOADING_LAST_BTN,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  countByClient: null,
  loadingLastBtn: false,
  statistics: {},
};

const resetPasswordReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_REST_PASSWORD_SUCCESS:
      return {
        ...state,
        data: action.payload.data.data,
        currentPage: action.payload.data.current_page,
        perPage: Number(action.payload.data.per_page),
        total: action.payload.data.total,
        countByClient: action.payload.countByClient,
        statistics: action.payload.statistics,
        loading: false,
      };
    case GET_REST_PASSWORD_FAILED:
      return { ...state, loading: false };
    case RESET_PASSWORD_LOADING_LAST_BTN:
      return { ...state, loadingLastBtn: action.payload };
    default:
      return state;
  }
};
export default resetPasswordReducer;

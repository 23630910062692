import React, { useState } from "react";
import { withI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./styles";
import {
	Grid,
	Button,
	CircularProgress,
	TextField,
	InputLabel,
	InputAdornment,
	IconButton,
	FormControl,
	FormHelperText,
	OutlinedInput,
} from "@material-ui/core";
import {
	SECONDARY_COLOR,
	VARIANT_OUTLINED,
	VARIANT_CONTAINED,
	SMALL_SIZE,
	INPUT_REQUIRED,
	passwordIsValid,
} from "../../helpers";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

/**
 * Component user form ( to create and update admin or agent)
 *
 * @component
 *
 * @example
 * return (
 *   <ResetPasswordForm/>
 * )
 */
function ResetPasswordForm({ t, textButton, handleButton }) {
	const classes = useStyles();

	const [password, setPassword] = useState("");
	const [rePassword, setRePassword] = useState("");
	const [currentPassword, setCurrentPassword] = useState("");
	const [passwordError, setPasswordError] = useState("");
	const [rePasswordError, setRePasswordError] = useState("");
	const [currentPasswordError, setCurrentPasswordError] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const [showRePassword, setShowRePassword] = useState(false);

	const users = useSelector((state) => state.users); //extract state.users from the Redux store

	//get the password entered by user (Admin or agent)
	const handlePassword = (e) => {
		setPassword(e.target.value);
		setPasswordError("");
		setRePasswordError("");
	};
	const handleRePassword = (e) => {
		setRePassword(e.target.value);
		setRePasswordError("");
		setPasswordError("");
	};
	const handleCurrentPassword = (e) => {
		setCurrentPassword(e.target.value);
		setCurrentPasswordError("");
	};

	//Function to validate the inputs
	const validationForm = () => {
		let isValidate = false;
		//Case is required
		if (!currentPassword) {
			setCurrentPasswordError("users.password.current.required.error");
		}
		if (!password) {
			setPasswordError("users.password.required.error");
			isValidate = true;
		} else if (!passwordIsValid(password)) {
			setPasswordError("password.format");
			isValidate = true;
		}
		if (!rePassword) {
			setRePasswordError("users.password.confirm.required.error");
			isValidate = true;
		} else if (password !== rePassword) {
			setPasswordError("users.password.error");
			setRePasswordError("users.password.error");
			isValidate = true;
		}

		return isValidate;
	};

	// handel click submit button
	const handleClickButton = (e) => {
		if (!validationForm()) {
			handleButton(currentPassword, password, rePassword);
		}
	};

	// hide or show a password type input
	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};
	const handleClickShowRePassword = () => {
		setShowRePassword(!showRePassword);
	};

	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<TextField
						autoComplete="new-password"
						fullWidth
						onChange={handleCurrentPassword}
						value={currentPassword}
						helperText={t(currentPasswordError)}
						type="password"
						error={currentPasswordError ? true : false}
						variant={VARIANT_OUTLINED}
						placeholder={t("users.placeholder.current.password")}
						label={t("users.current.password") + INPUT_REQUIRED}
						inputProps={{
							autoComplete: "new-password",
							form: {
								autoComplete: "off",
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<FormControl
						autoComplete="new-password"
						error={passwordError ? true : false}
						variant={VARIANT_OUTLINED}
						fullWidth
					>
						<InputLabel htmlFor="new-password">
							{t("users.password") + INPUT_REQUIRED}
						</InputLabel>
						<OutlinedInput
							autoComplete="new-password"
							variant={VARIANT_OUTLINED}
							id="new-password"
							type={showPassword ? "text" : "password"}
							value={password}
							onChange={handlePassword}
							placeholder={t("users.placeholder.password")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowPassword}
									>
										{showPassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							inputProps={{
								autoComplete: "new-password",
								form: {
									autocomplete: "off",
								},
							}}
							labelWidth={110}
						/>
						<FormHelperText>{t(passwordError)}</FormHelperText>
					</FormControl>
				</Grid>
				<Grid item xs={12} sm={12}>
					<FormControl
						autoComplete="new-password-confirmation"
						error={rePasswordError ? true : false}
						variant={VARIANT_OUTLINED}
						fullWidth
					>
						<InputLabel htmlFor="new-password-confirmation">
							{t("users.rePassword") + INPUT_REQUIRED}
						</InputLabel>
						<OutlinedInput
							autoComplete="new-password-confirmation"
							variant={VARIANT_OUTLINED}
							id="new-password-confirmation"
							type={showRePassword ? "text" : "password"}
							value={rePassword}
							onChange={handleRePassword}
							placeholder={t("users.placeholder.rePassword")}
							endAdornment={
								<InputAdornment position="end">
									<IconButton
										aria-label="toggle password visibility"
										onClick={handleClickShowRePassword}
									>
										{showRePassword ? <VisibilityOff /> : <Visibility />}
									</IconButton>
								</InputAdornment>
							}
							inputProps={{
								autoComplete: "new-password-confirmation",
								form: {
									autocomplete: "off",
								},
							}}
							labelWidth={210}
						/>
						<FormHelperText>{t(rePasswordError)}</FormHelperText>
					</FormControl>
				</Grid>
			</Grid>

			<Button
				onClick={handleClickButton}
				disabled={users.disableButton}
				size={SMALL_SIZE}
				variant={VARIANT_CONTAINED}
				color={SECONDARY_COLOR}
				className={classes.buttonStyle}
			>
				{users.disableButton && (
					<CircularProgress
						size={15}
						color={SECONDARY_COLOR}
						className={classes.circularProgress}
					/>
				)}
				{t(textButton)}
			</Button>
		</div>
	);
}
const connectedResetPasswordForm = withI18n()(ResetPasswordForm); //Higher-Order Component
export { connectedResetPasswordForm as ResetPasswordForm };

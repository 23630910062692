import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { withI18n } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
}));

/**
 * Component global picker
 *
 * @component
 *
 * @example
 * return (
 *   <PickerUi/>
 * )
 */
function PickersUi({
  t,
  label,
  selectedDate,
  handleDateChange,
  minDate = '',
  disabled = false,
  selectedDateError,
}) {
  const classes = useStyles();

  return (
    <form className={classes.container} noValidate>
      <TextField
        label={t(label)}
        value={selectedDate}
        helperText={t(selectedDateError)}
        error={selectedDateError ? true : false}
        onChange={handleDateChange}
        type="date"
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
          required: true,
        }}
        disabled={disabled}
        inputProps={{
          min: minDate ? minDate : '',
        }}
      />
    </form>
  );
}
const connectedPickersUi = withI18n()(PickersUi); //Higher-Order Component
export { connectedPickersUi as PickersUi };

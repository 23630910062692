import React from 'react';
import { withI18n } from 'react-i18next';

import { DialogTitle, IconButton } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';

import { useStyles } from './styles';
import { SECONDARY_COLOR, MAX_WIDTH_LARGE } from '../../helpers';

/**
 * Component global FormDialog
 *
 * @component
 *
 * @example
 * return (
 *   <FormDialog/>
 * )
 */
function FormDialog({
  t,
  openFormDialog,
  handleCloseFormDialog = false,
  title,
  children,
}) {
  const classes = useStyles();

  return (
    <div>
      <Dialog
        maxWidth={MAX_WIDTH_LARGE}
        open={openFormDialog}
        onClose={handleCloseFormDialog}
      >
        <DialogTitle>
          <span className={classes.dialogTitle}>{t(title)}</span>
          <IconButton
            className={classes.iconButtonStyle}
            onClick={handleCloseFormDialog}
          >
            <CancelIcon color={SECONDARY_COLOR} />
          </IconButton>
        </DialogTitle>
        <DialogContent className={classes.dialogContentStyle}>
          {children}
        </DialogContent>
      </Dialog>
    </div>
  );
}
const connectedFormDialog = withI18n()(FormDialog); //Higher-Order Component
export { connectedFormDialog as FormDialog };

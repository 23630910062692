import React, { Fragment, useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import {
	DynamicTable,
	FormDialog,
	ProfilesForm,
	DeleteDialog,
} from "../components";
import {
	PROFILES_KEYS,
	PROFILES_TABLE_HEADER,
	PROFILES_TABLE_TRI_BY,
} from "../helpers/constantes";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchProfiles,
	profilesFormDialog,
	createProfile,
	profilesUpdateFormDialog,
	changeDeleteDialogProfile,
	updateProfile,
	deleteProfile,
	resetSections,
} from "../store/actions";

/**
 * Component Profiles
 *
 * @component
 *
 * @example
 * return (
 *   <Profiles/>
 * )
 */
function Profiles({ t }) {
	const dispatch = useDispatch();
	const profiles = useSelector((state) => state.profiles);
	
	const [data, setData] = useState({});
	const [profileId, setIdProfile] = useState(null);
	const [tableOrderDirection, setTableOrderDirection] = useState("");
	const [tableOrderBy, setTableOrderBy] = useState("created_at");
	useEffect(() => {
		dispatch(
			fetchProfiles(
				profiles.currentPage,
				profiles.perPage,
				tableOrderBy,
				tableOrderDirection
			)
        );
	}, []);
	//When the user change Row per page
	const changeRowsPerPage = (page, perPage) => {
		dispatch(fetchProfiles(page, perPage, tableOrderBy, tableOrderDirection));
	};
	//when the user change the page
	const handleChangePage = (page, perPage) => {
		dispatch(fetchProfiles(page, perPage, tableOrderBy, tableOrderDirection));
	};
	//Close the Form dialog  (add new profile)
	const handleCloseFormDialog = () => {
		dispatch(profilesFormDialog(false));
	};
	//Open the Form dialog(add new profile)
	const openFormDialog = () => {
		dispatch(profilesFormDialog(true));
	};
	//Function to create a new profile
	const handleCreateProfile = (name,sections) => {
		dispatch(
			createProfile(
				name,
				sections,
				profiles.currentPage,
				profiles.perPage,
				tableOrderBy,
				tableOrderDirection
			)
		);
	};
	//Function to upadate a profile
	const handleUpdateProfile = (name,sections) => {
		dispatch(
			updateProfile(
				name,
				sections,
				data.id,
				profiles.currentPage,
				profiles.perPage,
				tableOrderBy,
				tableOrderDirection
			)
		);
	};
	//open the upadate form dialog
	const openUpdateFormDialog = (profile) => {
		setData(profile);
		dispatch(profilesUpdateFormDialog(true));
		dispatch(resetSections());
	};
	//Close the upadate form dialog
	const handleCloseUpdateFormDialog = () => {
		dispatch(profilesUpdateFormDialog(false));
		dispatch(resetSections());
	};
	//Open the delete dialog
	const openDeleteDialog = (id) => {
		setIdProfile(id);
		dispatch(changeDeleteDialogProfile(true));
	};
	//Close the delete dialog
	const handleCloseDeleteDialog = () => {
		dispatch(changeDeleteDialogProfile(false));
	};
	//Action to delete profile
	const handleDeleteProfile = () => {
		dispatch(
			deleteProfile(
				profileId,
				profiles.currentPage,
				profiles.perPage,
				tableOrderBy,
				tableOrderDirection
			)
		);
	};
	
	/**
	 * get the name of column related to orderBy
	 * @param {string} orderBy
	 *
	 */
	const getColumnName = (orderBy) => {
		return PROFILES_KEYS[0];
	};

	/**
	 *  get list of profiles using orderBy and orderDirection
	 * @param {string} orderBy
	 * @param {string} orderDirection
	 */
	const handleFetchProfilesBy = (orderBy, orderDirection) => {
		setTableOrderBy(getColumnName(orderBy));
		setTableOrderDirection(orderDirection);

		dispatch(
			fetchProfiles(
				profiles.currentPage,
				profiles.perPage,
				getColumnName(orderBy),
				orderDirection
			)
		);
	};
	return (
		<Fragment>
			<DynamicTable
				tbHeader={PROFILES_TABLE_HEADER}
				keys={PROFILES_KEYS}
				visibilityIcon={false}
				displayHeaderComponent={true}
				tbBody={profiles.data}
				rowsPerPage={profiles.perPage}
				total={profiles.total}
				currentPage={profiles.currentPage}
				displayAddOrApplyBtn={true}
				changeRowsPerPage={changeRowsPerPage}
				changePage={handleChangePage}
				openFormDialog={openFormDialog}
				openUpdateFormDialog={openUpdateFormDialog}
				openDeleteDialog={openDeleteDialog}
				textBtnAddOrApply="profiles.addNew"
				title={"profiles.title"}
				loading={profiles.loading}
				tbTriBy={PROFILES_TABLE_TRI_BY}
				fetchDataBy={handleFetchProfilesBy}
			/>
			{/* Dialog To create new profile */}
			<FormDialog
				openFormDialog={profiles.openDialogForm}
				handleCloseFormDialog={handleCloseFormDialog}
				buttonTitle={"profiles.addNew"}
				title={"profiles.addNew"}
			>
				<ProfilesForm
					passwordRequired={true}
					textButton={"profiles.create"}
					handleButton={handleCreateProfile}
				/>
			</FormDialog>
			{/* Dialog To update profile */}
			<FormDialog
				openFormDialog={profiles.openUpdateDialogForm}
				handleCloseFormDialog={handleCloseUpdateFormDialog}
				buttonTitle={"profiles.text.button.update"}
				title={"profiles.update"}
			>
				<ProfilesForm
					textButton={"profiles.text.button.update"}
					profile={data}
					handleButton={handleUpdateProfile}
				/>
			</FormDialog>
			{/* Dialog To delete profile */}
			<DeleteDialog
				dialogTitle="common.delete"
				dialogContent="profiles.delete"
				open={profiles.openDeleteDialog}
				handleClose={handleCloseDeleteDialog}
				handleClickDelete={handleDeleteProfile}
			/>
		</Fragment>
	);
}
const connectedProfilesPage = withI18n()(Profiles); //Higher-Order Component
export { connectedProfilesPage as Profiles };

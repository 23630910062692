import { Grid, Typography } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import React from 'react';
import { withI18n } from 'react-i18next';
import {
  customizeDate,
  ERROR_COLOR,
  FEMALE,
  MALE,
  NO,
  YES,
} from '../../helpers';
import { useStyles } from './styles';
/**
 * Component global CreditRequestPreview
 *
 * @component
 *
 * @example
 * return (
 *   <CreditRequestPreview/>
 * )
 */
function CreditRequestPreview({ t, creditRequest }) {
  const classes = useStyles();
  const getTheDescriptionOfAcquaintances = (data) => {
    let arrayOfDescriptions = [];
    data.forEach((element) => {
      arrayOfDescriptions.push(element.description);
    });
    return arrayOfDescriptions.join(', ');
  };

  return (
    <div>
      <Grid container justify="center" className={classes.notifictionContainer}>
        <Grid container className={classes.bloc} spacing={2}>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.date')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {customizeDate(creditRequest.created_at)}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.status')}
            </Typography>
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.status}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.lastname')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_last_name}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.firstname')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_first_name}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.gender')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_gender === 1 ? MALE : FEMALE}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.brith')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_birth_date}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.profession')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.profession.description}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.client')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client ? (
              <CheckCircleOutlineOutlinedIcon style={{ color: green[600] }} />
            ) : (
              <CancelOutlinedIcon color={ERROR_COLOR} />
            )}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.phone')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_phone_num}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.email')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_email ? creditRequest.client_email : '--'}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.cin')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_cin}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.governorate')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.governorate.description}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.delegation')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.delegation.description}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.homeAddress')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.client_address}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.sector')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.sector.description}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.agency')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.enda_agency.name}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.product')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.product_name ? creditRequest.product_name : '--'}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.need')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.need.description}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.projectAddress')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.project_address}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.amount')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {`${creditRequest.amount} ${t('credit.requests.tnd')}`}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.esteemRreimbursement')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.esteem_reimbursement_yearly}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.have.credit')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.have_credit === 1 ? YES : NO}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.credit.in.progress')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.credit_in_progress === 1 ? YES : NO}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.project')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.project.description}
          </Grid>

          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.acquaintances')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {getTheDescriptionOfAcquaintances(creditRequest.acquaintances)}
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            <Typography className={classes.label}>
              {t('credit.requests.desciption')}
            </Typography>
          </Grid>
          <Grid item xs={12} md={3} sm={6}>
            {creditRequest.description}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
const connectedCreditRequestPreview = withI18n()(CreditRequestPreview); //Higher-Order Component
export { connectedCreditRequestPreview as CreditRequestPreview };

import { AdminSidebar } from "../components";
import React, { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { withI18n } from "react-i18next";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "../assets/sass/common.scss";
import clsx from "clsx";
import { styles } from "./style";

/**
 * Component layout for admin
 *
 * @component
 *
 * @example
 * return (
 *   <AdminLayout/>
 * )
 */
class AdminLayout extends Component {
	constructor(props) {
		super(props);
		this.state = {
			children: props.children,
			route: props.route,
			name: props.name,
			displayArrowBack: props.displayArrowBack,
		};
	}
	render() {
		const { classes, drawer } = this.props;
		return (
			<div
				className={clsx(classes.layoutContainer, {
					[classes.layoutContainerShift]: drawer,
				})}
			>
				<Container style={{ maxWidth: "100%" }}>
					<Grid container>
						<Grid
							className={"SideMenuContainer"}
							style={{ paddingLeft: 0, paddingRight: 0 }}
						>
							<AdminSidebar
								displayArrowBack={this.state.displayArrowBack}
								route={this.state.route}
								name={this.state.name}
							/>
						</Grid>
						<Grid item className={"main-container"}>
							<div className="content">{this.state.children}</div>
						</Grid>
					</Grid>
				</Container>
			</div>
		);
	}
}
AdminLayout.propTypes = {
	classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
	const { drawer } = state.ui;
	return {
		state,
		drawer,
	};
}
const connectedAdminLayout = withRouter(
	connect(mapStateToProps, null, null, {
		pure: false,
	})(withI18n()(withStyles(styles)(AdminLayout)))
);
export { connectedAdminLayout as AdminLayout };

import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isLoggedIn } from "../helpers";

/**
 * Route gaurd for public users
 *
 * @param {*} param0
 */
export const GuestRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			isLoggedIn() ? <Redirect to="/" /> : <Component {...props} />
		}
	/>
);

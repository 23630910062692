import {
  Button,
  CircularProgress,
  Grid,
  Input,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  SECONDARY_COLOR,
  SMALL_SIZE,
  VARIANT_CONTAINED,
  JUSTIFY_CENTER,
} from '../../helpers';
import { useStyles } from './styles';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';


/**
 * Component global AutoNotificationForm
 *
 * @component
 *
 * @example
 * return (
 *   <AutoNotificationForm/>
 * )
 */
function AutoNotificationForm({
  t,
  loading,
  textButton,
  handleButton,
  autoNotifications,
}) {
  const [deadlineBefore, setDeadlineBefore] = useState(0);
  const [deadlineAfter, setDeadlineAfter] = useState(0);
  const [cardBefore, setCardBefore] = useState(0);
  const [cardAfter, setCardAfter] = useState(0);

  const [deadlineBeforeError, setDeadlineBeforeError] = useState('');
  const [deadlineAfterError, setDeadlineAfterError] = useState('');
  const [cardBeforeError, setCardBeforeError] = useState('');
  const [cardAfterError, setCardAfterError] = useState('');

  const notifications = useSelector((state) => state.notifications);

  useEffect(() => {
    //check if the product props is not empty
    if (autoNotifications && autoNotifications.length >= 2) {
      //change the default value by the values of product
      setDeadlineBefore(autoNotifications[0].before_days);
      setDeadlineAfter(autoNotifications[0].after_days);
      setCardBefore(autoNotifications[1].before_days);
      setCardAfter(autoNotifications[1].after_days);
    }
  }, [autoNotifications]);

  const ui = useSelector((state) => state.ui);
  const classes = useStyles();

  const handelDeadlineBefore = (e) => {
    setDeadlineBeforeError('');
    setDeadlineBefore(e.target.value);
  };

  const handelDeadlineAfter = (e) => {
    setDeadlineAfterError('');
    setDeadlineAfter(e.target.value);
  };

  const handelCardBefore = (e) => {
    setCardBeforeError('');
    setCardBefore(e.target.value);
  };

  const handelCardAfter = (e) => {
    setCardAfterError('');
    setCardAfter(e.target.value);
  };

  //Function to validate the inputs
  const validationForm = () => {
    let isValidate = true;
    if (deadlineBefore <= 0) {
      setDeadlineBeforeError('notifications.min.error');
      isValidate = false;
    }
    if (deadlineAfter <= 0) {
      setDeadlineAfterError('notifications.min.error');
      isValidate = false;
    }
    if (cardBefore <= 0) {
      setCardBeforeError('notifications.min.error');
      isValidate = false;
    }
    if (cardAfter <= 0) {
      setCardAfterError('notifications.min.error');
      isValidate = false;
    }
    return isValidate;
  };

  const handleClickButton = () => {
    if (validationForm()) {
      handleButton(deadlineBefore, deadlineAfter, cardBefore, cardAfter);
    }
  };

  return (
    <div>
      {loading ? (
        <Grid container justify={JUSTIFY_CENTER}>
          <CircularProgress color={SECONDARY_COLOR} />
        </Grid>
      ) : (
        <div>
          <Grid>
            <Grid item xs={12} sm={12} className={classes.content}>
              <Grid item xs={12} sm={12} style={{display:'flex',alignItems:'center'}}>
                <Typography className={classes.label}>
                  {t('notifications.payment.before')}
                </Typography>
                <Tooltip interactive title={t('notifications.payment.descativate')} style={{marginLeft:10}}>
                    <HelpOutlineRoundedIcon />
                  </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.inputContainer}>
                {t('common.before')}
                <Input
                  type="number"
                  className={`${classes.inputNumber} without-padding text-center`}
                  value={deadlineBefore}
                  onChange={handelDeadlineBefore}
                  error={deadlineBeforeError ? true : false}
                />
                {t('common.days')}
           

              </Grid>
              {deadlineBeforeError && (
                <span className={classes.error}>{t(deadlineBeforeError)}</span>
              )}
            </Grid>
            <Grid item xs={12} sm={12} className={classes.content}>
            <Grid item xs={12} sm={12} style={{display:'flex',alignItems:'center'}}>
                <Typography className={classes.label}>
                  {t('notifications.payment.after')}
                </Typography>
                 <Tooltip interactive title={t('notifications.payment.descativate')} style={{marginLeft:10}}>
                    <HelpOutlineRoundedIcon />
                  </Tooltip>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.inputContainer}>
                {t('common.after')}
                <Input
                  type="number"
                  className={`${classes.inputNumber} without-padding text-center`}
                  value={deadlineAfter}
                  onChange={handelDeadlineAfter}
                  error={deadlineAfterError ? true : false}
                />
                {t('common.days')}
              </Grid>
              {deadlineAfterError && (
                <span className={classes.error}>{t(deadlineAfterError)}</span>
              )}
            </Grid>
            <Grid item xs={12} sm={12} className={classes.content}>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.label}>
                  {t('notifications.card.before')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.inputContainer}>
                {t('common.before')}
                <Input
                  type="number"
                  className={`${classes.inputNumber} without-padding text-center`}
                  value={cardBefore}
                  onChange={handelCardBefore}
                  error={cardBeforeError ? true : false}
                />
                {t('common.days')}
              </Grid>
              {cardBeforeError && (
                <span className={classes.error}>{t(cardBeforeError)}</span>
              )}
            </Grid>
            <Grid item xs={12} sm={12} className={classes.content}>
              <Grid item xs={12} sm={12}>
                <Typography className={classes.label}>
                  {t('notifications.card.after')}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} className={classes.inputContainer}>
                {t('common.after')}
                <Input
                  type="number"
                  className={`${classes.inputNumber} without-padding text-center`}
                  value={cardAfter}
                  onChange={handelCardAfter}
                  error={cardAfterError ? true : false}
                />
                {t('common.days')}
              </Grid>
              {cardAfterError && (
                <span className={classes.error}>{t(cardAfterError)}</span>
              )}
            </Grid>
          </Grid>
          <Button
            className={classes.btnStyle}
            onClick={handleClickButton}
            disabled={notifications.disableButton}
            size={SMALL_SIZE}
            variant={VARIANT_CONTAINED}
            color={SECONDARY_COLOR}
          >
            {notifications.disableButton && (
              <CircularProgress size={15} color={SECONDARY_COLOR} />
            )}
            {t(textButton)}
          </Button>
        </div>
      )}
    </div>
  );
}
const connectedAutoNotificationForm = withI18n()(AutoNotificationForm); //Higher-Order Component
export { connectedAutoNotificationForm as AutoNotificationForm };

import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
	gridStyle: {
		display: "flex",
		flexDirection: "row",
	},
	labelStyle: { marginTop: 10, marginRight: 25 },
	buttonStyle: {
		marginTop: 50,
		left: "98%",
		position: "sticky",
	},
	circularProgress: {
		marginRight: 15,
	},
	formControl: {
		width: "100%",
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	menuPaper: {
		maxHeight: 200,
	},
	loadMoreBtn: {
		width: "100%",
		color: "#fdb813",
	},
	hide: {
		display: "none",
	},
}));

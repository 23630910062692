import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  iconButtonStyle: {
    position: 'sticky',
    left: '98%',
  },
  dialogContentStyle: { marginBottom: 15 },
  dialogTitle: {
    fontWeight: 'bold',
    color: '#c06',
    fontSize: '26px',
  },
});

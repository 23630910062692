import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Grid,
  Box,
  Button,
  TextField,
  Typography,
  CssBaseline,
  FormControlLabel,
  CircularProgress,
  Container,
  Checkbox,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { authActions } from '../../store/actions';
import { withRouter } from 'react-router-dom';
import { withI18n } from 'react-i18next';
import '../../assets/sass/login.scss';
import logo from '../../assets/img/logo-trans.png';
import { Link } from 'react-router-dom';
import { CLEAR_ERRORS } from '../../store/types';
import { styles } from './style';
import '../../assets/sass/login.scss';
import {
  SECONDARY_COLOR,
  VARIANT_CONTAINED,
  VARIANT_OUTLINED,
  VARIANT_SUBTITLE_ONE,
  ERROR_COLOR,
} from '../../helpers';

/**
 * Component login
 *
 * @component
 *
 * @example
 * return (
 *   <Login />
 * )
 */
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      rememberMe: false,
      loading: props.loading,
      emailError: '',
      passwordError: '',
    };
    const { dispatch } = this.props;
    dispatch({ type: CLEAR_ERRORS });
  }
  handleChange = (prop) => (event) => {
    const { dispatch } = this.props;
    dispatch({ type: CLEAR_ERRORS });
    this.setState({
      passwordError: '',
      emailError: '',
      rememberMe: false,
      [prop]: event.target.value,
    });
  };
  handleRemeberMeChack = (prop) => (event) => {
    const { dispatch } = this.props;
    dispatch({ type: CLEAR_ERRORS });
    this.setState(({ rememberMe }) => ({
      rememberMe: !rememberMe,
    }));
  };
  login = (event) => {
    const { email, password, rememberMe } = this.state;
    const { dispatch } = this.props;
    if (!email) {
      this.setState({
        emailError: 'login.email.required.error',
      });
    }
    if (!password) {
      this.setState({
        passwordError: 'login.password.required.error',
      });
    }
    if (email && password) {
      dispatch(authActions.login(email, password, rememberMe));
    }
  };

  render() {
    const { classes, t, loading, errors } = this.props;
    return (
      <div className="login">
        <Box>
          <Box px={5} pt={5} pb={3} className={classes.loginButtonContainer}>
            <img src={logo} alt="My Logo" />
          </Box>
          <Container component="main" maxWidth="md">
            <CssBaseline />
            <Grid
              container
              alignContent="center"
              alignItems="center"
              justify="center"
            >
              <Grid item md={8} xs={12}>
                <Paper>
                  <Box textAlign="center" p={5}>
                    <Typography variant="h4">
                      <Box fontWeight={600} letterSpacing={3}>
                        {t('login.title')}
                      </Box>
                    </Typography>
                  </Box>
                  <Box
                    textAlign="center"
                    className={classes.loginFormContainer}
                  >
                    <TextField
                      variant={VARIANT_OUTLINED}
                      margin="none"
                      value={this.state.email}
                      fullWidth
                      id="email"
                      label={t('login.email') + ' *'}
                      name="email"
                      type="email"
                      onChange={this.handleChange('email')}
                      helperText={t(this.state.emailError)}
                      error={this.state.emailError ? true : false}
                    />
                    <TextField
                      variant={VARIANT_OUTLINED}
                      margin="normal"
                      value={this.state.password}
                      fullWidth
                      name="password"
                      label={t('login.password') + ' *'}
                      type="password"
                      onChange={this.handleChange('password')}
                      helperText={t(this.state.passwordError)}
                      error={this.state.passwordError ? true : false}
                    />
                    <Grid
                      container
                      direction="column"
                      className={classes.resetPaswordContainer}
                    >
                      <Grid item sm={6} md={6}>
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="primary"
                              value={this.state.rememberMe}
                              onChange={this.handleRemeberMeChack()}
                            />
                          }
                          label={t('login.remeber.me')}
                        />
                      </Grid>
                      <Grid item sm={6} md={6} className="forgot-password-link">
                        <Link to="/forgot-password">
                          {t('login.forgot.email')}
                        </Link>
                      </Grid>
                    </Grid>
                    {errors && (
                      <Typography
                        variant={VARIANT_SUBTITLE_ONE}
                        color={ERROR_COLOR}
                        gutterBottom
                        style={{
                          whiteSpace: 'pre-line',
                        }}
                      >
                        {t(errors)}
                      </Typography>
                    )}
                    <Button
                      disabled={loading}
                      type="submit"
                      className="login-btn"
                      variant={VARIANT_CONTAINED}
                      onClick={(event) => {
                        this.login();
                      }}
                    >
                      {t('login.connect')}
                      {loading && (
                        <CircularProgress size={20} color={SECONDARY_COLOR} />
                      )}
                    </Button>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </div>
    );
  }
}

Login.propTypes = {
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  const { loggingIn } = state.authentication;
  const { loading, errors } = state.ui;
  return {
    loggingIn,
    loading,
    errors,
  };
};

const connectedLoginPage = withRouter(
  connect(mapStateToProps, null, null, {
    pure: false,
  })(withI18n()(withStyles(styles)(Login)))
);

export { connectedLoginPage as Login };

import { TableHead, TableSortLabel } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React, { useState } from 'react';
import { withI18n } from 'react-i18next';
import {
  ORDER_DIRECTION_ASC,
  ORDER_DIRECTION_DESC,
  SET_TIME_OUT,
  ALIGN_CENTER,
} from '../../helpers';
import { useStyles } from './styles';

/**
 * @component
 *
 * @example
 * return (
 *   <HearderDynamicTable/>
 * )
 */
function HearderDynamicTable({
  t,
  tbHeader,
  orderBy = '',
  order = ORDER_DIRECTION_DESC,
  tbTriBy = [],
  fetchDataBy,
}) {
  const classes = useStyles();
  const [orderDirection, setOrederDirection] = useState(order);
  const [orderByName, setOrderByName] = useState(orderBy);
  const [typingTimeout, setTypingTimeout] = useState(0);

  /**
   * To sorting data
   * @param {string} property
   */
  const createSortHandler = (property) => (event) => {
    let orderByDirection = ORDER_DIRECTION_DESC;
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    setOrderByName(property);
    if (orderByName && orderByName === property) {
      setOrederDirection(
        orderDirection === ORDER_DIRECTION_DESC
          ? ORDER_DIRECTION_ASC
          : ORDER_DIRECTION_DESC
      );
      orderByDirection =
        orderDirection === ORDER_DIRECTION_DESC
          ? ORDER_DIRECTION_ASC
          : ORDER_DIRECTION_DESC;
    } else setOrederDirection(ORDER_DIRECTION_DESC);
    setTypingTimeout(
      setTimeout(function () {
        fetchDataBy(property, orderByDirection);
      }, SET_TIME_OUT)
    );
  };

  return (
    <TableHead>
      <TableRow>
        {/* display table header */}
        {tbHeader.map((row, index) =>
          t(row) === t('common.client') ||
          t(row) === t('common.Notclient') ||
          t(row) === t('informations.client.enda') ||
          t(row) === t('users.admin') ||
          t(row) === t('common.processedAt') ||
          t(row) === t('common.status') ? (
            <TableCell align={ALIGN_CENTER} key={index}>
              {tbTriBy.length === 0 ? (
                t(row)
              ) : tbTriBy.includes(row) ? (
                <TableSortLabel
                  key={index}
                  active={orderByName === t(row)}
                  direction={orderDirection}
                  onClick={createSortHandler(t(row))}
                >
                  {t(row)}
                </TableSortLabel>
              ) : (
                t(row)
              )}
            </TableCell>
          ) : (
            <TableCell key={index}>
              {tbTriBy.length === 0 ? (
                t(row)
              ) : tbTriBy.includes(row) ? (
                <TableSortLabel
                  key={index}
                  active={orderByName === t(row)}
                  direction={orderDirection}
                  onClick={createSortHandler(t(row))}
                >
                  {t(row)}
                </TableSortLabel>
              ) : (
                t(row)
              )}
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}
const HearderDynamicTableHOC = withI18n()(HearderDynamicTable); //Higher-Order Component
export { HearderDynamicTableHOC as HearderDynamicTable };

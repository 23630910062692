import React, { Component } from "react";
import "./assets/sass/common.scss";
import { Switch, Route } from "react-router-dom";
import { Login } from "./pages/login/LoginPage";
import { Home } from "./pages/HomePage";
import { NotFound } from "./pages/NotFoundPage";
import { Claims } from "./pages/ClaimsPage";
import { Users } from "./pages/UsersPage";
import { Banners } from "./pages/BannersPage";
import { Agents } from "./pages/AgentsPage";
import { Agencies } from "./pages/AgenciesPage";
import { Notifications } from "./pages/NotificationsPage";
import { Products } from "./pages/ProductsPage";
import { Clients } from "./pages/ClientsPage";
import { InfoRequest } from "./pages/InfoRequestPage";
import { PrivateRoute, GuestRoute, AdminRoute } from "./routers";
import { isAdmin } from "./helpers";
import { AdminLayout } from "./layouts";
import { ForgotPassword } from "./pages/login/forget/ForgotPasswordPage";
import { ResetPassword } from "./pages/login/forget/ResetPasswordPage";
import { SnackBar, Loader } from "./components";
import { Credit } from "./pages/CreditPage";
import { Profiles } from "./pages/ProfilesPage";
import { SECTIONS } from "./helpers";
class App extends Component {
	state = {
		loading: true,
	};
	componentDidMount() {
		setTimeout(() => {
			this.setState({ loading: false });
		}, 500);
	}
	render() {
		const { loading } = this.state;
		return loading ? (
			<Loader />
		) : (
			<div>
				<SnackBar />
				<Switch>
					<AdminRoute
						exact
						path="/users"
						component={() => (
							<AdminLayout route="/users" name="users.title">
								<Users />
							</AdminLayout>
						)}
					/>
					<AdminRoute
						exact
						path="/profiles"
						component={() => (
							<AdminLayout route="/profiles" name="profiles.title">
								<Profiles />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						id={SECTIONS.DASHBOAD}
						exact
						path="/"
						component={() => (
							<AdminLayout route="/" name="dashboard.title">
								<Home />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						exact
						path="/clients"
						id={SECTIONS.CLIENTS}
						component={() => (
							<AdminLayout route="/clients" name="clients.title">
								<Clients />
							</AdminLayout>
						)}
					/>

					<PrivateRoute
						exact
						path="/agents"
						id={SECTIONS.AGENTS}
						component={() => (
							<AdminLayout route="/agents" name="agents.title">
								<Agents />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						exact
						id={SECTIONS.AGENCIES}
						path="/agencies"
						component={() => (
							<AdminLayout route="/agencies" name="agencies.title">
								<Agencies />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						exact
						id={SECTIONS.PRODUCTS}
						path="/products"
						component={() => (
							<AdminLayout route="/products" name="products.title">
								<Products />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						exact
						id={SECTIONS.PRODUCTS}
						path="/products/:id/credits"
						component={() => (
							<AdminLayout
								route="/products"
								name="credits.title"
								displayArrowBack={true}
							>
								<Credit />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						exact
						path="/notifications"
						id={SECTIONS.NOTIFICATIONS}
						component={() => (
							<AdminLayout route="/notifications" name="notifications.title">
								<Notifications />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						exact
						id={SECTIONS.BANNERS}
						path="/banners"
						component={() => (
							<AdminLayout route="/banners" name="banners.title">
								<Banners />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						id={SECTIONS.INFO_REQUESTS}
						exact
						path="/info-request"
						component={() => (
							<AdminLayout route="/info-request" name="informations.title">
								<InfoRequest />
							</AdminLayout>
						)}
					/>
					<PrivateRoute
						exact
						id={SECTIONS.CLAIMS}
						path="/claims"
						component={() => (
							<AdminLayout route="/claims" name="claims.title">
								<Claims />
							</AdminLayout>
						)}
					/>
					<GuestRoute exact path="/login" component={Login} />
					<GuestRoute
						exact
						path="/forgot-password"
						component={ForgotPassword}
					/>
					<GuestRoute
						exact
						path="/reset-password/:token"
						component={ResetPassword}
					/>
					<Route component={NotFound} />
				</Switch>
			</div>
		);
	}
}
export default App;

import React, { Component } from "react";
import "../../assets/sass/main.scss";

/**
 * Component global loader
 *
 * @component
 *
 * @example
 * return (
 *   <Loader/>
 * )
 */
class Loader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			done: undefined,
		};
	}
	render() {
		return <div className="app-loader"></div>;
	}
}

export { Loader };

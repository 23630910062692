
import React, { useEffect, useState, Fragment } from 'react';
import TextField from '@material-ui/core/TextField';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckLengthNumber,
  isNotNumber,
  MIN_LENGHT_PHONE,
  SECONDARY_COLOR,
  SMALL_SIZE,
  VARIANT_CONTAINED,
  VARIANT_OUTLINED,
  MIN_LENGHT_CODE,
  INPUT_REQUIRED,
} from '../../helpers';
import { useStyles } from './styles';
import { getDelegationOfGrouv, errorAgentPhone } from '../../store/actions';
import {
	Button,
	CircularProgress,
	Grid,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
	FormHelperText,
} from "@material-ui/core";

/**
 * Component Agent form
 *
 * @component
 *
 * @example
 * return (
 *   <AgentForm/>
 * )
 */
function AgentForm({ t, textButton, agent, handleButton, gourvernorate }) {
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [address, setAddress] = useState('');
  const [addressError, setAddressError] = useState('');
  const [codeP, setCodeP] = useState('');
  const [codePError, setCodePError] = useState('');
  const [note, setNote] = useState('');
  const [gouver, setGouver] = useState('');
  const [gouverError, setGouverError] = useState('');
  const [delegError, setDelegError] = useState('');
  const [deleg, setDeleg] = useState('');
  const dispatch = useDispatch(); //returns a reference to the dispatch function from the Redux store
  const classes = useStyles();
  const agents = useSelector((state) => state.agents); //extract state.agents from the Redux store
  const clients = useSelector((state) => state.clients); //extract state.clients from the Redux store
  useEffect(() => {
    //check if agent is not null 
    if (agent) {
      setGouver(agent.governorate_id);
      setName(agent.name);
      setPhone(agent.phone_num);
      setAddress(agent.address);
      setCodeP(agent.zip_code);
      if (agent.note !== 'null') setNote(agent.note);
    }
    return () => {
      dispatch(errorAgentPhone(''));
    };
  }, [dispatch, agent]);
  //Get the delegation of gouvernorat
  useEffect(() => {
    if (gouver) {
      dispatch(getDelegationOfGrouv(gouver));
    }
  }, [dispatch, gouver]);

  useEffect(() => {
    if (agent)
      if (gouver === agent.governorate_id) setDeleg(agent.delegation_id);
  }, [clients.delegation]);
  //get the title entered by user
  const handleChangeName = (e) => {
    setNameError('');
    setName(e.target.value);
  };

  //get the second phone number entered by user
  const handleChangePhone = (e) => {
    if (isNotNumber(e.target.value)) {
      setPhoneError('clients.phone.number.error');
    } else {
      setPhoneError('');
    }
    if (agents.phoneError) dispatch(errorAgentPhone(''));
    setPhone(e.target.value);
  };
  const handleChangeNote = (e) => {
    setNote(e.target.value);
  };
  const validationForm = () => {
    let isValidate = false;

    if (!name) {
      setNameError('agents.name.required.error');
      isValidate = true;
    }
    if (!phone) {
      setPhoneError('clients.phone.required.error');

      isValidate = true;
    }
    if (!address) {
      setAddressError('agents.adress.required.error');
      isValidate = true;
    }
    if (!deleg) {
      if (!gouver) setGouverError('agents.gouver.required.error');
      setDelegError('agents.deleg.required.error');
      isValidate = true;
    }
    if (phone) {
      if (CheckLengthNumber(phone, MIN_LENGHT_PHONE)) {
        setPhoneError('clients.lenght.phone.error');
        isValidate = true;
      }
    }
    if (codeP) {
      if (CheckLengthNumber(codeP, MIN_LENGHT_CODE)) {
        setCodePError('clients.lenght.codeP.error');
        isValidate = true;
      }
    }

    return isValidate;
  };
  const handleClickButton = (e) => {
    if (!validationForm())
      handleButton(name, phone, address, codeP, note, deleg);
  };
  const handleChangeAdress = (e) => {
    setAddressError('');
    setAddress(e.target.value);
  };
  const handleChangeCodeP = (e) => {
    if (isNotNumber(e.target.value))
      setCodePError('clients.phone.number.error');
    else setCodePError('');
    setCodeP(e.target.value);
  };
  const handleChangeGouvernment = (event) => {
    setGouver(event.target.value);
    setDeleg('');
    setGouverError('');
  };

  const handleChangedeleg = (event) => {
    setDeleg(event.target.value);
    setDelegError('');
  };
  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            onChange={handleChangeName}
            value={name}
            variant={VARIANT_OUTLINED}
            label={t('agents.name') + INPUT_REQUIRED}
            helperText={t(nameError)}
            error={nameError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} className={classes.gridGenderStyle}>
          <FormControl
            className={classes.formControl}
            error={gouverError ? true : false}
          >
            <Fragment>
              <InputLabel>
                {t('clients.gouvernment') + INPUT_REQUIRED}
              </InputLabel>
              <Select value={gouver} onChange={handleChangeGouvernment}>
                {gourvernorate.map((items, index) => {
                  return (
                    <MenuItem key={index} value={items.id.toString()}>
                      {items.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </Fragment>
            <FormHelperText>{t(gouverError)}</FormHelperText>
          </FormControl>

          <FormControl
            className={classes.formControl}
            error={delegError ? true : false}
            disabled={!gouver ? true : false}
          >
            <Fragment>
              <InputLabel>{t('clients.deleg') + INPUT_REQUIRED}</InputLabel>
              <Select value={deleg} onChange={handleChangedeleg}>
                {clients.delegation.map((items, index) => {
                  return (
                    <MenuItem key={index} value={items.id.toString()}>
                      {items.description}
                    </MenuItem>
                  );
                })}
              </Select>
            </Fragment>
            <FormHelperText>{t(delegError)}</FormHelperText>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            onChange={handleChangeAdress}
            value={address}
            variant={VARIANT_OUTLINED}
            label={t('agents.adress') + INPUT_REQUIRED}
            helperText={t(addressError)}
            error={addressError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleChangeCodeP}
            value={codeP}
            variant={VARIANT_OUTLINED}
            label={t('agents.code')}
            helperText={t(codePError)}
            error={codePError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            fullWidth
            onChange={handleChangePhone}
            value={phone}
            variant={VARIANT_OUTLINED}
            label={t('agents.phone') + INPUT_REQUIRED}
            helperText={
              agents.phoneError || phoneError
                ? phoneError
                  ? t(phoneError)
                  : t(agents.phoneError)
                : false
            }
            error={agents.phoneError || phoneError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            onChange={handleChangeNote}
            multiline
            rows={3}
            value={note || ''}
            variant={VARIANT_OUTLINED}
            label={t('agents.note')}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Button
        className={classes.btnStyle}
        onClick={handleClickButton}
        disabled={agents.disableButton}
        size={SMALL_SIZE}
        variant={VARIANT_CONTAINED}
        color={SECONDARY_COLOR}
      >
        {agents.disableButton && (
          <CircularProgress size={15} color={SECONDARY_COLOR} />
        )}
        {t(textButton)}
      </Button>
    </div>
  );
}
const connectedAgentForm = withI18n()(AgentForm); //Higher-Order Component
export { connectedAgentForm as AgentForm };

import {
  LOADING_UI,
  SET_ERRORS,
  CLEAR_ERRORS,
  STOP_LOADING_UI,
  OPEN_SNACKBAR,
  CLOSE_SNACKBAR,
  CLOSE_DRAWER,
  OPEN_DRAWER,
  CHANGE_DISABLED_DIALOG,
} from '../types';

const initialState = {
  loading: false,
  message: '',
  snackbar: false,
  severity: '',
  drawer: true,
  diabledDialog: false,
  varaible: '',
};

export function ui(state = initialState, action) {
  switch (action.type) {
    case LOADING_UI:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING_UI:
      return {
        ...state,
        loading: false,
      };
    case SET_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        errors: null,
      };
    case OPEN_SNACKBAR:
      return {
        ...state,
        snackbar: true,
        message: action.payload,
        severity: action.severity,
        varaible: action.varaible,
      };
    case CLOSE_SNACKBAR:
      return {
        ...state,
        snackbar: false,
        message: '',
        severity: '',
      };
    case OPEN_DRAWER:
      return {
        ...state,
        drawer: true,
      };
    case CLOSE_DRAWER:
      return {
        ...state,
        drawer: false,
      };
    case CHANGE_DISABLED_DIALOG:
      return { ...state, diabledDialog: action.payload };
    default:
      return state;
  }
}
export const reducer = (state = initialState, action) => {};

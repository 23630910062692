import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  conatiner: {
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
  },
  circularProgressStyle: {
    margin: 'auto',
  },
});

import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DynamicTable } from '..';
import {
  RESET_KEYS,
  RESET_TABLE_HEADER,
  RESET_TABLE_TRI_BY,
} from '../../helpers/constantes';
import { fetchResetPassword, downloadFile } from '../../store/actions';
import {
  changeFormatDate,
  chartData,
  ENDPOINT_STATISTIC,
  ENDPOINT_REST_PASSWORD,
  EXPORT,
} from '../../helpers';
import { LineChart } from '../lineChart/LineChart';

/**
 * Component ResetPasswordPanel
 *
 * @component
 *
 * @example
 * return (
 *   <ResetPasswordPanel/>
 * )
 */
function ResetPasswordPanel({ t }) {
  const dispatch = useDispatch();
  const resetPassword = useSelector((state) => state.resetPassword);
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [startAt, setStartAt] = useState('');
  const [endAt, setEndAt] = useState('');
  const [startAtError, setStartAtError] = useState('');
  const [endAtError, setEndAtError] = useState('');
  const [startAtFormat, setStartAtFormat] = useState('');
  const [endAtFormat, setEndAtFormat] = useState('');
  const [disbaleSecondDate, setDisabledSecondDate] = useState(true);
  const [dataChart, setDataChart] = useState({});
  const [loadingChart, setloadingChart] = useState(true);
  const download = useSelector((state) => state.download);

  //Costomize options of lineChart
  const options = {
    legend: {
      display: false,
    },
    title: {
      display: true,
      text: t('resetPassword.nbr.changement.pswd'),
    },
    maintainAspectRatio: false,
    scales: {
      yAxes: [
        {
          ticks: {
            min: 0,
            precision: 0,
          },
        },
      ],
    },
  };
  useEffect(() => {
    dispatch(
      fetchResetPassword(
        resetPassword.currentPage,
        resetPassword.perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  }, []);
  useEffect(() => {
    if (Object.values(resetPassword.statistics).length !== 0)
      setloadingChart(false);
    const data = Object.values(resetPassword.statistics);
    setDataChart(chartData(data));
  }, [resetPassword.statistics]);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchResetPassword(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchResetPassword(
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };

  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('inscription.date'):
        return RESET_KEYS[0];
      case t('RESET.code'):
        return RESET_KEYS[1];
      default:
        return RESET_KEYS[2];
    }
  };
  /**
   *  get list of paswword using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleTriByKey = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchResetPassword(
        resetPassword.currentPage,
        resetPassword.perPage,
        getColumnName(orderBy),
        orderDirection,
        startAtFormat,
        endAtFormat
      )
    );
  };
  //When the user change the data start
  const handleChangeStartAt = (date) => {
    setStartAt(date.target.value);
    setStartAtFormat(changeFormatDate(date.target.value));
    setEndAt('');
    setStartAtError('');
    if (disbaleSecondDate) setDisabledSecondDate(false);
  };
  //When the user change the data start
  const handleChangeEndAt = (date) => {
    setEndAtError('');
    setEndAt(date.target.value);
    setEndAtFormat(changeFormatDate(date.target.value));
  };
  //Handle button done
  const handleBtnDone = () => {
    if (!startAt) {
      setStartAtError('common.starts_at.error');
      setEndAtError('common.ends_at.error');
    } else if (!endAt) setEndAtError('common.ends_at.error');
    else {
      dispatch(
        fetchResetPassword(
          resetPassword.currentPage,
          resetPassword.perPage,
          tableOrderBy,
          tableOrderDirection,
          startAtFormat,
          endAtFormat
        )
      );
    }
  };
  const handleExportToExcel = () => {
    if (startAt && !endAt) {
      setEndAtError('common.ends_at.error');
    } else {
      dispatch(
        downloadFile(
          startAtFormat,
          endAtFormat,
          `${ENDPOINT_STATISTIC}${ENDPOINT_REST_PASSWORD}${EXPORT}`
        )
      );
    }
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={RESET_TABLE_HEADER}
        keys={RESET_KEYS}
        visibilityIcon={false}
        editIcon={false}
        deleteIcon={false}
        displayHeaderComponent={true}
        tbBody={resetPassword.data}
        rowsPerPage={resetPassword.perPage}
        total={resetPassword.total}
        currentPage={resetPassword.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        displayAddOrApplyBtn={true}
        textBtnAddOrApply={'common.enter'}
        loading={resetPassword.loading}
        tbTriBy={RESET_TABLE_TRI_BY}
        fetchDataBy={handleTriByKey}
        displayAddIcon={false}
        secondLabelOfPicker={'common.ends_at'}
        firstLabelOfPicker={'common.starts_at'}
        selectedFirstDate={startAt}
        selectedSecondDate={endAt}
        changeFistDate={handleChangeStartAt}
        changeSecondDate={handleChangeEndAt}
        disabledSecondDatePicker={disbaleSecondDate}
        countClientByid={resetPassword.countByClient}
        diplayPickerUi={true}
        loadingLastBtn={resetPassword.loadingLastBtn}
        openFormDialog={handleBtnDone}
        selectedFirstDateError={startAtError}
        selectedSecondDateError={endAtError}
        displayExportButton={true}
        handleDisplayExportButton={handleExportToExcel}
        disabledExportBtn={download.loading}
      />
      {!resetPassword.loading ? (
        resetPassword.data.length !== 0 ? (
          <LineChart
            data={dataChart}
            options={options}
            loading={loadingChart}
          />
        ) : null
      ) : (
        <LineChart data={dataChart} options={options} loading={loadingChart} />
      )}
    </Fragment>
  );
}
const connectedResetPasswordPanel = withI18n()(ResetPasswordPanel); //Higher-Order Component
export { connectedResetPasswordPanel as ResetPasswordPanel };

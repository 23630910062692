import { makeStyles } from '@material-ui/core/styles';
import { RUBY_COLOR, GAMBOGE_COLOR, FONT_SIZE_LARGE } from '../../helpers';

export const useStyles = makeStyles({
  paperStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingLeft: 5,
    paddingTop: 10,
    paddingBottom: 10,
    alignItems: 'center',
    borderRadius: '4px 4px 0 0',
    flexWrap: 'wrap',
  },
  searchStyle: {
    minWidth: '50%',
    padding: '5px',
  },
  boxStyle: {
    marginLeft: 20,
  },

  firstButtonStyle: {
    display: 'flex',
    justifyContent: 'space-around',
    padding: '5px',
  },
  lastButtonStyle: {
    marginRight: 5,
    marginLeft: 5,
  },

  gridStyle: {
    display: 'none',
  },

  '@media screen and (max-width:957px)': {
    gridStyle: {
      display: 'block',
    },
    paperTitleStyle: { display: 'flex', justifyContent: 'center' },
    typographyStyle: {
      marginTop: 10,
      marginBottom: 10,
      color: RUBY_COLOR,
      ' font-size': FONT_SIZE_LARGE,
      'font-weight': '700',
    },
  },

  inputSearchStyle: {
    width: '100%',
  },
  buttonProgress: {
    color: RUBY_COLOR,
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  wrapper: {
    position: 'relative',
  },
  uploadButton: {
    backgroundColor: '#dc9f13',
    color: 'white',
  },
  configButton: {
    backgroundColor: '#1da2de',
    color: 'white',
  },
  nameStyle: {
    marginLeft: 8,
    fontWeight: 'bold',
    color: '#dc9f13',
    fontSize: 18,
  },
  margin: {
    paddingBottom: 25,
  },
  tooltipStyle: {
    padding: 0,
    marginRight: 20,
    marginLeft: 20,
  },
  importExportIconStyle: {
    color: GAMBOGE_COLOR,
    height: 35,
    width: 35,
  },
});

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  gridDivStyle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  btnStyle: {
    marginTop: 50,
    left: '98%',
    position: 'sticky',
  },
  gridStyle: { display: 'flex', justifyContent: 'center' },

  checkIconStyle: { marginLeft: 8 },
});

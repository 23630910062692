import {
	Button,
	Checkbox,
	CircularProgress,
	Divider,
	FormControl,
	FormControlLabel,
	Grid,
	IconButton,
	Input,
	InputLabel,
	MenuItem,
	Select,
	TextField,
	Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
	ENDA_CLIENT,
	ERROR_COLOR,
	FONT_SIZE_LARGE,
	getWidthHeightOfImage,
	ICON_MAX_HEIGHT,
	ICON_MAX_WIDTH,
	IMAGE_MAX_HEIGHT,
	IMAGE_MAX_WIDTH,
	INPUT_REQUIRED,
	MAX_CLIENT_AGE,
	MIN_CLIENT_AGE,
	NOT_ENDA_CLIENT,
	SECONDARY_COLOR,
	SMALL_SIZE,
	TYPE_OF_IMAGES,
	VALUE_OF_GENDER_FEMALE,
	VALUE_OF_GENDER_MALE,
	VARIANT_CONTAINED,
	VARIANT_OUTLINED,
	VARIANT_SUBTITLE_TWO,
	JUSTIFY_CENTER,
	ALIGN_CENTER,
} from "../../helpers";
import {
	loadMoreAgenciesAction,
	loadMoreDelegationsAction,
	loadMoreEndaProductsAction,
	loadMoreGovernoratesAction,
} from "../../store/actions";
import { useStyles } from "./styles";

/**
 * Component global NotificationAddForm
 *
 * @component
 *
 * @example
 * return (
 *   <NotificationAddForm/>
 * )
 */
function NotificationAddForm({
	t,
	agencies,
	agents,
	governorates,
	delegations,
	textButton,
	handleButton,
	products,
}) {
	const dispatch = useDispatch();

	const classes = useStyles();

	/**
	 * declaration of the notifications
	 */
	const notifications = useSelector((state) => state.notifications);

	/**
	 * declaration of the agencies
	 */
	const [filterAgencies, setFilterAgencies] = useState([]);
	const [agenciesData, setAgenciesData] = useState([]);
	const [pageAgencies, setPageAgencies] = useState(1);

	/**
	 * declaration of the agents
	 */
	const [filterAgents, setFilterAgents] = useState([]);
	const [agentsData, setAgentsData] = useState([]);
	const [pageAgents, setPageAgents] = useState(1);

	/**
	 * declaration of the governorates
	 */
	const [filterGovernorates, setFilterGovernorates] = useState([]);
	const [governoratesData, setGovernoratesData] = useState([]);
	const [pageGovernorate, setPageGovernorate] = useState(1);

	/**
	 * declaration of the delegtaions
	 */
	const [filterDelegations, setFilterDelegations] = useState([]);
	const [delegationsData, setDelegationsData] = useState([]);
	const [pageDelegation, setPageDelegation] = useState(1);

	/**
	 * declaration of the products/credits
	 */
	const [filterProducts, setFilterProducts] = useState([]);
	const [productsData, setProductsData] = useState([]);
	const [pageProducts, setPageProducts] = useState(1);

	/**
	 * declaration of the min and max age
	 */
	const [minAge, setMinAge] = useState(MIN_CLIENT_AGE);
	const [maxAge, setMaxAge] = useState(MAX_CLIENT_AGE);
	const [minAgeError, setMinError] = useState("");
	const [maxAgeError, setMaxError] = useState("");
	const [ageError, setAgeError] = useState("");

	/**
	 * declaration of the title,url and message
	 */
	const [title, setTitle] = useState("");
	const [url, setUrl] = useState("");
	const [message, setMessage] = useState("");
	const [urlError, setUrlrror] = useState("");
	const [titleError, setTitleError] = useState("");
	const [messageError, setMessageError] = useState("");

	/**
	 * declaration of the image
	 */
	const [image, setImage] = useState("");
	const [uploadImage, setUploadImage] = useState(false);
	const [imageError, setImageError] = useState("");
	const [preViewImage, setPreViewImage] = useState("");

	/**
	 * declaration of the icon
	 */
	const [icon, setIcon] = useState("");
	const [uploadIcon, setUploadIcon] = useState(false);
	const [iconError, setIconError] = useState("");
	const [preViewIcon, setPreViewIcon] = useState("");

	/**
	 * declaration of the variable send now and send date
	 */
	const [sendNow, setSendNow] = useState(true);
	const [sendDate, setSendDate] = useState("");
	const [sendDateError, setSendDateError] = useState("");

	/**
	 * declaration of the gender
	 */
	const [genderMale, setMaleGender] = useState(false);
	const [genderFemale, setFemaleGender] = useState(false);

	/**
	 * declaration of the clients or not clients filter
	 */
	const [notEndaClients, setNotEndaClients] = useState(false);
	const [endaClients, setEndaClients] = useState(false);

	useEffect(() => {
		setAgenciesData(agencies.data);

		setAgentsData(agents.data);

		setProductsData(products.data);

		setGovernoratesData(governorates.data);

		setDelegationsData(delegations.data);
	}, [agencies, agents, governorates, delegations, products]);

	/**
	 * On change selected agencies
	 *
	 * @param {*} e
	 */
	const handleChangeAgencies = (e) => {
		setFilterAgencies(e.target.value.filter((x) => x !== undefined));
	};

	/**
	 * On change selected governorates
	 *
	 * @param {*} e
	 */
	const handleChangeGovernorates = (e) => {
		setFilterGovernorates(e.target.value.filter((x) => x !== undefined));
	};

	/**
	 * On change the checkbox send now
	 *
	 * @param {*} e
	 */
	const handleSendNowCheckBox = (e) => {
		setSendNow(!sendNow);
		setSendDate("");
	};

	/**
	 * On change the gender female
	 *
	 * @param {*} e
	 */
	const handleGenderFemale = (e) => {
		setFemaleGender(!genderFemale);
	};

	/**
	 * On change the gender male
	 *
	 * @param {*} e
	 */
	const handleGenderMale = (e) => {
		setMaleGender(!genderMale);
	};

	/**
	 * On change the checkbox not enda clients
	 *
	 * @param {*} e
	 */
	const handleNotEndaClients = (e) => {
		setNotEndaClients(!notEndaClients);
	};

	/**
	 * On change the checkbox enda clients
	 *
	 * @param {*} e
	 */
	const handleEndaClients = (e) => {
		setEndaClients(!endaClients);
	};

	/**
	 * On change selected delegations
	 *
	 * @param {*} e
	 */
	const handleChangeDelegations = (e) => {
		setFilterDelegations(e.target.value.filter((x) => x !== undefined));
	};

	/**
	 * On change selected agents
	 *
	 * @param {*} e
	 */
	const handleChangeAgents = (e) => {
		setFilterAgents(e.target.value.filter((x) => x !== undefined));
	};

	/**
	 * On change selected products/credits
	 *
	 * @param {*} e
	 */
	const handleChangeProducts = (e) => {
		setFilterProducts(e.target.value.filter((x) => x !== undefined));
	};

	/**
	 * Loading more agencies
	 *
	 * @param {*} e
	 */
	const loadMoreAgencies = (event) => {
		if (event.target.scrollTop + 300 >= event.target.scrollHeight) {
			if (agencies.lastPage > pageAgencies) {
				dispatch(
					loadMoreAgenciesAction(
						pageAgencies + 1,
						undefined,
						agencies.orderBy,
						agencies.orderDirection
					)
				);
				setPageAgencies(pageAgencies + 1);
			}
		}
	};

	/**
	 * Loading more products
	 *
	 * @param {*} e
	 */
	const loadMoreProducts = (event) => {
		if (event.target.scrollTop + 300 >= event.target.scrollHeight) {
			if (products.lastPage > pageProducts) {
				dispatch(
					loadMoreEndaProductsAction(
						pageProducts + 1,
						products.perPage,
						products.orderBy,
						products.orderDirection
					)
				);
				setPageProducts(pageProducts + 1);
			}
		}
	};

	/**
	 * Loading more agents
	 *
	 * @param {*} e
	 */
	const loadMoreAgents = (event) => {
		if (event.target.scrollTop + 300 >= event.target.scrollHeight) {
			if (agents.lastPage > pageAgents) {
				dispatch(loadMoreAgents(pageAgents + 1));
				setPageAgents(pageAgents + 1);
			}
		}
	};

	/**
	 * Updating the value of the max age
	 *
	 * @param {*} e
	 */
	const handleMaxAge = (e) => {
		setMaxError("");
		setAgeError("");
		setMaxAge(e.target.value);
	};

	/**
	 * Updating the value of the min age
	 *
	 * @param {*} e
	 */
	const handleMinAge = (e) => {
		setMinError("");
		setAgeError("");
		setMinAge(e.target.value);
	};

	/**
	 * Updating the value of the message
	 *
	 * @param {*} e
	 */
	const handleMessage = (e) => {
		setMessageError("");
		setMessage(e.target.value);
	};

	/**
	 * Updating the value of the title
	 *
	 * @param {*} e
	 */
	const handleTitle = (e) => {
		setTitleError("");
		setTitle(e.target.value);
	};

	/**
	 * Updating the value of the url
	 *
	 * @param {*} e
	 */
	const handleUrl = (e) => {
		setUrl(e.target.value);
	};

	/**
	 * Updating the value of the date
	 *
	 * @param {*} e
	 */
	const handleSendDate = (e) => {
		setSendDateError("");
		setSendDate(e.target.value);
	};

	/**
	 * Updating the value of the image
	 *
	 * @param {*} e
	 */
	const onChangeImage = (e) => {
		const file = e.target.files[0];
		if (file) {
			getWidthHeightOfImage(file).then((res) => {
				setImageError("");
				setPreViewImage("");
				setImage("");
				if (res.width > IMAGE_MAX_WIDTH || res.height > IMAGE_MAX_HEIGHT) {
					setImageError("notifications.image.error");
					setUploadImage(false);
				} else {
					setImage(file);
					setUploadImage(true);
					setPreViewImage(URL.createObjectURL(file));
				}
			});
		}
	};

	/**
	 * Removing the selected image
	 *
	 * @param {*} e
	 */
	const removeImage = () => {
		setImageError("");
		setPreViewImage("");
		setImage("");
		setUploadImage(false);
	};

	/**
	 * Updating the value of the icon
	 *
	 * @param {*} e
	 */
	const onChangeIcon = (e) => {
		const file = e.target.files[0];
		if (file) {
			getWidthHeightOfImage(file).then((res) => {
				setIconError("");
				setPreViewIcon("");
				setIcon("");
				if (res.width > ICON_MAX_WIDTH || res.height > ICON_MAX_HEIGHT) {
					setIconError("notifications.icon.error");
					setUploadIcon(false);
				} else {
					setIcon(file);
					setUploadIcon(true);
					setPreViewIcon(URL.createObjectURL(file));
				}
			});
		}
	};

	/**
	 * Loading more delegations
	 *
	 * @param {*} e
	 */
	const loadMoreDelegations = (event) => {
		if (event.target.scrollTop + 300 >= event.target.scrollHeight) {
			if (delegations.lastPage > pageDelegation) {
				dispatch(
					loadMoreDelegationsAction(
						pageDelegation + 1,
						undefined,
						delegations.orderBy,
						delegations.orderDirection
					)
				);

				setPageDelegation(pageDelegation + 1);
			}
		}
	};

	/**
	 * Verify the validation of the forms
	 *
	 * @param {*} e
	 */
	const validationForm = () => {
		let isValid = true;
		if (minAge < MIN_CLIENT_AGE) {
			setMinError("notifications.age.error");
			setAgeError("notifications.age.error");
			isValid = false;
		}
		if (maxAge > MAX_CLIENT_AGE) {
			setMaxError("notifications.age.error");
			setAgeError("notifications.age.error");
			isValid = false;
		}
		if (!minAge) {
			setMinError("notifications.age.error");
			setAgeError("notifications.age.error");
			isValid = false;
		}
		if (!maxAge) {
			setMinError("notifications.age.error");
			setAgeError("notifications.age.error");
			isValid = false;
		}
		if (!title) {
			setTitleError("notification.title.required");
			isValid = false;
		}
		if (!message) {
			setMessageError("notification.message.required");
			isValid = false;
		}
		if (!icon) {
			setIconError("notification.icon.required");
			isValid = false;
		}
		if (!sendNow && !sendDate) {
			setSendDateError("notification.date.required");
			isValid = false;
		}
		return isValid;
	};

	/**
	 * Sending the data to create a notification
	 *
	 * @param {*} e
	 */
	const handleClickButton = (e) => {
		if (validationForm()) {
			const isEndaClient =
				endaClients && !notEndaClients
					? ENDA_CLIENT
					: !endaClients && notEndaClients
					? NOT_ENDA_CLIENT
					: 0;

			const gender =
				genderFemale && !genderMale
					? VALUE_OF_GENDER_FEMALE
					: !genderFemale && genderMale
					? VALUE_OF_GENDER_MALE
					: 0;

			const toBeSendAt = !sendNow ? new Date(sendDate).getTime() / 1000 : "now";

			handleButton(
				filterAgencies,
				filterGovernorates,
				filterDelegations,
				filterProducts,
				minAge,
				maxAge,
				gender,
				isEndaClient,
				title,
				url,
				message,
				toBeSendAt,
				icon,
				image
			);
		}
	};

	/**
	 * Loading more governorate
	 *
	 * @param {*} e
	 */
	const loadMoreGovernorates = (event) => {
		if (event.target.scrollTop + 300 >= event.target.scrollHeight) {
			if (governorates.lastPage > pageGovernorate) {
				dispatch(
					loadMoreGovernoratesAction(
						pageGovernorate + 1,
						undefined,
						governorates.orderBy,
						governorates.orderDirection
					)
				);

				setPageGovernorate(pageGovernorate + 1);
			}
		}
	};
	const MenuProps = {
		getContentAnchorEl: null,

		PaperProps: {
			style: {
				maxHeight: 300,
				width: 250,
			},
		},
	};
	function loadMoreItems(event) {
		console.log(event.target.scrollTop);
		console.log(event.target.scrollHeight);

		console.log("end");
	}
	return (
		<div>
			{agentsData && agenciesData && (
				<Grid container>
					<Grid
						container
						justify={JUSTIFY_CENTER}
						className={classes.subTitleContainer}
					>
						<Grid item md={5} sm={12}>
							<Divider className={classes.divider} />
						</Grid>
						<Grid item md={2} sm={12}>
							<Typography variant="h5" className={classes.subTitle}>
								{t("notifications.target")}
							</Typography>
						</Grid>

						<Grid item md={5} sm={12}>
							<Divider className={classes.divider} />
						</Grid>
					</Grid>
					<Grid container justify={JUSTIFY_CENTER}>
						<Grid container justify={JUSTIFY_CENTER}>
							<FormControlLabel
								disabled
								control={<Checkbox />}
								label={t("notifications.digital")}
							/>
							<FormControlLabel
								disabled
								control={<Checkbox />}
								label={t("notifications.none.digital")}
							/>
						</Grid>
						<Grid container>
							<Grid container justify={JUSTIFY_CENTER}>
								<Grid item md={4} sm={12} className={classes.selectInput}>
									<FormControl className={classes.formControl}>
										<InputLabel>{t("notifications.credits")}</InputLabel>
										<Select
											value={filterProducts}
											onChange={handleChangeProducts}
											multiple
											MenuProps={{ ...MenuProps, onScroll: loadMoreProducts }}
										>
											{productsData.map((items, index) => {
												return (
													<MenuItem key={index} value={items.name}>
														{items.name}
													</MenuItem>
												);
											})}
											{products.loading && (
												<Grid container justify={JUSTIFY_CENTER}>
													<CircularProgress size={20} color={SECONDARY_COLOR} />
												</Grid>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={4} sm={12} className={classes.selectInput}>
									<FormControl className={classes.formControl}>
										<InputLabel>{t("notifications.agencies")}</InputLabel>
										<Select
											value={filterAgencies}
											onChange={handleChangeAgencies}
											multiple
											MenuProps={{ ...MenuProps, onScroll: loadMoreAgencies }}
										>
											{agenciesData.map((items, index) => {
												return (
													<MenuItem key={index} value={items.id.toString()}>
														{items.name}
													</MenuItem>
												);
											})}
											{agencies.loading && (
												<Grid container justify={JUSTIFY_CENTER}>
													<CircularProgress size={20} color={SECONDARY_COLOR} />
												</Grid>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={4} sm={12} className={classes.selectInput}>
									<FormControl className={classes.formControl}>
										<InputLabel>{t("notifications.agents")}</InputLabel>
										<Select
											value={filterAgents}
											onChange={handleChangeAgents}
											multiple
											disabled
											MenuProps={{ ...MenuProps, onScroll: loadMoreAgents }}
										>
											{agentsData.map((items, index) => {
												return (
													<MenuItem key={index} value={items.id.toString()}>
														{items.name}
													</MenuItem>
												);
											})}
											{agents.loading && (
												<Grid container justify={JUSTIFY_CENTER}>
													<CircularProgress size={20} color={SECONDARY_COLOR} />
												</Grid>
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container justify={JUSTIFY_CENTER}>
								<Grid item md={4} sm={12} className={classes.selectInput}>
									<FormControl className={classes.formControl}>
										<InputLabel>{t("notifications.governorates")}</InputLabel>
										<Select
											value={filterGovernorates}
											onChange={handleChangeGovernorates}
											multiple
											MenuProps={{
												...MenuProps,
												onScroll: loadMoreGovernorates,
											}}
										>
											{governoratesData.map((items, index) => {
												return (
													<MenuItem key={index} value={items.id.toString()}>
														{items.description}
													</MenuItem>
												);
											})}
											{governorates.loading && (
												<Grid container justify={JUSTIFY_CENTER}>
													<CircularProgress size={20} color={SECONDARY_COLOR} />
												</Grid>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={4} sm={12} className={classes.selectInput}>
									<FormControl className={classes.formControl}>
										<InputLabel>{t("notifications.delegations")}</InputLabel>
										<Select
											value={filterDelegations}
											onChange={handleChangeDelegations}
											multiple
											MenuProps={{
												...MenuProps,
												onScroll: loadMoreDelegations,
											}}
										>
											{delegationsData.map((items, index) => {
												return (
													<MenuItem key={index} value={items.id.toString()}>
														{items.description}
													</MenuItem>
												);
											})}
											{delegations.loading && (
												<Grid container justify={JUSTIFY_CENTER}>
													<CircularProgress size={20} color={SECONDARY_COLOR} />
												</Grid>
											)}
										</Select>
									</FormControl>
								</Grid>
								<Grid item md={4} sm={12} className={classes.selectInput}>
									<FormControl className={classes.formControl}>
										<InputLabel>{t("notifications.managers")}</InputLabel>
										<Select
											value={filterAgents}
											onChange={handleChangeAgents}
											multiple
											disabled
										>
											{agentsData.map((items, index) => {
												return (
													<MenuItem key={index} value={items.id.toString()}>
														{items.name}
													</MenuItem>
												);
											})}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
						</Grid>
						<Grid container justify={JUSTIFY_CENTER}>
							<Grid item md={3} sm={12}>
								<Typography className={classes.label}>
									{t("notifications.age")}:
								</Typography>
								<Grid container justify={JUSTIFY_CENTER}>
									<span className={classes.textNumber}>
										{t("common.between")}
									</span>
									<Input
										type="number"
										className={`${classes.inputNumber} text-center`}
										value={minAge}
										onChange={handleMinAge}
										error={minAgeError ? true : false}
									/>
									<span className={classes.textNumber}>{t("common.and")}</span>
									<Input
										type="number"
										className={`${classes.inputNumber} text-center`}
										value={maxAge}
										onChange={handleMaxAge}
										error={maxAgeError ? true : false}
									/>
								</Grid>
								{(maxAgeError || minAgeError) && (
									<Grid container justify={JUSTIFY_CENTER}>
										<Typography
											variant={VARIANT_SUBTITLE_TWO}
											component="h2"
											color={ERROR_COLOR}
										>
											{t(ageError)}
										</Typography>
									</Grid>
								)}
							</Grid>
							<Grid item md={4} sm={12}>
								<Typography className={classes.label}>
									{t("notifications.gender")}:
								</Typography>
								<Grid container justify={JUSTIFY_CENTER}>
									<FormControlLabel
										control={
											<Checkbox color={SECONDARY_COLOR} value={genderFemale} />
										}
										label={t("notifications.gender.female")}
										onChange={handleGenderFemale}
									/>
									<FormControlLabel
										control={
											<Checkbox color={SECONDARY_COLOR} value={genderMale} />
										}
										label={t("notifications.gender.male")}
										onChange={handleGenderMale}
									/>
								</Grid>
							</Grid>
							<Grid item md={5} sm={12}>
								<Typography className={classes.label}>
									{t("notifications.clients")}:
								</Typography>
								<Grid container justify={JUSTIFY_CENTER}>
									<FormControlLabel
										control={
											<Checkbox color={SECONDARY_COLOR} value={endaClients} />
										}
										label={t("notifications.client")}
										onChange={handleEndaClients}
									/>
									<FormControlLabel
										control={
											<Checkbox
												color={SECONDARY_COLOR}
												value={notEndaClients}
											/>
										}
										label={t("notifications.not.client")}
										onChange={handleNotEndaClients}
									/>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid container justify={JUSTIFY_CENTER}>
						<Grid
							container
							justify={JUSTIFY_CENTER}
							className={classes.subTitleSecondContainer}
						>
							<Grid item md={5} sm={12}>
								<Divider color={SECONDARY_COLOR} className={classes.divider} />
							</Grid>
							<Grid item md={2} sm={12}>
								<Typography variant="h5" className={classes.subTitle}>
									{t("notifications.subtitle.message")}
								</Typography>
							</Grid>

							<Grid item md={5} sm={12}>
								<Divider color={SECONDARY_COLOR} className={classes.divider} />
							</Grid>
						</Grid>
						<Grid container justify="space-between">
							<Grid item md={5} sm={12} className={classes.textFieldContainer}>
								<TextField
									fullWidth
									onChange={handleTitle}
									value={title}
									variant={VARIANT_OUTLINED}
									label={t("notifications.add.form.title") + INPUT_REQUIRED}
									helperText={t(titleError)}
									error={titleError ? true : false}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item md={5} sm={12} className={classes.textFieldContainer}>
								<TextField
									fullWidth
									onChange={handleUrl}
									value={url}
									variant={VARIANT_OUTLINED}
									label={t("notifications.url")}
									helperText={t(urlError)}
									error={urlError ? true : false}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item md={12} sm={12} className={classes.textFieldContainer}>
								<TextField
									multiline
									rows={3}
									fullWidth
									onChange={handleMessage}
									value={message || ""}
									variant={VARIANT_OUTLINED}
									label={t("notifications.message") + INPUT_REQUIRED}
									helperText={t(messageError)}
									error={messageError ? true : false}
									InputLabelProps={{
										shrink: true,
									}}
								/>
							</Grid>
							<Grid item md={5} sm={12}>
								<Grid container alignItems={ALIGN_CENTER}>
									<Grid item md={7} sm={12}>
										<Typography className={classes.label}>
											{t("notifications.image.upload")}
											{uploadImage && (
												<CheckIcon
													className={classes.checkIconStyle}
													color={SECONDARY_COLOR}
												/>
											)}
										</Typography>
										{imageError && (
											<Typography
												variant={VARIANT_SUBTITLE_TWO}
												component="h2"
												color={ERROR_COLOR}
											>
												{t(imageError)}
											</Typography>
										)}
									</Grid>
									<Grid item md={5} sm={12}>
										<input
											onChange={onChangeImage}
											onClick={(e) => (e.target.value = null)}
											accept={TYPE_OF_IMAGES}
											style={{ display: "none" }}
											id="image-button-file"
											type="file"
										/>
										<label htmlFor="image-button-file">
											<IconButton component="span">
												<CloudUploadIcon
													fontSize={FONT_SIZE_LARGE}
													color={SECONDARY_COLOR}
												/>
											</IconButton>
										</label>
									</Grid>
									{preViewImage && (
										<Grid container>
											<Grid item md={10} className={classes.imageContainer}>
												<img src={preViewImage} alt="image" />
											</Grid>
											<Grid item md={1} className={classes.deleteIconContainer}>
												<IconButton aria-label="delete" onClick={removeImage}>
													<DeleteIcon />
												</IconButton>
											</Grid>
										</Grid>
									)}
								</Grid>
								<Grid container alignItems={ALIGN_CENTER}>
									<Grid item md={7} sm={12}>
										<Typography className={classes.label}>
											{t("notifications.icon.upload")}
											{uploadIcon && (
												<CheckIcon
													className={classes.checkIconStyle}
													color={SECONDARY_COLOR}
												/>
											)}
										</Typography>
										{iconError && (
											<Typography
												variant={VARIANT_SUBTITLE_TWO}
												component="h2"
												color={ERROR_COLOR}
											>
												{t(iconError)}
											</Typography>
										)}
									</Grid>
									<Grid item md={5} sm={12}>
										<input
											onChange={onChangeIcon}
											accept={TYPE_OF_IMAGES}
											style={{ display: "none" }}
											id="icon-button-file"
											type="file"
										/>
										<label htmlFor="icon-button-file">
											<IconButton component="span">
												<CloudUploadIcon
													fontSize={FONT_SIZE_LARGE}
													color={SECONDARY_COLOR}
												/>
											</IconButton>
										</label>
									</Grid>
									{preViewIcon && (
										<Grid item md={10} className={classes.imageContainer}>
											<img src={preViewIcon} alt="icon" />
										</Grid>
									)}
								</Grid>
							</Grid>
							<Grid item md={7} sm={12}>
								<Grid
									container
									alignItems={ALIGN_CENTER}
									justify={JUSTIFY_CENTER}
									className={classes.dateTimeContainer}
								>
									<Grid item md={12}>
										<Typography className={classes.label}>
											{t("notifications.date.time") +
												(sendNow ? "" : INPUT_REQUIRED)}
											:
										</Typography>
									</Grid>
									<Grid item md={7} sm={12}>
										<TextField
											id="datetime-local"
											type="datetime-local"
											className={classes.textField}
											InputLabelProps={{
												shrink: true,
											}}
											disabled={sendNow}
											onChange={handleSendDate}
											value={sendDate}
											error={sendDateError ? true : false}
											helperText={t(sendDateError)}
										/>
									</Grid>
									<Grid item md={5} sm={12}>
										<FormControlLabel
											control={
												<Checkbox color={SECONDARY_COLOR} checked={sendNow} />
											}
											onChange={handleSendNowCheckBox}
											label={t("notifications.now")}
										/>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Button
						className={classes.btnStyle}
						onClick={handleClickButton}
						disabled={notifications.disableButton}
						size={SMALL_SIZE}
						variant={VARIANT_CONTAINED}
						color={SECONDARY_COLOR}
					>
						{notifications.disableButton && (
							<CircularProgress size={15} color={SECONDARY_COLOR} />
						)}
						{t(textButton)}
					</Button>
				</Grid>
			)}
		</div>
	);
}
const connectedNotificationAddForm = withI18n()(NotificationAddForm); //Higher-Order Component
export { connectedNotificationAddForm as NotificationAddForm };

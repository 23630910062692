import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import {
  DynamicTable,
  FormDialog,
  BannersForm,
  DeleteDialog,
} from '../components';
import {
  BANNERS_KEYS,
  BANNERS_TABLE_HEADER,
  BANNERS_TABLE_TRI_BY,
} from '../helpers/constantes';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchBanners,
  bannersFormDialog,
  createBanner,
  bannersUpdateFormDialog,
  changeDeleteDialogBanner,
  updateBanner,
  deleteBanner,
} from '../store/actions';

/**
 * Component Banners
 *
 * @component
 *
 * @example
 * return (
 *   <Banners/>
 * )
 */
function Banners({ t }) {
  const dispatch = useDispatch();
  const banners = useSelector((state) => state.banners);
  const [data, setData] = useState({});
  const [bannerId, setIdBanner] = useState(null);
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  useEffect(() => {
    dispatch(
      fetchBanners(
        banners.currentPage,
        banners.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  }, []);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(fetchBanners(page, perPage, tableOrderBy, tableOrderDirection));
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(fetchBanners(page, perPage, tableOrderBy, tableOrderDirection));
  };
  //Close the Form dialog  (add new banner)
  const handleCloseFormDialog = () => {
    dispatch(bannersFormDialog(false));
  };
  //Open the Form dialog(add new banner)
  const openFormDialog = () => {
    dispatch(bannersFormDialog(true));
  };
  //Function to create a new banner
  const handleCreateBanner = (title, subtitle, urlRedirection, image, id) => {
    dispatch(
      createBanner(
        title,
        subtitle,
        urlRedirection,
        image,
        banners.currentPage,
        banners.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //Function to upadate a banner
  const handleUpdateBanner = (title, subtitle, urlRedirection, image, id) => {
    dispatch(
      updateBanner(
        title,
        subtitle,
        urlRedirection,
        image,
        id,
        banners.currentPage,
        banners.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (banner) => {
    setData(banner);
    dispatch(bannersUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(bannersUpdateFormDialog(false));
  };
  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setIdBanner(id);
    dispatch(changeDeleteDialogBanner(true));
  };
  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialogBanner(false));
  };
  //Action to delete banner
  const handleDeleteBanner = () => {
    dispatch(
      deleteBanner(
        bannerId,
        banners.currentPage,
        banners.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('common.title'):
        return BANNERS_KEYS[0];
      case t('common.subTitle'):
        return BANNERS_KEYS[1];
      case t('common.createdAt'):
        return BANNERS_KEYS[2];
      default:
        return BANNERS_KEYS[0];
    }
  };

  /**
   *  get list of banners using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchBannersBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);

    dispatch(
      fetchBanners(
        banners.currentPage,
        banners.perPage,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={BANNERS_TABLE_HEADER}
        keys={BANNERS_KEYS}
        visibilityIcon={false}
        displayHeaderComponent={true}
        tbBody={banners.data}
        rowsPerPage={banners.perPage}
        total={banners.total}
        currentPage={banners.currentPage}
        displayAddOrApplyBtn={true}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openFormDialog={openFormDialog}
        openUpdateFormDialog={openUpdateFormDialog}
        openDeleteDialog={openDeleteDialog}
        textBtnAddOrApply="banners.addNew"
        title={'banners.title'}
        loading={banners.loading}
        tbTriBy={BANNERS_TABLE_TRI_BY}
        fetchDataBy={handleFetchBannersBy}
      />
      {/* Dialog To create new banner */}
      <FormDialog
        openFormDialog={banners.openDialogForm}
        handleCloseFormDialog={handleCloseFormDialog}
        buttonTitle={'banners.text.button.create'}
        title={'banners.addNew'}
      >
        <BannersForm
          textButton={'banners.text.button.create'}
          handleButton={handleCreateBanner}
        />
      </FormDialog>
      {/* Dialog To update banner */}
      <FormDialog
        openFormDialog={banners.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        buttonTitle={'banners.text.button.update'}
        title={'users.update'}
      >
        <BannersForm
          passwordRequired={false}
          textButton={'banners.text.button.update'}
          banner={data}
          handleButton={handleUpdateBanner}
        />
      </FormDialog>
      {/* Dialog To delete banner */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="banners.delete"
        open={banners.openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteBanner}
      />
    </Fragment>
  );
}
const connectedBannersPage = withI18n()(Banners); //Higher-Order Component
export { connectedBannersPage as Banners };

import {
	DEFAULT_ORDER_DIRECTION,
	getFirstErrorFromResponse,
	SERVERITY_ERROR,
	SERVERITY_SUCCESS,
} from "../../helpers";
import {
	ENDPOINT_USER,
	ORDER_BY,
	ORDER_DIRECTION,
	PAGE,
	ENDPOINT_PER_PAGE,
	PREFIX_ADMIN,
	ENDPOINT_PASSWORD,
} from "../../helpers/endPoint";
import { methodsServices } from "../services";
import {
	CHANGE_DISABLED_DIALOG,
	CHANGE_VALUE_DELETE_DIALOG,
	CHANGE_VALUE_FORMDIALOG,
	CHANGE_VALUE_UPDATE_FORMDIALOG,
	DELETED_USER_SUCCESS,
	ENABLE_BUTTON_USER,
	ERROR_EMAIL,
	GET_USER,
	GET_USERS_SUCCESS,
	GET_USER_FAILED,
	OPEN_SNACKBAR,
	UPDATE_USER_SUCCESS,
	USER_CREATED_FAILED,
	USER_CREATED_SUCCESS,
	EMPTY_ERRORS,
	ClOSE_PASSWORD_DIALOG,
	OPEN_PASSWORD_DIALOG,
} from "../types";

/**
 * Action Creator to get all the users
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchUser(
	page,
	perPage,
	orderBy = "",
	orderDirection = DEFAULT_ORDER_DIRECTION
) {
	return (dispatch) => {
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_USER}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;
		if (orderBy) {
			apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
		}
		methodsServices
			.get(apiEndpoint)
			.then((response) => {
				dispatch({
					type: GET_USERS_SUCCESS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: GET_USER_FAILED });
			});
	};
}
/**
 * Action Creator to create a new user ( admin or agent )
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} email
 * @param {string} password
 * @param {string} rePassword
 * @param {string} profileId
 * @param {int} id
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function createUser(
	firstName,
	lastName,
	email,
	password,
	rePassword,
	profileId,
	page,
	perPage,
	orderBy,
	orderDirection
) {
	return (dispatch) => {
		dispatch({ type: GET_USER });
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_USER}`;
		let formData = new FormData();
		formData.append("first_name", firstName);
		formData.append("last_name", lastName);
		formData.append("email", email);
		formData.append("profile_id", profileId || "");
		formData.append("password", password);
		formData.append("password_confirmation", rePassword);

		methodsServices
			.post(apiEndpoint, formData, true)
			.then((response) => {
				dispatch({ type: USER_CREATED_SUCCESS, payload: response.data.data });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "users.created",
					severity: SERVERITY_SUCCESS,
				});
				dispatch(fetchUser(page, perPage, orderBy, orderDirection));
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: ENABLE_BUTTON_USER });
				dispatch({
					type: USER_CREATED_FAILED,
					playload: getFirstErrorFromResponse(error),
				});
			});
	};
}
/**
 * Action Creator to update user ( admin or agent )
 *
 * @param {String} firstName
 * @param {String} lastName
 * @param {String} email
 * @param {string} password
 * @param {string} rePassword
 * @param {string} profileId
 * @param {int} id
 *
 * @returns function contains an action
 */
export function updateUser(
	firstName,
	lastName,
	email,
	password,
	rePassword,
	profileId,
	id,
	page,
	perPage,
	orderBy,
	orderDirection
) {
	return (dispatch) => {
		dispatch({ type: GET_USER });
		let formData = new FormData();
		formData.append("first_name", firstName);
		formData.append("last_name", lastName);
		formData.append("email", email);
		formData.append("profile_id", profileId || "");
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_USER}/${id}`;
		if (password) {
			formData.append("password", password);
			formData.append("password_confirmation", rePassword);
		}
		methodsServices
			.post(apiEndpoint, formData, true)
			.then((response) => {
				dispatch(fetchUser(page, perPage, orderBy, orderDirection));
				dispatch({ type: UPDATE_USER_SUCCESS, payload: response.data.data });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "users.uptated",
					severity: SERVERITY_SUCCESS,
				});
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: ENABLE_BUTTON_USER });
				dispatch({
					type: USER_CREATED_FAILED,
					playload: getFirstErrorFromResponse(error),
				});
			});
	};
}


/**
 * Action Creator to reset a password
 *
 * @param {string} value
 *
 * @returns an action
 */
export const resetPasswordAction = (currentPassword,password, rePassword) => {
	return (dispatch) => {
		dispatch({ type: GET_USER });
		let formData = new FormData();
		formData.append("current_password", currentPassword);
		formData.append("password", password);
		formData.append("password_confirmation", rePassword);

		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PASSWORD}`;

		methodsServices
			.post(apiEndpoint, formData, true)
			.then((response) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "users.password.uptated",
					severity: SERVERITY_SUCCESS,
				});
				dispatch({ type: ClOSE_PASSWORD_DIALOG });
				dispatch({ type: ENABLE_BUTTON_USER });
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: ENABLE_BUTTON_USER });
			});
	};
};

/**
 * Action Creator to delete user and call fetchUser action to reload data
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function deleteUser(id, currentPage, perPage, orderBy, orderDirection) {
	return (dispatch) => {
		dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_USER}/${id}`;
		methodsServices
			.deleteDetail(apiEndpoint)
			.then((response) => {
				dispatch(fetchUser(currentPage, perPage, orderBy, orderDirection));
				dispatch({ type: DELETED_USER_SUCCESS, payload: id });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "users.deleted",
					severity: SERVERITY_SUCCESS,
				});
				dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});

				dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
			});
	};
}
/**
 * Action Creator to open and close the Form dialog (user)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeFormDialog = (value) => {
	return {
		type: CHANGE_VALUE_FORMDIALOG,
		payload: value,
	};
};
/**
 * Action Creator to open and close the update form dialog (user)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeUpdateFormDialog = (values) => {
	return {
		type: CHANGE_VALUE_UPDATE_FORMDIALOG,
		payload: values,
	};
};
/**
 * Action Creator to open and close the delete dialog (user)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialog = (value) => {
	return {
		type: CHANGE_VALUE_DELETE_DIALOG,
		payload: value,
	};
};
/**
 * Action Creator to handle the error of Email
 *
 * @param {string} value
 *
 * @returns an action
 */
export const errorEmail = (value) => {
	return {
		type: ERROR_EMAIL,
		payload: value,
	};
};

/**
 * Action Creator to empty the errors
 *
 * @param {string} value
 *
 * @returns an action
 */
export const emptyUserError = () => {
	return {
		type: EMPTY_ERRORS
	};
};

/**
 * Action Creator to open reset password dialog
 *
 * @param {string} value
 *
 * @returns an action
 */
export const openDialogResetPassword = () => {
	return {
		type: OPEN_PASSWORD_DIALOG,
	};
};

/**
 * Action Creator to close reset password dialog
 *
 * @param {string} value
 *
 * @returns an action
 */
export const closeDialogResetPassword = () => {
	return {
		type: ClOSE_PASSWORD_DIALOG,
	};
};


import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AgentForm,
  DeleteDialog,
  DynamicTable,
  FormDialog,
} from '../components';
import {
  AGENTS_TABLE_HEADER,
  AGENTS_KEYS,
  AGENTS_TABLE_TRI_BY,
  KEY_GOUVERNORAT_ID,
} from '../helpers/constantes';
import {
  agentFormDialog,
  AgentUpdateFormDialog,
  deleteAgent,
  createAgent,
  getGovernorate,
  updateAgent,
  changeDeleteDialogAgent,
  fetchAgents,
  uploadCsv,
} from '../store/actions';

/**
 * Component Agents
 *
 * @component
 *
 * @example
 * return (
 *   <Agents/>
 * )
 */
function Agents({ t }) {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);
  const agents = useSelector((state) => state.agents);
  const [data, setData] = useState({});
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [agentId, setIdAgent] = useState(null);
  const [search, setSearch] = useState('');
  useEffect(() => {
    dispatch(
      fetchAgents(
        agents.currentPage,
        agents.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  }, []);
  useEffect(() => {
    if (clients.gouvernorate.length === 0) dispatch(getGovernorate());
  }, []);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchAgents(page, perPage, search, tableOrderBy, tableOrderDirection)
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchAgents(page, perPage, search, tableOrderBy, tableOrderDirection)
    );
  };
  const handleSearch = (message) => {
    setSearch(message);
    dispatch(
      fetchAgents(
        agents.currentPage,
        agents.perPage,
        message,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };

  //Open the Form dialog(add new agent)
  const openFormDialog = () => {
    dispatch(agentFormDialog(true));
  };

  //Function to upadate a agent
  const handleUpdateAgent = (name, phone, address, codeP, note, deleg) => {
    dispatch(
      updateAgent(
        name,
        phone,
        address,
        codeP,
        note,
        deleg,
        agents.currentPage,
        agents.perPage,
        search,
        data.id,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (agent) => {
    setData(agent);
    dispatch(AgentUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(AgentUpdateFormDialog(false));
  };
  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setIdAgent(id);
    dispatch(changeDeleteDialogAgent(true));
  };
  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialogAgent(false));
  };
  //Action to delete agent
  const handleDeleteAgent = () => {
    dispatch(deleteAgent(agentId, agents.currentPage, agents.perPage, search));
  };
  const handleCloseFormDialog = () => {
    dispatch(agentFormDialog(false));
  };
  const handleCreateAgent = (name, phone, address, codeP, note, deleg) => {
    dispatch(
      createAgent(
        name,
        phone,
        address,
        codeP,
        note,
        deleg,
        agents.currentPage,
        agents.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  const handleChangeCsv = (e) => {
    dispatch(
      uploadCsv(
        e.target.files[0],
        agents.currentPage,
        agents.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
    //reset file input
    e.target.value = null;
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('agents.name'):
        return AGENTS_KEYS[0];
      default:
        return KEY_GOUVERNORAT_ID;
    }
  };
  /**
   *  get list of agent using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchAgentBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchAgents(
        agents.currentPage,
        agents.perPage,
        search,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={AGENTS_TABLE_HEADER}
        keys={AGENTS_KEYS}
        displaySearch={true}
        visibilityIcon={false}
        displayHeaderComponent={true}
        tbBody={agents.data}
        rowsPerPage={agents.perPage}
        total={agents.total}
        currentPage={agents.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openFormDialog={openFormDialog}
        openUpdateFormDialog={openUpdateFormDialog}
        openDeleteDialog={openDeleteDialog}
        title={'agents.title'}
        displayUploadBtn={true}
        displayAddOrApplyBtn={true}
        textBtnAddOrApply={'agents.create'}
        loading={agents.loading}
        handleSearch={handleSearch}
        onChangeUploadBtn={handleChangeCsv}
        tbTriBy={AGENTS_TABLE_TRI_BY}
        fetchDataBy={handleFetchAgentBy}
      />
      {/* Dialog To create  agent */}
      <FormDialog
        openFormDialog={agents.openDialogForm}
        buttonTitle={'banners.text.button.update'}
        title={'agents.create'}
        handleCloseFormDialog={handleCloseFormDialog}
      >
        <AgentForm
          textButton={'agents.btn.create'}
          handleButton={handleCreateAgent}
          gourvernorate={clients.gouvernorate}
        />
      </FormDialog>
      {/* Dialog To update  agent */}
      <FormDialog
        openFormDialog={agents.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        buttonTitle={'banners.text.button.update'}
        title={'agents.update'}
      >
        <AgentForm
          textButton={'agents.btn.update'}
          agent={data}
          handleButton={handleUpdateAgent}
          gourvernorate={clients.gouvernorate}
        />
      </FormDialog>
      {/* Dialog To delete agent */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="agents.delete"
        open={agents.openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteAgent}
      />
    </Fragment>
  );
}
const connectedAgentsPage = withI18n()(Agents); //Higher-Order Component
export { connectedAgentsPage as Agents };

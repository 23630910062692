import {
	getFirstErrorFromResponse,
	SERVERITY_ERROR,
	ORDER_DIRECTION_ASC,
} from "../../helpers";
import {
	PAGE,
	ENDPOINT_PER_PAGE,
	PREFIX_ADMIN,
	ENDPOINT_SECTION,
	ORDER_DIRECTION,
	ORDER_BY,
	ORDER_BY_ID,
} from "../../helpers/endPoint";
import { methodsServices } from "../services";
import {
	GET_SECTIONS_FAILED,
	GET_SECTIONS_SUCCESS,
	INIT_SECTIONS,
	OPEN_SNACKBAR,
} from "../types";

/**
 * Action Creator to get all sections
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchSections(page = 1, perPage = 3) {
	return (dispatch) => {
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_SECTION}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${ORDER_BY}${ORDER_BY_ID}${ORDER_DIRECTION}${ORDER_DIRECTION_ASC}`;
		methodsServices
			.get(apiEndpoint)
			.then((response) => {
				dispatch({
					type: GET_SECTIONS_SUCCESS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: GET_SECTIONS_FAILED });
			});
	};
}

/**
 * Action Creator to reset the section to initial state
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const resetSections = (value) => {
	return {
		type: INIT_SECTIONS,
	};
};

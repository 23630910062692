import {
  DEFAULT_ORDER_DIRECTION,
  getFirstErrorFromResponse,
  SERVERITY_ERROR,
} from '../../helpers';
import {
  ENDPOINT_STATISTIC,
  ENDPOINT_STATISTIC_CLAIM,
  ENDS_AT,
  ORDER_BY,
  ORDER_DIRECTION,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
  STARTS_AT,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  GET_CLAIM_PANEL_FAILED,
  GET_CLAIM_PANEL_SUCCESS,
  OPEN_SNACKBAR,
  RESET_CLAIM_PANEL_LOADING_LAST_BTN,
} from '../types';

/**
 * Action Creator to get all agencies
 *
 * @param {int} page
 * @param {int} perPage
 * @param {string} orderBy
 * @param {string} orderDirection
 *
 * @returns function contains an action
 */
export function fetchClaimPanel(
  page,
  perPage,
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION,
  startAt,
  endAt
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_STATISTIC}${ENDPOINT_STATISTIC_CLAIM}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;
    if (orderBy)
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    if (startAt) {
      apiEndpoint = `${apiEndpoint}${STARTS_AT}${startAt}${ENDS_AT}${endAt}`;
      dispatch({ type: RESET_CLAIM_PANEL_LOADING_LAST_BTN, payload: true });
    }
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({ type: RESET_CLAIM_PANEL_LOADING_LAST_BTN, payload: false });
        dispatch({
          type: GET_CLAIM_PANEL_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_CLAIM_PANEL_FAILED });
        dispatch({ type: RESET_CLAIM_PANEL_LOADING_LAST_BTN, payload: false });
      });
  };
}

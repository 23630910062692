import React, { Fragment, useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableContainer from "@material-ui/core/TableContainer";
import { withI18n } from "react-i18next";
import { ORDER_DIRECTION_DESC } from "../../helpers";
import { BodyFormTable } from "./BodyFormTable";
import { useStyles } from "./styles";
import Pagination from "@material-ui/lab/Pagination";

/**
 * Component global table
 * this component contains 3 components BodyFormTable , HearderDynamicTable and HeaderComponent
 *
 * @component
 *
 * @example
 * return (
 *   <FormTable/>
 * )
 */
function FormTable({
	tbHeader,
	keys = [],
	tbBody = [],
	currentPage = 1,
	rowsPerPage = 5,
	changePage,
	total,
	changeRowsPerPage,
	loading,
	t,
	orderBy = "",
	order = ORDER_DIRECTION_DESC,
	tbTriBy = [],
	fetchDataBy,
	pages,
	handleChangeCheck,
	values,
	type = "single",
	value
}) {
	const classes = useStyles();
	const [rowsPage, setRowsPage] = useState(rowsPerPage);

	//Handle the case when the body of table is empty and the currentPage diffrent to 1
	useEffect(() => {
		if (tbBody.length === 0 && currentPage > 1) changePage(1, rowsPage);
	}, [tbBody, currentPage, rowsPage, changePage]);

	//function to change the page
	const handleChangePage = (event, page) => {
		changePage(page, rowsPage);
	};
	//get the value of rowPerPage from props
	useEffect(() => {
		setRowsPage(rowsPerPage);
	}, [rowsPerPage]);

	return (
		<Fragment>
			<Grid item xs={12} className={classes.formTable}>
				<TableContainer>
					<Table>
						<Fragment>
							<BodyFormTable
								tbHeader={tbHeader}
								keys={keys}
								tbBody={tbBody}
								loading={loading}
								handleChangeCheck={handleChangeCheck}
								values={values}
								value={value}
								type={type}
							/>
						</Fragment>
					</Table>
				</TableContainer>
				{!loading && pages > 0 && (
					<Pagination
						className={classes.formTablePagination}
						count={pages}
						page={currentPage}
						onChange={handleChangePage}
					/>
				)}
			</Grid>
		</Fragment>
	);
}
const FormTableHOC = withI18n()(FormTable); //Higher-Order Component
export { FormTableHOC as FormTable };

import { Avatar, Divider, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch } from 'react-redux';
import config from '../../config/config';
import { customizeDate, ALIGN_CENTER } from '../../helpers';
import { useStyles } from './styles';

/**
 * Component global NotificationPreview
 *
 * @component
 *
 * @example
 * return (
 *   <NotificationPreview/>
 * )
 */
function NotificationPreview({ t, notification }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div>
      <Grid
        container
        justify={ALIGN_CENTER}
        className={classes.notifictionContainer}
      >
        <Grid container justify={ALIGN_CENTER} className={classes.bloc}>
          <Typography variant="h5" component="h5">
            {notification.title}
          </Typography>
        </Grid>
        <Grid container justify={ALIGN_CENTER}>
          {customizeDate(notification.send_date)}
        </Grid>
        <Divider variant="middle" />
        <Grid container className={classes.bloc}>
          <Grid item md={12} sm={12}>
            <Typography className={classes.label}>
              {t('notifications.icon')}
            </Typography>
          </Grid>
          <Grid container justify={ALIGN_CENTER} className={classes.content}>
            <Avatar
              alt="icon"
              src={`${config.imageUrl}${notification.icon}`}
              className={classes.icon}
            />
          </Grid>
        </Grid>
        {notification.url && (
          <Grid container className={classes.bloc}>
            <Grid item md={12} sm={12}>
              <Typography className={classes.label}>
                {t('notifications.url')}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} className={classes.content}>
              {notification.url}
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.bloc}>
          <Grid item md={12} sm={12}>
            <Typography className={classes.label}>
              {t('notifications.message')}
            </Typography>
          </Grid>
          <Grid item md={12} sm={12} className={classes.content}>
            {notification.message}
          </Grid>
        </Grid>

        {notification.image && (
          <Grid container className={classes.bloc}>
            <Grid item md={12} sm={12}>
              <Typography className={classes.label}>
                {t('notifications.image')}
              </Typography>
            </Grid>
            <Grid item md={12} sm={12} className={classes.content}>
              <img
                alt="image"
                src={`${config.imageUrl}${notification.image}`}
                className={classes.image}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </div>
  );
}
const connectedNotificationPreview = withI18n()(NotificationPreview); //Higher-Order Component
export { connectedNotificationPreview as NotificationPreview };

import {
  DEFAULT_ORDER_DIRECTION,
  ENDPOINT_CREDIT_REQUEST_STATISTICS,
  ENDPOINT_STATISTIC,
  ENDS_AT,
  getFirstErrorFromResponse,
  ORDER_BY,
  ORDER_DIRECTION,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
  STARTS_AT,
  SERVERITY_ERROR,
} from '../../helpers';
import { methodsServices } from '../services';
import {
  CREDIT_REQUEST_STATISTICS_LOADING_LAST_BTN,
  GET_CREDIT_REQUEST_STATISTICS_FAILED,
  GET_CREDIT_REQUEST_STATISTICS_SUCCESS,
  OPEN_SNACKBAR,
} from '../types';

/**
 * Action Creator to get all agencies
 *
 * @param {int} page
 * @param {int} perPage
 * @param {string} orderBy
 * @param {string} orderDirection
 *
 * @returns function contains an action
 */
export function fetchCreditRequestStatistics(
  page,
  perPage,
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION,
  startAt,
  endAt
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_STATISTIC}${ENDPOINT_CREDIT_REQUEST_STATISTICS}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;
    if (orderBy)
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    if (startAt) {
      apiEndpoint = `${apiEndpoint}${STARTS_AT}${startAt}${ENDS_AT}${endAt}`;
      dispatch({
        type: CREDIT_REQUEST_STATISTICS_LOADING_LAST_BTN,
        payload: true,
      });
    }
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: CREDIT_REQUEST_STATISTICS_LOADING_LAST_BTN,
          payload: false,
        });
        dispatch({
          type: GET_CREDIT_REQUEST_STATISTICS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_CREDIT_REQUEST_STATISTICS_FAILED });
        dispatch({
          type: CREDIT_REQUEST_STATISTICS_LOADING_LAST_BTN,
          payload: false,
        });
      });
  };
}

const drawerWidth = 270;
const miniDrawerWidth = 70;

export const styles = (theme) => ({
	layoutContainer: {
		[theme.breakpoints.up("md")]: {
			marginLeft: miniDrawerWidth,
		},
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	layoutContainerShift: {
		[theme.breakpoints.up("md")]: {
			marginLeft: drawerWidth,
		},
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
});

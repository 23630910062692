import {
  AGENTS_OPEN_FORM_DIALOG,
  CHANGE_AGENT_DELETE_DIALOG,
  GET_AGENTS_FAILED,
  GET_AGENTS_SUCCESS,
  LOADING_AGENTS,
  AGENTS_CREATED_SUCCESS,
  AGENTS_FAILED,
  DELETED_AGENTS_SUCCESS,
  AGENTS_UPADTE_FORM_DIALOG,
  UPDATE_AGENTS_SUCCESS,
  ERROR_AGENT_PHONE,
  GET_AGENTS,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  openDialogForm: false,
  disableButton: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
  phoneError: '',
};

const agentsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AGENTS_OPEN_FORM_DIALOG:
      return { ...state, openDialogForm: action.payload };
    case CHANGE_AGENT_DELETE_DIALOG:
      return { ...state, openDeleteDialog: action.payload };
    case GET_AGENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        perPage: Number(action.payload.per_page),
        total: action.payload.total,
        loading: false,
      };
    case GET_AGENTS_FAILED:
      return { ...state, loading: false };
    case LOADING_AGENTS:
      return { ...state, disableButton: true };
    case AGENTS_CREATED_SUCCESS:
      return { ...state, openDialogForm: false, disableButton: false };
    case AGENTS_FAILED:
      return { ...state, disableButton: false };
    case DELETED_AGENTS_SUCCESS:
      return { ...state, openDeleteDialog: false };
    case AGENTS_UPADTE_FORM_DIALOG:
      return { ...state, openUpdateDialogForm: action.payload };
    case UPDATE_AGENTS_SUCCESS:
      return { ...state, openUpdateDialogForm: false, disableButton: false };
    case ERROR_AGENT_PHONE:
      return { ...state, phoneError: action.payload };
    case GET_AGENTS:
      return { ...state, loading: true };
    default:
      return state;
  }
};

export default agentsReducer;

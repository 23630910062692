import React from 'react';
import { Typography, Box } from '@material-ui/core';
/**
 * Component tabPanel for HomePAage
 *
 * @component
 *
 *
 *
 * @example
 * return (
 *   <TabPanel children={children} value={value} index={index}/>
 * )
 */
export function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

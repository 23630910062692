import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteDialog,
  DynamicTable,
  FormDialog,
  AgencieForm,
} from '../components';
import {
  AGENCIES_TABLE_HEADER,
  AGENCIES_KEYS,
  AGENCIES_TABLE_TRI_BY,
} from '../helpers/constantes';
import {
  fetchAgencies,
  getGovernorate,
  createAgencie,
  agenciesFormDialog,
  agenciesUpdateFormDialog,
  changeDeleteDialogAgencie,
  updateAgencie,
  deleteAgencie,
  uploadAgencies,
} from '../store/actions';

/**
 * Component Agencies
 *
 * @component
 *
 * @example
 * return (
 *   <Agencies/>
 * )
 */
function Agencies({ t }) {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);
  const agencies = useSelector((state) => state.agencies);
  const [data, setData] = useState({});
  const [agencieId, setIdAgencie] = useState(null);
  const [search, setSearch] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  useEffect(() => {
    dispatch(fetchAgencies(agencies.currentPage, agencies.perPage, search));
  }, []);
  useEffect(() => {
    if (clients.gouvernorate.length === 0) dispatch(getGovernorate());
  }, []);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchAgencies(page, perPage, search, tableOrderBy, tableOrderDirection)
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchAgencies(page, perPage, search, tableOrderBy, tableOrderDirection)
    );
  };
  const handleSearch = (message) => {
    setSearch(message);
    dispatch(
      fetchAgencies(
        agencies.currentPage,
        agencies.perPage,
        message,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };

  //Open the Form dialog(add new agent)
  const openFormDialog = () => {
    dispatch(agenciesFormDialog(true));
  };

  //Function to upadate a agent
  const handleUpdateAngencie = (
    name,
    phone,
    address,
    codeP,
    longitude,
    latitude,
    gouver
  ) => {
    dispatch(
      updateAgencie(
        name,
        phone,
        address,
        codeP,
        longitude,
        latitude,
        gouver,
        agencies.currentPage,
        agencies.perPage,
        search,
        data.id,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (agencie) => {
    setData(agencie);
    dispatch(agenciesUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(agenciesUpdateFormDialog(false));
  };
  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setIdAgencie(id);
    dispatch(changeDeleteDialogAgencie(true));
  };
  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialogAgencie(false));
  };
  //Action to delete agencie
  const handleDeleteAgencie = () => {
    dispatch(
      deleteAgencie(
        agencieId,
        agencies.currentPage,
        agencies.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  const handleCloseFormDialog = () => {
    dispatch(agenciesFormDialog(false));
  };
  const handleCreateAgencie = (
    name,
    phone,
    address,
    codeP,
    longitude,
    latitude,
    gouver
  ) => {
    dispatch(
      createAgencie(
        name,
        phone,
        address,
        codeP,
        longitude,
        latitude,
        gouver,
        agencies.currentPage,
        agencies.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  const handleChangeCsv = (e) => {
    dispatch(
      uploadAgencies(
        e.target.files[0],
        agencies.currentPage,
        agencies.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
    //reset file input
    e.target.value = null;
  };

  /**
   *  get list of agent using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchAgenciesBy = (orderBy, orderDirection) => {
    setTableOrderBy(AGENCIES_KEYS[0]);
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchAgencies(
        agencies.currentPage,
        agencies.perPage,
        search,
        AGENCIES_KEYS[0],
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={AGENCIES_TABLE_HEADER}
        keys={AGENCIES_KEYS}
        displaySearch={true}
        visibilityIcon={false}
        displayHeaderComponent={true}
        tbBody={agencies.data}
        rowsPerPage={agencies.perPage}
        total={agencies.total}
        currentPage={agencies.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openFormDialog={openFormDialog}
        openUpdateFormDialog={openUpdateFormDialog}
        openDeleteDialog={openDeleteDialog}
        title={'agencies.title'}
        displayUploadBtn={true}
        displayAddOrApplyBtn={true}
        textBtnAddOrApply={'agencies.create'}
        loading={agencies.loading}
        handleSearch={handleSearch}
        onChangeUploadBtn={handleChangeCsv}
        tbTriBy={AGENCIES_TABLE_TRI_BY}
        fetchDataBy={handleFetchAgenciesBy}
      />
      {/* Dialog To create  agencie */}
      <FormDialog
        openFormDialog={agencies.openDialogForm}
        buttonTitle={'banners.text.button.update'}
        title={'agencies.create'}
        handleCloseFormDialog={handleCloseFormDialog}
      >
        <AgencieForm
          textButton={'agencies.btn.create'}
          handleButton={handleCreateAgencie}
          gourvernorate={clients.gouvernorate}
        />
      </FormDialog>
      {/* Dialog To update  agencie */}
      <FormDialog
        openFormDialog={agencies.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        buttonTitle={'banners.text.button.update'}
        title={'agents.update'}
      >
        <AgencieForm
          textButton={'agencies.btn.update'}
          agencie={data}
          handleButton={handleUpdateAngencie}
          gourvernorate={clients.gouvernorate}
        />
      </FormDialog>
      {/* Dialog To delete agent */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="agencies.delete"
        open={agencies.openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteAgencie}
      />
    </Fragment>
  );
}
const connectedAgenciesPage = withI18n()(Agencies); //Higher-Order Component
export { connectedAgenciesPage as Agencies };

import {
	GET_PROFILES_SUCCESS,
	PROFILE_OPEN_FORM_DIALOG,
	PROFILE_CREATED_SUCCESS,
	PROFILE_FAILED,
	PROFILE_UPADTE_FORM_DIALOG,
	UPDATE_PROFILE_SUCCESS,
	LOADING_PROFILE,
	DELETE_DIALOG_PROFILE,
	DELETED_PROFILE_SUCCESS,
	GET_PROFILES_FAILED,
	LOADING_PROFILES,
	LOAD_MORE_PROFILES_FAILED,
	LOAD_MORE_PROFILES_SUCCESS,
} from "../types";
import { CURRENT_PAGE, PER_PAGE } from "../../helpers";

const INITIAL_STATE = {
	data: [],
	loading: true,
	currentPage: CURRENT_PAGE,
	total: 1,
	perPage: PER_PAGE,
	openDialogForm: false,
	disableButton: false,
	openUpdateDialogForm: false,
	openDeleteDialog: false,
	lastPage: 0,
};

const profilesReducer = (state = INITIAL_STATE, action) => {
	switch (action.type) {
		case GET_PROFILES_SUCCESS:
			return {
				...state,
				data: action.payload.data,
				currentPage: action.payload.current_page,
				total: action.payload.total,
				perPage: Number(action.payload.per_page),
				lastPage: Number(action.payload.last_page),
				loading: false,
			};
		case GET_PROFILES_FAILED:
			return {
				...state,
				loading: false,
			};
		case PROFILE_OPEN_FORM_DIALOG:
			return { ...state, openDialogForm: action.payload };
		case PROFILE_CREATED_SUCCESS:
			return { ...state, openDialogForm: false, disableButton: false };
		case PROFILE_UPADTE_FORM_DIALOG:
			return { ...state, openUpdateDialogForm: action.payload };
		case UPDATE_PROFILE_SUCCESS:
			return { ...state, openUpdateDialogForm: false, disableButton: false };
		case LOADING_PROFILE:
			return { ...state, disableButton: true };
		case PROFILE_FAILED:
			return { ...state, disableButton: false };
		case DELETE_DIALOG_PROFILE:
			return { ...state, openDeleteDialog: action.payload };
		case DELETED_PROFILE_SUCCESS:
			return { ...state, openDeleteDialog: false };
		case LOADING_PROFILES:
			return { ...state, loading: true };
		case LOAD_MORE_PROFILES_FAILED:
			return { ...state, loading: false };
		case LOAD_MORE_PROFILES_SUCCESS:
			return {
				...state,
				data: state.data.concat(action.payload.data),
				currentPage: action.payload.current_page,
				total: action.payload.total,
				lastPage: action.payload.last_page,
				loading: false,
			};
		default:
			return state;
	}
};

export default profilesReducer;

import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoNotificationForm,
  DeleteDialog,
  DynamicTable,
  FormDialog,
  NotificationAddForm,
  NotificationPreview,
} from '../components';
import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  NOTIFICATIONS_TABLE_TRI_BY,
  NOTIFICATION_KEYS,
  NOTIFICATION_TABLE_HEADER,
} from '../helpers';
import {
  changeAddDialogNotification,
  changeConfigDialogNotification,
  changeDeleteDialogNotification,
  changePreviewDialogNotification,
  createNotificationAction,
  deleteNotification,
  fetchAgents,
  fetchAutoNotifications,
  fetchDelegations,
  fetchEndaAgencies,
  fetchEndaProducts,
  fetchGovernorates,
  fetchNotifications,
  updateAutoNotification,
} from '../store/actions';

/**
 * Component Notifications
 *
 * @component
 *
 * @example
 * return (
 *   <Notifications/>
 * )
 */
function Notifications({ t }) {
  const dispatch = useDispatch();
  const notifications = useSelector((state) => state.notifications);
  const endaAgencies = useSelector((state) => state.endaAgencies);
  const endaProducts = useSelector((state) => state.endaProducts);
  const agents = useSelector((state) => state.agents);
  const governorates = useSelector((state) => state.governorates);
  const delegations = useSelector((state) => state.delegations);
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [data, setData] = useState({});
  const [notificationId, setIdNotification] = useState(null);
  const [notificationToPerview, setNotificationToPreview] = useState(null);

  useEffect(() => {
    dispatch(
      fetchNotifications(
        notifications.currentPage,
        notifications.perPage,
        '',
        notifications.orderBy,
        notifications.orderDirection
      )
    );
    dispatch(
      fetchEndaAgencies(
        endaAgencies.currentPage,
        undefined,
        endaAgencies.orderBy,
        endaAgencies.orderDirection
      )
    );
    dispatch(
      fetchEndaProducts(
        endaProducts.currentPage,
        undefined,
        endaProducts.orderBy,
        endaProducts.orderDirection
      )
    );
    dispatch(fetchAgents(agents.currentPage, agents.perPage));
    dispatch(
      fetchGovernorates(
        governorates.currentPage,
        undefined,
        governorates.orderBy,
        governorates.orderDirection
      )
    );
    dispatch(
      fetchDelegations(
        delegations.currentPage,
        undefined,
        delegations.orderBy,
        delegations.orderDirection
      )
    );
  }, []);

  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(fetchNotifications(page, perPage));
  };

  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(fetchNotifications(page, perPage));
  };

  //Open the add dialog
  const openAddDialog = (id) => {
    dispatch(changeAddDialogNotification(true));
  };

  //Close the add dialog
  const handleCloseAddDialog = () => {
    dispatch(fetchEndaAgencies(DEFAULT_PAGE, undefined));
    dispatch(fetchAgents(DEFAULT_PAGE, DEFAULT_PER_PAGE));
    dispatch(
      fetchDelegations(
        1,
        undefined,
        delegations.orderBy,
        delegations.orderDirection
      )
    );
    dispatch(
      fetchGovernorates(
        1,
        undefined,
        governorates.orderBy,
        governorates.orderDirection
      )
    );
    dispatch(
      fetchEndaProducts(
        endaProducts.currentPage,
        undefined,
        endaProducts.orderBy,
        endaProducts.orderDirection
      )
    );
    dispatch(changeAddDialogNotification(false));
  };

  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setIdNotification(id);
    dispatch(changeDeleteDialogNotification(true));
  };

  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialogNotification(false));
  };

  //Close the configuration dialog
  const handleCloseConfigDialog = () => {
    dispatch(changeConfigDialogNotification(false));
  };

  //Open the configuration dialog
  const openConfigDialog = (id) => {
    dispatch(fetchAutoNotifications());

    dispatch(changeConfigDialogNotification(true));
  };

  //Close the preview dialog
  const handleClosePreviewDialog = () => {
    dispatch(changePreviewDialogNotification(false));
  };

  //Open the preview dialog
  const openPreviewDialog = (notification) => {
    setNotificationToPreview(notification);
    dispatch(changePreviewDialogNotification(true));
  };

  //Action to delete notification
  const handleDeleteNotification = () => {
    dispatch(
      deleteNotification(
        notificationId,
        notifications.currentPage,
        notifications.perPage
      )
    );
  };

  //Action to update auto notification
  const updateAutoNotifications = (
    deadlineBefore,
    deadlineAfter,
    cardBefore,
    cardAfter
  ) => {
    dispatch(
      updateAutoNotification(
        deadlineBefore,
        deadlineAfter,
        cardBefore,
        cardAfter
      )
    );
  };

  // create a notification
  const createNotification = (
    filterAgencies,
    filterGovernorates,
    filterDelegations,
    filterProducts,
    minAge,
    maxAge,
    gender,
    isEndaClient,
    title,
    url,
    message,
    toBeSendAt,
    icon,
    image
  ) => {
    dispatch(
      createNotificationAction(
        filterAgencies,
        filterGovernorates,
        filterDelegations,
        filterProducts,
        minAge,
        maxAge,
        gender,
        isEndaClient,
        title,
        url,
        message,
        toBeSendAt,
        icon,
        image,
        notifications.currentPage,
        notifications.perPage
      )
    );
  };

  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('common.title'):
        return 'title';
      default:
        return 'send_date';
    }
  };

  /**
   *  get list of notifications using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchNotificationsBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchNotifications(
        notifications.currentPage,
        notifications.perPage,
        '',
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={NOTIFICATION_TABLE_HEADER}
        keys={NOTIFICATION_KEYS}
        displaySearch={false}
        visibilityIcon={true}
        displayHeaderComponent={true}
        tbBody={notifications.data}
        rowsPerPage={notifications.perPage}
        total={notifications.total}
        currentPage={notifications.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        textBtnAddOrApply="notifications.add"
        displayAddOrApplyBtn={true}
        title={'notifications.title'}
        loading={notifications.loading}
        editIcon={false}
        displayConfigButton={true}
        configButtonText="notifications.config"
        onChangeConfigButton={openConfigDialog}
        openFormDialog={openAddDialog}
        openDeleteDialog={openDeleteDialog}
        handleShowBtn={openPreviewDialog}
        tbTriBy={NOTIFICATIONS_TABLE_TRI_BY}
        fetchDataBy={handleFetchNotificationsBy}
      />

      {/* Dialog To delete notification */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="notifications.delete"
        open={notifications.deleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteNotification}
      />

      {/* Dialog To update auto notifications */}
      <FormDialog
        openFormDialog={notifications.configDialog}
        handleCloseFormDialog={handleCloseConfigDialog}
        buttonTitle={'notifications.text.button.create'}
        title={'notifications.config'}
      >
        <AutoNotificationForm
          loading={notifications.loadingAutoNotifications}
          textButton={'common.save'}
          handleButton={updateAutoNotifications}
          autoNotifications={notifications.autoNotifications}
        />
      </FormDialog>

      {/* Dialog To add a notification */}
      <FormDialog
        openFormDialog={notifications.addDialog}
        handleCloseFormDialog={handleCloseAddDialog}
        buttonTitle={'notifications.text.button.update'}
        title={'notifications.add'}
        maxWidth="xl"
      >
        <NotificationAddForm
          agencies={endaAgencies}
          agents={agents}
          governorates={governorates}
          delegations={delegations}
          textButton={'common.save'}
          handleButton={createNotification}
          products={endaProducts}
        />
      </FormDialog>

      {/* Dialog To preview a notification */}
      <FormDialog
        openFormDialog={notifications.previewDialog}
        handleCloseFormDialog={handleClosePreviewDialog}
        title={'notifications.preview'}
      >
        <NotificationPreview notification={notificationToPerview} />
      </FormDialog>
    </Fragment>
  );
}
const connectedNotificationsPage = withI18n()(Notifications); //Higher-Order Component
export { connectedNotificationsPage as Notifications };

import {
	Button,
	CircularProgress,
	Grid,
	IconButton,
	TextField,
	Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import React, { useEffect, useState } from "react";
import { withI18n } from "react-i18next";
import { useSelector } from "react-redux";
import bannerDefault from "../../assets/img/banner-default.jpg";
import config from "../../config/config";
import {
	ERROR_COLOR,
	FONT_SIZE_LARGE,
	getWidthHeightOfImage,
	INPUT_REQUIRED,
	PRODUCT_IMAGE_MAX_HEIGHT,
	PRODUCT_IMAGE_MAX_WIDTH,
	SECONDARY_COLOR,
	SMALL_SIZE,
	TYPE_OF_IMAGES,
	VARIANT_CONTAINED,
	VARIANT_OUTLINED,
	VARIANT_SUBTITLE_TWO,
} from "../../helpers";
import { useStyles } from "./styles";

/**
 * Component Credit From
 *
 * @component
 *
 * @example
 * return (
 *   <CreditFrom/>
 * )
 */
function CreditFrom({ t, textButton, credit, handleButton }) {
	const [name, setName] = useState(""); // to retrieve the name entered by the user (initial value empty string)
	const [description, setDescription] = useState("");
	const [nameError, setNameError] = useState(""); // to retrieve the name entered by the user (initial value empty string)
	const [descriptionError, setDescriptionError] = useState(""); // state to display the description error
	const [uploadImage, setUploadImage] = useState(false); // state to check if the image is uploaded or not
	const [image, setImage] = useState("");
	const [preViewImage, setPreViewImage] = useState("");
	const [photo, setPhoto] = useState("");
	const [imageError, setImageError] = useState(""); // state to display the image error
	const [amount, setAmount] = useState("");
	const [amountError, setAmountError] = useState(""); // state to display the amount error
	const credits = useSelector((state) => state.credits); //extract state.credits from the Redux store
	const classes = useStyles();

	useEffect(() => {
		//check if the credit props is not empty
		if (credit) {
			//change the default value by the values of Credit
			setName(credit.name);
			setDescription(credit.description);
			setAmount(credit.amount);
			setPhoto(credit.image);
		}
	}, [credit]);

	//Function to validate the inputs
	const validationForm = () => {
		let isValidate = false;
		if (!name) {
			setNameError("products.name.required.error");
			isValidate = true;
		}
		if (!description) {
			setDescriptionError("products.description.required.error");
			isValidate = true;
		}
		if (!amount) {
			setAmountError("credit.amount.required.error");
			isValidate = true;
		}
		if (!image && !photo) {
			setImageError("banners.images.required.error");
			isValidate = true;
		}
		return isValidate;
	};
	//get the name entered by user
	const handleName = (e) => {
		setNameError("");
		setName(e.target.value);
	};
	//get the description entered by user
	const handleDescription = (e) => {
		setDescriptionError("");
		setDescription(e.target.value);
	};
	//get the amount entered by user
	const handleAmount = (e) => {
		setAmountError("");
		setAmount(e.target.value);
	};

	//get the image entered by user
	const _onChangeImage = (e) => {
		const file = e.target.files[0];
		if (file) {
			getWidthHeightOfImage(file).then((res) => {
				setImageError("");
				setPreViewImage("");
				setImage("");
				if (
					res.width > PRODUCT_IMAGE_MAX_WIDTH ||
					res.height > PRODUCT_IMAGE_MAX_HEIGHT
				) {
					setImageError("banners.images.error");
					setUploadImage(false);
				} else {
					setImage(file);
					setUploadImage(true);
					setPreViewImage(URL.createObjectURL(file));
				}
			});
		}
	};
	//handle submit action
	const handleClickButton = (e) => {
		if (!validationForm()) handleButton(name, description, amount, image);
	};
	//Replace img src onError  when the image broken
	const addDefaultSrc = (ev) => {
		ev.target.src = bannerDefault;
	};
	return (
		<div>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						onChange={handleName}
						value={name}
						variant={VARIANT_OUTLINED}
						placeholder={t("credits.placeholder.name")}
						label={t("credits.name") + INPUT_REQUIRED}
						helperText={t(nameError)}
						error={nameError ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						onChange={handleDescription}
						value={description || ""}
						variant={VARIANT_OUTLINED}
						placeholder={t("credits.placeholder.description")}
						label={t("credits.description") + INPUT_REQUIRED}
						multiline
						rows={3}
						helperText={t(descriptionError)}
						error={descriptionError ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<TextField
						fullWidth
						onChange={handleAmount}
						value={amount}
						variant={VARIANT_OUTLINED}
						placeholder={t("credits.placeholder.amount")}
						label={t("credits.amount") + INPUT_REQUIRED}
						helperText={t(amountError)}
						error={amountError ? true : false}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid>
				<Grid item sm={12}>
					<div className={classes.gridDivStyle}>
						<div>
							<Typography variant="h6" component="h2">
								{t("credits.image") + INPUT_REQUIRED}
								{uploadImage && (
									<CheckIcon
										className={classes.checkIconStyle}
										color={SECONDARY_COLOR}
									/>
								)}
							</Typography>
							{imageError && (
								<Typography
									variant={VARIANT_SUBTITLE_TWO}
									component="h2"
									color={ERROR_COLOR}
								>
									{t(imageError)}
								</Typography>
							)}
						</div>
						<input
							onChange={(e) => _onChangeImage(e)}
							accept={TYPE_OF_IMAGES}
							style={{ display: "none" }}
							id="icon-button-file"
							type="file"
						/>
						<label htmlFor="icon-button-file">
							<IconButton component="span">
								<CloudUploadIcon
									fontSize={FONT_SIZE_LARGE}
									color={SECONDARY_COLOR}
								/>
							</IconButton>
						</label>
					</div>
				</Grid>
				{photo || preViewImage ? (
					preViewImage ? (
						<Grid item sm={12} className={classes.gridStyle}>
							<img src={preViewImage} alt="new" />
						</Grid>
					) : (
						<Grid item sm={12} className={classes.gridStyle}>
							<img
								src={`${config.imageUrl}${photo}`}
								alt="new"
								onError={addDefaultSrc}
							/>
						</Grid>
					)
				) : (
					<div></div>
				)}
			</Grid>

			<Button
				className={classes.btnStyle}
				onClick={handleClickButton}
				disabled={credits.disableButton}
				size={SMALL_SIZE}
				variant={VARIANT_CONTAINED}
				color={SECONDARY_COLOR}
			>
				{credits.disableButton && (
					<CircularProgress size={15} color={SECONDARY_COLOR} />
				)}
				{t(textButton)}
			</Button>
		</div>
	);
}
const connectedCreditFrom = withI18n()(CreditFrom); //Higher-Order Component
export { connectedCreditFrom as CreditFrom };

import {
  GET_CREDIT_SUCCESS,
  GET_CREDIT_FAILED,
  CREDITS_OPEN_FORM_DIALOG,
  CREDITS_UPADTE_FORM_DIALOG,
  LOADING_CREDITS,
  CREDITS_CREATED_SUCCESS,
  CREDITS_FAILED,
  UPDATE_CREDITS_SUCCESS,
  DELETED_CREDIT_SUCCESS,
  DELETE_DIALOG_CREDIT,
  GET_CREDIT,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  openDialogForm: false,
  disableButton: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
};

const creditReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CREDIT_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        total: action.payload.total,
        perPage: Number(action.payload.per_page),
        loading: false,
      };
    case GET_CREDIT:
      return { ...state, loading: true };
    case GET_CREDIT_FAILED:
      return { ...state, loading: false };
    case CREDITS_OPEN_FORM_DIALOG:
      return { ...state, openDialogForm: action.payload };
    case CREDITS_UPADTE_FORM_DIALOG:
      return { ...state, openUpdateDialogForm: action.payload };
    case LOADING_CREDITS:
      return { ...state, disableButton: true };
    case CREDITS_CREATED_SUCCESS:
      return { ...state, openDialogForm: false, disableButton: false };
    case UPDATE_CREDITS_SUCCESS:
      return { ...state, openUpdateDialogForm: false, disableButton: false };
    case CREDITS_FAILED:
      return { ...state, disableButton: false };
    case DELETED_CREDIT_SUCCESS:
      return { ...state, openDeleteDialog: false };
    case DELETE_DIALOG_CREDIT:
      return { ...state, openDeleteDialog: action.payload };
    default:
      return state;
  }
};

export default creditReducer;

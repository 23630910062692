import React, { useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useSelector } from 'react-redux';

import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Tooltip,
} from '@material-ui/core';
import { useStyles } from './styles';
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import {
  SECONDARY_COLOR,
  SMALL_SIZE,
  VARIANT_CONTAINED,
  VARIANT_OUTLINED,
  INPUT_REQUIRED,
  POSITION_END,
} from '../../helpers';

/**
 * Component Product Form
 *
 * @component
 *
 * @example
 * return (
 *   <ProductsForm/>
 * )
 */
function ProductsForm({ t, textButton, product, handleButton }) {
  const [name, setName] = useState(''); // to retrieve the name entered by the user (initial value empty string)
  const [description, setDescription] = useState('');
  const [eligibilityCriteria, setEligibilityCriteria] = useState('');
  const [treatmenDelays, setTreatmenDelays] = useState('');
  const [documents, setDocuments] = useState(''); // to retrieve the document entered by the user (initial value empty string)
  const [nameError, setNameError] = useState(''); // state to display the name error
  const [descriptionError, setDescriptionError] = useState(''); // state to display the description error
  const [eligibilityCriteriaError, setEligibilityCriteriaError] = useState('');
  const [treatmenDelaysError, setTreatmenDelaysError] = useState('');
  const [documentsError, setDocumentsError] = useState('');
  const classes = useStyles();

  const products = useSelector((state) => state.products); //extract state.products from the Redux store

  useEffect(() => {
    //check if the product props is not empty
    if (product) {
      //change the default value by the values of product
      setName(product.name);
      setDescription(product.description);
      setEligibilityCriteria(product.eligibility_criteria);
      setTreatmenDelays(product.treatment_delays);
      setDocuments(product.required_documents);
    }
  }, [product]);

  //Function to validate the inputs
  const validationForm = () => {
    let isValidate = false;
    if (!name) {
      setNameError('products.name.required.error');
      isValidate = true;
    }
    if (!description) {
      setDescriptionError('products.description.required.error');
      isValidate = true;
    }
    return isValidate;
  };
  //get the name entered by user
  const handleName = (e) => {
    setNameError('');
    setName(e.target.value);
  };
  //get the description entered by user
  const handleDescription = (e) => {
    setDescriptionError('');
    setDescription(e.target.value);
  };
  //get the eligibility entered by user
  const handleEligibility = (e) => {
    setEligibilityCriteriaError('');
    setEligibilityCriteria(e.target.value);
  };
  //get the treatmentDelays entered by user
  const handleTreatemen = (e) => {
    setTreatmenDelaysError('');
    setTreatmenDelays(e.target.value);
  };
  //get the document s entered by user
  const handleDocument = (e) => {
    setDocumentsError('');
    setDocuments(e.target.value);
  };
  //handle submit form
  const handleClickButton = (e) => {
    if (!validationForm())
      handleButton(
        name,
        description,
        eligibilityCriteria,
        treatmenDelays,
        documents
      );
  };

  return (
    <div>
      <Grid container spacing={3}>
        <Grid item xs={12} sm={12}>
          <TextField
            fullWidth
            onChange={handleName}
            value={name}
            variant={VARIANT_OUTLINED}
            placeholder={t('products.placeholder.name')}
            label={t('products.name') + INPUT_REQUIRED}
            helperText={t(nameError)}
            error={nameError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position={POSITION_END}>
                  <Tooltip interactive title={t('products.text.rules')}>
                    <HelpOutlineRoundedIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={handleDescription}
            value={description || ''}
            variant={VARIANT_OUTLINED}
            placeholder={t('products.placeholder.description')}
            label={t('products.description') + INPUT_REQUIRED}
            multiline
            rows={5}
            helperText={t(descriptionError)}
            error={descriptionError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position={POSITION_END}>
                  <Tooltip interactive title={t('products.text.rules')}>
                    <HelpOutlineRoundedIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={handleEligibility}
            value={eligibilityCriteria || ''}
            variant={VARIANT_OUTLINED}
            multiline
            rows={5}
            placeholder={t('products.placeholder.eligibilityCriteria')}
            label={t('products.eligibilityCriteria')}
            helperText={t(eligibilityCriteriaError)}
            error={eligibilityCriteriaError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position={POSITION_END}>
                  <Tooltip interactive title={t('products.text.rules')}>
                    <HelpOutlineRoundedIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={handleDocument}
            value={documents || ''}
            multiline
            rows={5}
            variant={VARIANT_OUTLINED}
            placeholder={t('products.placeholder.requiredDocuments')}
            label={t('products.requiredDocuments')}
            helperText={t(documentsError)}
            error={documentsError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
        <Grid item sm={12}>
          <TextField
            InputProps={{
              endAdornment: (
                <InputAdornment position={POSITION_END}>
                  <Tooltip interactive title={t('products.text.rules')}>
                    <HelpOutlineRoundedIcon />
                  </Tooltip>
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={handleTreatemen}
            value={treatmenDelays || ''}
            multiline
            rows={5}
            variant={VARIANT_OUTLINED}
            placeholder={t('products.placeholder.treatmentDelays')}
            label={t('products.treatmentDelays')}
            helperText={t(treatmenDelaysError)}
            error={treatmenDelaysError ? true : false}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </Grid>
      </Grid>

      <Button
        className={classes.btnStyle}
        onClick={handleClickButton}
        disabled={products.disableButton}
        size={SMALL_SIZE}
        variant={VARIANT_CONTAINED}
        color={SECONDARY_COLOR}
      >
        {products.disableButton && (
          <CircularProgress size={15} color={SECONDARY_COLOR} />
        )}
        {t(textButton)}
      </Button>
    </div>
  );
}
const connectedProductsForm = withI18n()(ProductsForm); //Higher-Order Component
export { connectedProductsForm as ProductsForm };

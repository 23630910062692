import {
  GET_BANNERS_SUCCESS,
  BANNERS_OPEN_FORM_DIALOG,
  BANNERS_CREATED_SUCCESS,
  BANNERS_UPADTE_FORM_DIALOG,
  UPDATE_BANNERS_SUCCESS,
  DELETE_DIALOG_BANNER,
  DELETED_BANNER_SUCCESS,
  BANNERS_FAILED,
  LOADING_BANNER,
  URL_ERROR,
  GET_BANNERS_FAILED,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  openDialogForm: false,
  disableButton: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
  errorURL: '',
};

const bannersReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_BANNER: {
      return { ...state, disableButton: true };
    }
    case GET_BANNERS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        perPage: Number(action.payload.per_page),
        total: action.payload.total,
        loading: false,
      };
    case GET_BANNERS_FAILED:
      return {
        ...state,
        loading: false,
      };
    case BANNERS_OPEN_FORM_DIALOG:
      return { ...state, openDialogForm: action.payload, errorURL: '' };
    case BANNERS_CREATED_SUCCESS:
      return { ...state, openDialogForm: false, disableButton: false };
    case BANNERS_FAILED:
      return { ...state, disableButton: false };

    case BANNERS_UPADTE_FORM_DIALOG:
      return { ...state, openUpdateDialogForm: action.payload, errorURL: '' };
    case UPDATE_BANNERS_SUCCESS:
      return {
        ...state,
        openUpdateDialogForm: false,
        data: state.data.map((item) =>
          item.id === action.payload.id ? { ...item, ...action.payload } : item
        ),
        disableButton: false,
      };
    case DELETE_DIALOG_BANNER:
      return { ...state, openDeleteDialog: action.payload };
    case DELETED_BANNER_SUCCESS:
      return {
        ...state,
        openDeleteDialog: false,
        data: state.data.filter((item) => item.id !== action.payload),
        total: state.total - 1,
      };
    case URL_ERROR:
      return { ...state, errorURL: action.payload };
    default:
      return state;
  }
};

export default bannersReducer;

import {
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAILED,
  CLIENTS_UPADTE_FORM_DIALOG,
  GET_GOUVERNORATE_SUCCESS,
  GET_DELEGATION_OF_GOUVERNORAT_SUCCESS,
  GET_DELEGATION_OF_GOUVERNORAT,
  UPDATE_CLIENT_SUCCESS,
  UPDATE_CLIENT,
  UPDATE_CLIENT_FAILED,
  CHANGE_CLIENT_DELETE_DIALOG,
  DELETED_CLIENT_SUCCESS,
  CHANGE_CLIENT_LOADING_DELEGATION,
  CHANGE_DELEGATION_TO_EMPTY,
} from '../types';
import { CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  openDialogForm: false,
  disableButton: false,
  openUpdateDialogForm: false,
  openDeleteDialog: false,
  errorURL: '',
  gouvernorate: [],
  delegation: [],
  loadigDelegation: false,
};

const clientsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        total: action.payload.total,
        perPage: Number(action.payload.per_page),
        loading: false,
      };
    case GET_CLIENTS_FAILED:
      return { ...state, loading: false };
    case CLIENTS_UPADTE_FORM_DIALOG:
      return { ...state, openUpdateDialogForm: action.payload };
    case GET_GOUVERNORATE_SUCCESS:
      return { ...state, gouvernorate: action.payload };
    case GET_DELEGATION_OF_GOUVERNORAT:
      return { ...state, loadigDelegation: false };
    case GET_DELEGATION_OF_GOUVERNORAT_SUCCESS:
      return { ...state, delegation: action.payload, loadigDelegation: true };
    case UPDATE_CLIENT:
      return { ...state, disableButton: true };
    case UPDATE_CLIENT_SUCCESS:
      return {
        ...state,
        openUpdateDialogForm: false,
        disableButton: false,
      };
    case UPDATE_CLIENT_FAILED:
      return { ...state, disableButton: false };
    case CHANGE_CLIENT_DELETE_DIALOG:
      return { ...state, openDeleteDialog: action.payload };
    case CHANGE_CLIENT_LOADING_DELEGATION:
      return { ...state, loadigDelegation: action.payload };
    case DELETED_CLIENT_SUCCESS:
      return {
        ...state,
        openDeleteDialog: false,
        data: state.data.filter((item) => item.id !== action.payload),
        total: state.total - 1,
      };
    case CHANGE_DELEGATION_TO_EMPTY:
      return { ...state, delegation: [], loadigDelegation: false };
    default:
      return state;
  }
};

export default clientsReducer;

import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
import { authentication } from "./authReducer";
import { ui } from "./uiReducer";
import userReducer from "./userReducer";
import bannersReducer from "./bannersReducer";
import clientReducer from "./clientsReducer";
import productsReducer from "./productsReducer";
import creditReducer from "./creditReducer";
import agentsReducer from "./agentReducer";
import infoRequestReducer from "./infoRequestReducer";
import agenciesReducer from "./agenciesReducer";
import claimReducer from "./claimReducer";
import notificationReducer from "./notificationReducer";
import resetPasswordReducer from "./resetPasswordReducer";
import infoPanelReducer from "./infoPanelReducer";
import governorateReducer from "./governorateReducer";
import delegationReducer from "./delegationReducer";
import endaAgencyReducer from "./endaAgencyReducer";
import endaProductReducer from "./endaProductReducer";
import inscriptionStatisticsReducer from "./inscriptionStatisticsReducer";
import claimPanelReducer from "./claimPanelReducer";
import CreditRequestStatisticReducer from "./creditRequestStatisticReducer";
import downloadReducer from "./downloadReducer";
import profileReducer from "./profileReducer";
import sectionsReducer from "./sectionsReducer";

const rootReducer = (history) =>
	combineReducers({
		users: userReducer,
		router: connectRouter(history),
		authentication,
		ui,
		banners: bannersReducer,
		clients: clientReducer,
		products: productsReducer,
		credits: creditReducer,
		agents: agentsReducer,
		infoRequest: infoRequestReducer,
		agencies: agenciesReducer,
		claim: claimReducer,
		notifications: notificationReducer,
		resetPassword: resetPasswordReducer,
		infoPanel: infoPanelReducer,
		governorates: governorateReducer,
		delegations: delegationReducer,
		endaAgencies: endaAgencyReducer,
		endaProducts: endaProductReducer,
		inscriptionStatistics: inscriptionStatisticsReducer,
		claimPanel: claimPanelReducer,
		creditRequestStatistics: CreditRequestStatisticReducer,
		download: downloadReducer,
		profiles: profileReducer,
		sections: sectionsReducer,
	});

export default rootReducer;

import React from "react";
import {
	ResetPasswordPanel,
	InfoRequestPanel,
	ClaimRequestPanel,
	InscriptionStatisticsPanel,
	CreditRequestStatisticsPanel,
} from "../components";

export const dashboardPanels = [
	{
		id: 10,
		index: 0,
		title: "dashboard.inscription",
		panelComponent: <InscriptionStatisticsPanel />,
	},
	{
		id: 11,
		index: 1,
		title: "dashboard.reset.password",
		panelComponent: <ResetPasswordPanel />,
	},
	{
		id: 12,
		index: 2,
		title: "dashboard.credit.request",
		panelComponent: <CreditRequestStatisticsPanel />,
	},
	{
		id: 13,
		index: 3,
		title: "dashboard.info-request",
		panelComponent: <InfoRequestPanel />,
	},
	{
		id: 14,
		index: 4,
		title: "dashboard.claim",
		panelComponent: <ClaimRequestPanel />,
	},
];

import {
  GET_DELEGATION_ERROR,
  GET_DELEGATION_SUCCESS,
  LOADING_DELEGATIONS,
  LOAD_MORE_DELEGATION_SUCCESS,
  LOAD_MORE_DELEGATION_ERROR,
} from '../types';

import { ORDER_DIRECTION_ASC, CURRENT_PAGE, PER_PAGE } from '../../helpers';

const INITIAL_STATE = {
  data: [],
  loading: true,
  currentPage: CURRENT_PAGE,
  total: 1,
  perPage: PER_PAGE,
  lastPage: 1,
  orderBy: 'description',
  orderDirection: ORDER_DIRECTION_ASC,
};

const delegationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADING_DELEGATIONS:
      return {
        ...state,
        loading: true,
      };
    case GET_DELEGATION_SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        currentPage: action.payload.current_page,
        total: action.payload.total,
        perPage: Number(action.payload.per_page),
        lastPage: action.payload.last_page,
        loading: false,
      };
    case LOAD_MORE_DELEGATION_SUCCESS:
      return {
        ...state,
        data: state.data.concat(action.payload.data),
        currentPage: action.payload.current_page,
        total: action.payload.total,
        perPage: Number(action.payload.per_page),
        lastPage: action.payload.last_page,
        loading: false,
      };
    case GET_DELEGATION_ERROR:
      return { ...state, loading: false };
    case LOAD_MORE_DELEGATION_ERROR:
      return { ...state, loading: false };

    default:
      return state;
  }
};

export default delegationReducer;

import { LOADIGN_DOWNLOAD, DOWNLOAD_ACTION } from '../types';

const INITIAL_STATE = {
  loading: false,
};

const downloadReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOADIGN_DOWNLOAD:
      return { ...state, loading: true };
    case DOWNLOAD_ACTION:
      return { ...state, loading: false };
    default:
      return state;
  }
};

export default downloadReducer;

import {
  BEGIN_AT,
  ENDS_AT,
  getFirstErrorFromResponse,
  PREFIX_ADMIN,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';
import { methodsServices } from '../services';
import { DOWNLOAD_ACTION, LOADIGN_DOWNLOAD, OPEN_SNACKBAR } from '../types';

/**
 * Action to download File
 *
 * @param {integer} startAt
 * @param {integer} endAt
 * @param {integer} endPoint
 *
 * @returns function contains an action
 */
export function downloadFile(startAt, endAt, endPoint) {
  return (dispatch) => {
    dispatch({ type: LOADIGN_DOWNLOAD });

    let apiEndpoint = `${PREFIX_ADMIN}${endPoint}`;
    if (startAt) {
      apiEndpoint = `${apiEndpoint}${BEGIN_AT}${startAt}${ENDS_AT}${endAt}`;
    }
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: DOWNLOAD_ACTION,
        });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'common.export.success',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: DOWNLOAD_ACTION,
        });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
      });
  };
}

import React from "react";
import { withI18n } from "react-i18next";
import { Line } from "react-chartjs-2";
import { Paper, CircularProgress } from "@material-ui/core";
import { useStyles } from "./styles";
import { SECONDARY_COLOR } from "../../helpers";

/**
 * Component LineChart
 *
 * @component
 *
 * @example
 * return (
 *   <LineChart/>
 * )
 */
function LineChart({ t, data, options, loading }) {
	const classes = useStyles();
	if (!loading)
		return (
			<Paper elevation={1} className={classes.conatiner}>
				<Line data={data} options={options} />
			</Paper>
		);
	else
		return (
			<Paper elevation={1} className={classes.conatiner}>
				<CircularProgress
					color={SECONDARY_COLOR}
					className={classes.circularProgressStyle}
				/>
			</Paper>
		);
}

const connectedLineChart = withI18n()(LineChart); //Higher-Order Component
export { connectedLineChart as LineChart };

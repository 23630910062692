import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useLocation } from 'react-router-dom';
import {
  DeleteDialog,
  DynamicTable,
  FormDialog,
  CreditFrom,
} from '../components';
import {
  CREDIT_TABLE_HEADER,
  CREDIT_KEYS,
  CREDIT_TABLE_TRI_BY,
} from '../helpers/constantes';
import {
  createCredit,
  getCreditRelatedPack,
  updateCredit,
  creditFormDialog,
  creditsUpdateFormDialog,
  deleteCredit,
  changeDeleteDialogCredit,
} from '../store/actions';

/**
 * Component Credit
 *
 * @component
 *
 * @example
 * return (
 *   <Credit/>
 * )
 */
function Credit({ t }) {
  const dispatch = useDispatch();
  const [data, setData] = useState({});
  const params = useParams();
  const location = useLocation();
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  const [creditId, setCreditID] = useState(null);
  const credits = useSelector((state) => state.credits);

  useEffect(() => {
    dispatch(
      getCreditRelatedPack(
        params.id,
        credits.currentPage,
        credits.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  }, []);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      getCreditRelatedPack(
        params.id,
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      getCreditRelatedPack(
        params.id,
        page,
        perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //Close the Form dialog  (add new credit )
  const handleCloseFormDialog = () => {
    dispatch(creditFormDialog(false));
  };
  //Open the Form dialog(add new credit)
  const openFormDialog = () => {
    dispatch(creditFormDialog(true));
  };
  //Function to create a new credit
  const handleCreateCredit = (name, description, amount, image) => {
    dispatch(
      createCredit(
        name,
        description,
        amount,
        image,
        params.id,
        credits.currentPage,
        credits.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //Function to upadate a credit
  const handleUpdateProduct = (name, description, amount, image) => {
    dispatch(
      updateCredit(
        name,
        description,
        amount,
        image,
        params.id,
        data.id,
        credits.currentPage,
        credits.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };

  //open the upadate form dialog
  const openUpdateFormDialog = (credit) => {
    setData(credit);
    dispatch(creditsUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(creditsUpdateFormDialog(false));
  };
  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setCreditID(id);
    dispatch(changeDeleteDialogCredit(true));
  };
  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialogCredit(false));
  };
  //Action to delete credit
  const handleDeleteProdcut = () => {
    dispatch(
      deleteCredit(
        creditId,
        params.id,
        credits.currentPage,
        credits.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('credits.list'):
        return CREDIT_KEYS[0];
      case t('common.createdAt'):
        return CREDIT_KEYS[1];

      default:
        return CREDIT_KEYS[0];
    }
  };

  /**
   *  get list of banners using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchCreditsBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      getCreditRelatedPack(
        params.id,
        credits.currentPage,
        credits.perPage,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={CREDIT_TABLE_HEADER}
        keys={CREDIT_KEYS}
        visibilityIcon={false}
        displayHeaderComponent={true}
        tbBody={credits.data}
        rowsPerPage={credits.perPage}
        total={credits.total}
        currentPage={credits.currentPage}
        displayAddOrApplyBtn={true}
        openFormDialog={openFormDialog}
        openUpdateFormDialog={openUpdateFormDialog}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openDeleteDialog={openDeleteDialog}
        textBtnAddOrApply="credits.addNew"
        title={'products.title'}
        name={location.state ? location.state.name : ''}
        loading={credits.loading}
        tbTriBy={CREDIT_TABLE_TRI_BY}
        fetchDataBy={handleFetchCreditsBy}
      />
      {/* Dialog To create new credit */}
      <FormDialog
        openFormDialog={credits.openDialogForm}
        handleCloseFormDialog={handleCloseFormDialog}
        buttonTitle={'credits.addNew'}
        title={'credits.addNew'}
      >
        <CreditFrom
          textButton={'credits.create'}
          handleButton={handleCreateCredit}
        />
      </FormDialog>
      {/* Dialog To update credit */}
      <FormDialog
        openFormDialog={credits.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        buttonTitle={'banners.text.button.update'}
        title={'users.update'}
      >
        <CreditFrom
          textButton={'products.text.button.update'}
          credit={data}
          handleButton={handleUpdateProduct}
        />
      </FormDialog>
      {/* Dialog To delete credit */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="credits.delete"
        open={credits.openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteProdcut}
      />
    </Fragment>
  );
}
const connectedCreditPage = withI18n()(Credit); //Higher-Order Component
export { connectedCreditPage as Credit };

import {
  DEFAULT_ORDER_DIRECTION,
  getFirstErrorFromResponse,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';
import {
  ENDPOINT_BANNER,
  ORDER_BY,
  ORDER_DIRECTION,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  BANNERS_CREATED_SUCCESS,
  BANNERS_FAILED,
  BANNERS_OPEN_FORM_DIALOG,
  BANNERS_UPADTE_FORM_DIALOG,
  CHANGE_DISABLED_DIALOG,
  DELETED_BANNER_SUCCESS,
  DELETE_DIALOG_BANNER,
  GET_BANNERS_FAILED,
  GET_BANNERS_SUCCESS,
  LOADING_BANNER,
  OPEN_SNACKBAR,
  UPDATE_BANNERS_SUCCESS,
  URL_ERROR,
} from '../types';

/**
 * Action Creator to get all banners
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchBanners(
  page = 1,
  perPage = 5,
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_BANNER}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;

    if (orderBy) {
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    }
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_BANNERS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_BANNERS_FAILED });
      });
  };
}
/**
 * Action Creator to create a new banner
 *
 * @param {String} title
 * @param {String} subtitle
 * @param {String} urlRedirection
 * @param {File} image
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function createBanner(
  title,
  subtitle,
  urlRedirection,
  image,
  page,
  perPage,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_BANNER}`;
    let formData = new FormData();
    formData.append('title', title);
    formData.append('sub_title', subtitle);
    formData.append('url', urlRedirection);
    formData.append('image', image);
    dispatch({ type: LOADING_BANNER });
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchBanners(page, perPage, orderBy, orderDirection));
        dispatch({ type: BANNERS_CREATED_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'banners.created',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: BANNERS_FAILED });
      });
  };
}
/**
 * Action Creator to update banner
 *
 * @param {String} title
 * @param {String} subtitle
 * @param {String} urlRedirection
 * @param {File} image
 * @param {int} image
 *
 * @returns function contains an action
 */
export function updateBanner(
  title,
  subtitle,
  urlRedirection,
  image,
  id,
  page,
  perPage,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    dispatch({ type: LOADING_BANNER });
    let formData = new FormData();
    formData.append('title', title);
    formData.append('sub_title', subtitle);
    formData.append('url', urlRedirection);

    if (image) {
      formData.append('image', image);
    }
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_BANNER}/${id}`;
    dispatch({ type: LOADING_BANNER });
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchBanners(page, perPage, orderBy, orderDirection));
        dispatch({ type: UPDATE_BANNERS_SUCCESS, payload: response.data.data });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'users.uptated',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: BANNERS_FAILED });
      });
  };
}
/**
 * Action Creator to delete banner and call fetchBanners action to reload data
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export const deleteBanner = (
  id,
  currentPage,
  perPage,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_BANNER}/${id}`;
    methodsServices
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(fetchBanners(currentPage, perPage, orderBy, orderDirection));
        dispatch({ type: DELETED_BANNER_SUCCESS, payload: id });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'banners.deleted',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      });
  };
};
/**
 * Action Creator to open and close the Form dialog (banners)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const bannersFormDialog = (value) => {
  return {
    type: BANNERS_OPEN_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the delete dialog (banners)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogBanner = (value) => {
  return {
    type: DELETE_DIALOG_BANNER,
    payload: value,
  };
};
/**
 * Action Creator to open and close the update dialog (banners)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const bannersUpdateFormDialog = (value) => {
  return {
    type: BANNERS_UPADTE_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to handle the error of URL
 *
 * @param {string} value
 *
 * @returns an action
 */
export const errorURL = (value) => {
  return {
    type: URL_ERROR,
    payload: value,
  };
};

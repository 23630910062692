import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteDialog, DynamicTable, FormDialog } from '../components';
import { ClientsForm } from '../components/clientsForm/ClientsForm';
import {
  CLIENTS_KEYS,
  CLIENTS_TABLE_HEADER,
  CLIENT_TABLE_TRI_BY,
} from '../helpers/constantes';
import {
  bannersFormDialog,
  ClientUpdateFormDialog,
  fetchClients,
  getGovernorate,
  updateClient,
  changeDeleteDialogClient,
  deleteClient,
} from '../store/actions';

/**
 * Component Clients
 *
 * @component
 *
 * @example
 * return (
 *   <Clients/>
 * )
 */
function Clients({ t }) {
  const dispatch = useDispatch();
  const clients = useSelector((state) => state.clients);
  const [data, setData] = useState({});
  const [bannerId, setIdBanner] = useState(null);
  const [search, setSearch] = useState('');
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  useEffect(() => {
    dispatch(fetchClients(clients.currentPage, clients.perPage, search));
    if (clients.gouvernorate.length === 0) dispatch(getGovernorate());
  }, []);
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(
      fetchClients(page, perPage, search, tableOrderBy, tableOrderDirection)
    );
  };
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(
      fetchClients(page, perPage, search, tableOrderBy, tableOrderDirection)
    );
  };
  const handleSearch = (message) => {
    setSearch(message);
    dispatch(
      fetchClients(
        clients.currentPage,
        clients.perPage,
        message,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };

  //Open the Form dialog(add new user)
  const openFormDialog = () => {
    dispatch(bannersFormDialog(true));
  };

  //Function to upadate a user
  const handleUpdateClient = (
    firstName,
    lastName,
    id,
    phone,
    deleg,
    address,
    email,
    secondPhone,
    codeP
  ) => {
    dispatch(
      updateClient(
        firstName,
        lastName,
        id,
        phone,
        deleg,
        address,
        email,
        secondPhone,
        codeP,
        clients.currentPage,
        clients.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (client) => {
    setData(client);
    dispatch(ClientUpdateFormDialog(true));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
    dispatch(ClientUpdateFormDialog(false));
  };
  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setIdBanner(id);
    dispatch(changeDeleteDialogClient(true));
  };
  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialogClient(false));
  };
  //Action to delete user
  const handleDeleteClient = () => {
    dispatch(
      deleteClient(
        bannerId,
        clients.currentPage,
        clients.perPage,
        search,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('clients.userName'):
        return CLIENTS_KEYS[0];
      case t('clients.firstName'):
        return CLIENTS_KEYS[1];
      default:
        return CLIENTS_KEYS[2];
    }
  };

  /**
   *  get list of clients using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchClientsBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);
    dispatch(
      fetchClients(
        clients.currentPage,
        clients.perPage,
        search,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={CLIENTS_TABLE_HEADER}
        keys={CLIENTS_KEYS}
        displaySearch={true}
        visibilityIcon={false}
        displayHeaderComponent={true}
        tbBody={clients.data}
        rowsPerPage={clients.perPage}
        total={clients.total}
        currentPage={clients.currentPage}
        changeRowsPerPage={changeRowsPerPage}
        changePage={handleChangePage}
        openFormDialog={openFormDialog}
        openUpdateFormDialog={openUpdateFormDialog}
        openDeleteDialog={openDeleteDialog}
        title={'clients.title'}
        loading={clients.loading}
        handleSearch={handleSearch}
        tbTriBy={CLIENT_TABLE_TRI_BY}
        fetchDataBy={handleFetchClientsBy}
      />

      {/* Dialog To update  client */}
      <FormDialog
        openFormDialog={clients.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        buttonTitle={'banners.text.button.update'}
        title={'clients.update'}
      >
        <ClientsForm
          textButton={'clients.update'}
          client={data}
          gourvernorate={clients.gouvernorate}
          handleButton={handleUpdateClient}
        />
      </FormDialog>
      {/* Dialog To delete client */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="clients.delete"
        open={clients.openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteClient}
      />
    </Fragment>
  );
}
const connectedClientsPage = withI18n()(Clients); //Higher-Order Component
export { connectedClientsPage as Clients };

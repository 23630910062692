import {
  DEFAULT_PAGE,
  DEFAULT_PER_PAGE,
  ENDPOINT_PAGINATED_GOUVERNORATE,
  getFirstErrorFromResponse,
  ORDER_BY,
  ORDER_DIRECTION,
  ORDER_DIRECTION_ASC,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
  SEARCH,
  SERVERITY_ERROR,
} from '../../helpers';
import { methodsServices } from '../services';
import {
  GET_GOVERNORATE_ERROR,
  GET_GOVERNORATE_SUCCESS,
  LOADING_GOVERNORATES,
  LOAD_MORE_GOVERNORATE_ERROR,
  LOAD_MORE_GOVERNORATE_SUCCESS,
  OPEN_SNACKBAR,
} from '../types';

/**
 * Action to get all governorates
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchGovernorates(
  page = DEFAULT_PAGE,
  perPage = 15,
  orderBy,
  orderDirection,
  search = ''
) {
  return (dispatch) => {
    dispatch({
      type: LOADING_GOVERNORATES,
    });

    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PAGINATED_GOUVERNORATE}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${SEARCH}${search}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;

    methodsServices
      .get(apiEndpoint, true)
      .then((response) => {
        dispatch({
          type: GET_GOVERNORATE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: GET_GOVERNORATE_ERROR,
        });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: 'error',
        });
      });
  };
}

/**
 * Action to load more governorates
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function loadMoreGovernoratesAction(
  page = DEFAULT_PAGE,
  perPage = 15,
  orderBy = 'description',
  orderDirection = ORDER_DIRECTION_ASC
) {
  return (dispatch) => {
    dispatch({
      type: LOADING_GOVERNORATES,
    });

    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PAGINATED_GOUVERNORATE}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;

    methodsServices
      .get(apiEndpoint, true)
      .then((response) => {
        dispatch({
          type: LOAD_MORE_GOVERNORATE_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: LOAD_MORE_GOVERNORATE_ERROR,
        });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
      });
  };
}

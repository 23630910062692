import React, { Fragment, useEffect, useState } from 'react';
import { withI18n } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  DeleteDialog,
  DynamicTable,
  FormDialog,
  UserForm,
} from '../components';
import {
  USER_KEYS,
  USER_TABLE_HEADER,
  USER_TABLE_TRI_BY,
} from '../helpers/constantes';
import {
	changeDeleteDialog,
	changeFormDialog,
	changeUpdateFormDialog,
	createUser,
	deleteUser,
	fetchUser,
	updateUser,
	emptyUserError,
} from "../store/actions/userAction";

/**
 * Component Users
 *
 * @component
 *
 * @example
 * return (
 *   <Users/>
 * )
 */
function Users({ t }) {
  const dispatch = useDispatch();
  const users = useSelector((state) => state.users);
  const [data, setData] = useState({});
  const [idUser, setIdUser] = useState({});
  const [tableOrderDirection, setTableOrderDirection] = useState('');
  const [tableOrderBy, setTableOrderBy] = useState('');
  //when the user change the page
  const handleChangePage = (page, perPage) => {
    dispatch(fetchUser(page, perPage, tableOrderBy, tableOrderDirection));
  };
  //Function to create a new user
  const handleCreateUser = (
    firstName,
    lastName,
    email,
    password,
    rePassword,
    profileId
  ) => {
    dispatch(
			createUser(
				firstName,
				lastName,
				email,
				password,
				rePassword,
				profileId,
				users.currentPage,
				users.perPage,
				tableOrderBy,
				tableOrderDirection
			)
		);
  };
  //Function to upadate a user
  const handleUpdateUser = (
		firstName,
		lastName,
		email,
		password,
		rePassword,
		profileId,
		id
	) => {
		dispatch(
			updateUser(
				firstName,
				lastName,
				email,
				password,
				rePassword,
				profileId,
				id,
				users.currentPage,
				users.perPage,
				tableOrderBy,
				tableOrderDirection
			)
		);
	};
  //When the user change Row per page
  const changeRowsPerPage = (page, perPage) => {
    dispatch(fetchUser(page, perPage, tableOrderBy, tableOrderDirection));
  };
  //Close the Form dialog  (add new user)
  const handleCloseFormDialog = () => {
    dispatch(emptyUserError());
    dispatch(changeFormDialog(false));
  };
  //Open the Form dialog(add new user)
  const openFormDialog = () => {
    dispatch(changeFormDialog(true));
  };
  //Open the delete dialog
  const openDeleteDialog = (id) => {
    setIdUser(id);
    dispatch(changeDeleteDialog(true));
  };
  //Close the delete dialog
  const handleCloseDeleteDialog = () => {
    dispatch(changeDeleteDialog(false));
  };
  //Close the upadate form dialog
  const handleCloseUpdateFormDialog = () => {
     dispatch(emptyUserError());
    dispatch(changeUpdateFormDialog(false));
  };
  //open the upadate form dialog
  const openUpdateFormDialog = (user) => {
    setData(user);
    dispatch(changeUpdateFormDialog(true));
  };
  //get the list of user
  useEffect(() => {
    dispatch(
      fetchUser(
        users.currentPage,
        users.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  }, []);
  //Action to delete user
  const handleDeleteUser = () => {
    dispatch(
      deleteUser(
        idUser,
        users.currentPage,
        users.perPage,
        tableOrderBy,
        tableOrderDirection
      )
    );
  };
  /**
   * get the name of column related to orderBy
   * @param {string} orderBy
   *
   */
  const getColumnName = (orderBy) => {
    switch (orderBy) {
      case t('users.firstName'):
        return USER_KEYS[0];
      case t('users.lastName'):
        return USER_KEYS[1];
      default:
        return USER_KEYS[0];
    }
  };

  /**
   *  get list of banners using orderBy and orderDirection
   * @param {string} orderBy
   * @param {string} orderDirection
   */
  const handleFetchUsersBy = (orderBy, orderDirection) => {
    setTableOrderBy(getColumnName(orderBy));
    setTableOrderDirection(orderDirection);

    dispatch(
      fetchUser(
        users.currentPage,
        users.perPage,
        getColumnName(orderBy),
        orderDirection
      )
    );
  };
  return (
    <Fragment>
      <DynamicTable
        tbHeader={USER_TABLE_HEADER}
        keys={USER_KEYS}
        visibilityIcon={false}
        tbBody={users.data}
        displayHeaderComponent={true}
        displayAddOrApplyBtn={true}
        rowsPerPage={users.perPage}
        total={users.total}
        currentPage={users.currentPage}
        changePage={handleChangePage}
        changeRowsPerPage={changeRowsPerPage}
        textBtnAddOrApply="user.text.button.create"
        openFormDialog={openFormDialog}
        openUpdateFormDialog={openUpdateFormDialog}
        openDeleteDialog={openDeleteDialog}
        title={'users.title'}
        IdOfAdminConnected={localStorage.getItem('idUserConnected')}
        loading={users.loading}
        tbTriBy={USER_TABLE_TRI_BY}
        fetchDataBy={handleFetchUsersBy}
        userEdit={true}
      /> 
      {/* Dialog To create user */}
      <FormDialog
        openFormDialog={users.openDialogForm}
        handleCloseFormDialog={handleCloseFormDialog}
        buttonTitle={'users.button.create'}
        title={'user.text.button.create'}
      >
        <UserForm
          passwordRequired={true}
          textButton={'user.text.button.create'}
          handleButton={handleCreateUser}
        />
      </FormDialog>
      {/* Dialog To update user */}
      <FormDialog
        openFormDialog={users.openUpdateDialogForm}
        handleCloseFormDialog={handleCloseUpdateFormDialog}
        buttonTitle={'user.text.button.create'}
        title={'users.update'}
      >
        <UserForm
          passwordRequired={false}
          textButton={'user.text.button.update'}
          user={data}
          handleButton={handleUpdateUser}
        />
      </FormDialog>
      {/* Dialog To delete user */}
      <DeleteDialog
        dialogTitle="common.delete"
        dialogContent="users.delete"
        open={users.openDeleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleClickDelete={handleDeleteUser}
      />
    </Fragment>
  );
}
const connectedUsersPage = withI18n()(Users); //Higher-Order Component
export { connectedUsersPage as Users };

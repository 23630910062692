import {
	getFirstErrorFromResponse,
	ERROR_NETWORK,
	DEFAULT_ORDER_DIRECTION,
	SERVERITY_ERROR,
	SERVERITY_SUCCESS,
	DEFAULT_PAGE,PAGE,
	ENDPOINT_PER_PAGE,
	PREFIX_ADMIN,
	ENDPOINT_PROFILE,
	ORDER_DIRECTION,
	ORDER_BY,
} from "../../helpers";
import { methodsServices } from "../services";
import {
	GET_PROFILES_FAILED,
	GET_PROFILES_SUCCESS,
	OPEN_SNACKBAR,
	PROFILE_OPEN_FORM_DIALOG,
	LOADING_PROFILE,
	PROFILE_CREATED_SUCCESS,
	PROFILE_FAILED,
	PROFILE_UPADTE_FORM_DIALOG,
	UPDATE_PROFILE_SUCCESS,
	DELETE_DIALOG_PROFILE,
	CHANGE_DISABLED_DIALOG,
	DELETED_PROFILE_SUCCESS,
	LOADING_PROFILES,
	LOAD_MORE_PROFILES_FAILED,
	LOAD_MORE_PROFILES_SUCCESS,
} from "../types";

/**
 * Action Creator to get all profiles
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchProfiles(
	page = 1,
	perPage = 5,
	orderBy = "created_at",
	orderDirection = DEFAULT_ORDER_DIRECTION
) {
	return (dispatch) => {
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PROFILE}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;
		if (orderBy)
			apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
		methodsServices
			.get(apiEndpoint)
			.then((response) => {
				dispatch({
					type: GET_PROFILES_SUCCESS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: GET_PROFILES_FAILED });
			});
	};
}
/**
 * Action Creator to create a new profile
 *
 * @param {String} name
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function createProfile(
	name,
	sections,
	page,
	perPage,
	orderBy,
	orderDirection
) {
	return (dispatch) => {
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PROFILE}`;
		let formData = new FormData();
		formData.append("name", name);

		sections.forEach((section, index) => {
			formData.append("sections[" + index + "]", section);
		});

		dispatch({ type: LOADING_PROFILE });
		methodsServices
			.post(apiEndpoint, formData, true)
			.then(() => {
				dispatch({ type: PROFILE_CREATED_SUCCESS });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "profiles.created",
					severity: SERVERITY_SUCCESS,
				});
				dispatch(fetchProfiles(page, perPage, orderBy, orderDirection));
			})
			.catch((error) => {
				dispatch({ type: PROFILE_FAILED });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: PROFILE_FAILED });
			});
	};
}
/**
 * Action Creator to update profile
 *
 * @param {String} name
 * @param {int} id
 *
 * @returns function contains an action
 */
export function updateProfile(
	name,
	sections,
	id,
	page,
	perPage,
	orderBy,
	orderDirection
) {
	return (dispatch) => {
		let formData = new FormData();
		formData.append("name", name);
		sections.forEach((section, index) => {
			formData.append("sections[" + index + "]", section);
		});

		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PROFILE}/${id}`;
		dispatch({ type: LOADING_PROFILE });
		methodsServices
			.post(apiEndpoint, formData, true)
			.then((response) => {
				dispatch(fetchProfiles(page, perPage, orderBy, orderDirection));
				dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: response.data.data });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "profiles.uptated",
					severity: SERVERITY_SUCCESS,
				});
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});

				dispatch({ type: PROFILE_FAILED });
			});
	};
}
/**
 * Action Creator to delete profile
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export const deleteProfile = (
	id,
	currentPage,
	perPage,
	orderBy,
	orderDirection
) => {
	return (dispatch) => {
		dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PROFILE}/${id}`;
		methodsServices
			.deleteDetail(apiEndpoint)
			.then((response) => {
				dispatch(fetchProfiles(currentPage, perPage, orderBy, orderDirection));
				dispatch({ type: DELETED_PROFILE_SUCCESS, payload: id });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "profiles.deleted",
					severity: SERVERITY_SUCCESS,
				});
				dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
			});
	};
};

/**
 * Action Creator to load more agencies
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function loadMoreProfiles(
	page = DEFAULT_PAGE,
	perPage = 10,
	orderBy = "created_at",
	orderDirection = DEFAULT_ORDER_DIRECTION
) {
	return (dispatch) => {
		dispatch({
			type: LOADING_PROFILES,
		});
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PROFILE}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
		methodsServices
			.get(apiEndpoint)
			.then((response) => {
				dispatch({
					type: LOAD_MORE_PROFILES_SUCCESS,
					payload: response.data.data,
				});
			})
			.catch((error) => {
				dispatch({
					type: OPEN_SNACKBAR,
					payload: getFirstErrorFromResponse(error),
					severity: SERVERITY_ERROR,
				});
				dispatch({ type: LOAD_MORE_PROFILES_FAILED });
			});
	};
}

/**
 * Action Creator to open and close the Form dialog (profile)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const profilesFormDialog = (value) => {
	return {
		type: PROFILE_OPEN_FORM_DIALOG,
		payload: value,
	};
};
/**
 * Action Creator to open and close the update dialog (profile)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const profilesUpdateFormDialog = (value) => {
	return {
		type: PROFILE_UPADTE_FORM_DIALOG,
		payload: value,
	};
};
/**
 * Action Creator to open and close the delete dialog (profile)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogProfile = (value) => {
	return {
		type: DELETE_DIALOG_PROFILE,
		payload: value,
	};
};

import { makeStyles } from "@material-ui/core/styles";
import { RUBY_COLOR, GAMBOGE_COLOR } from "../../helpers";

export const useStyles = makeStyles({
	table: {
		minWidth: 650,
	},
	pagination: {
		marginTop: 5,
		display: "flex",
		justifyContent: "center",
		paddingTop: 10,
		paddingBottom: 10,
	},
	dividerStyle: { backgroundColor: "rgb(224, 224, 224)" },
	spinnerStyle: {
		textAlign: "center",
		marginTop: 20,
		marginBottom: 20,
		display: "flex",
		justifyContent: "center",
	},
	"@media screen and (max-width:1150px)": {
		gridStyle: {
			width: "100%",
		},
	},
	editButton: { color: "#009688" },
	deleteButton: { color: "#e82440fa" },
	displayButton: { color: "#ea910b" },
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	colorRuby: {
		color: RUBY_COLOR,
	},
	colorGamboge: {
		color: GAMBOGE_COLOR,
	},
	sectionsCell: {
		whiteSpace: "pre-line",
	},
	profileCell: {
		fontWeight: "bold",
		color: "#e91e63",
	},
	formTable: {
		paddingTop: "20px",
		"& td": {
			borderBottom: "none !important",
		},
	},
	formTablePagination: {
		"& ul": {
			justifyContent: "center",
		},
	},
	formRow: {
		"&:hover": {
			backgroundColor: "#fec94e59",
		},
	},
});

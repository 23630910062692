import {
	getFirstErrorFromResponse,
	history,
	SERVERITY_ERROR,
	SERVERITY_SUCCESS,
} from "../../helpers";
import {
	ENDPOINT_LOGIN,
	ENDPOINT_LOGOUT,
	ENDPOINT_PASSWORD_EMAIL,
	ENDPOINT_PASSWORD_RESET,
	PREFIX_ADMIN,
} from "../../helpers/endPoint";
import { methodsServices } from "../services";
import {
	CLEAR_ERRORS,
	LOADING_UI,
	OPEN_SNACKBAR,
	SET_ERRORS,
	STOP_LOADING_UI,
} from "../types";

export const authActions = {
	login,
	logout,
	sendMail,
	resetPassword,
};

/**
 * login an admin or an agent and saves the token and the role in the localstorage
 *
 * @param {string}  email
 * @param {string}  password
 * @param {boolean} rememberMe
 *
 * @returns function
 */
function login(email, password, rememberMe) {
	return (dispatch) => {
		dispatch({ type: LOADING_UI });
		dispatch({ type: CLEAR_ERRORS });
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_LOGIN}`;
		let formData = new FormData();

		formData.append("email", email);
		formData.append("password", password);
		formData.append("remember_me", rememberMe ? 1 : 0);

		methodsServices
			.post(apiEndpoint, formData)
			.then((response) => {
				if (response && response.data && response.data.admin) {
					localStorage.setItem("token", response.data.admin.access_token);
					localStorage.setItem("role", response.data.admin.data.role);
					localStorage.setItem("idUserConnected", response.data.admin.data.id);
					localStorage.setItem(
						"user",
						JSON.stringify(response.data.admin.data)
					);
					dispatch(setUserDetails(response.data.admin));
					dispatch({ type: STOP_LOADING_UI });
					history.push("/");
				}
			})
			.catch((error) => {
				dispatch({
					type: SET_ERRORS,
					payload: getFirstErrorFromResponse(error),
				});
			});
	};
}

/**
 * logout a user and clean the local storage
 *
 * @returns function
 */
function logout() {
	return (dispatch) => {
		methodsServices
			.post(ENDPOINT_LOGOUT)
			.then((response) => {
				localStorage.removeItem("role");
				localStorage.removeItem("token");
				localStorage.removeItem("idUserConnected");
				localStorage.removeItem("user");
				dispatch(logoutUser());
				history.push("/");
			})
			.catch((error) => {
				dispatch({
					type: SET_ERRORS,
					payload: getFirstErrorFromResponse(error),
				});
			});
	};
}

/**
 * Send a reset password email to a user
 *
 * @param {string} email
 *
 * @returns function
 */
function sendMail(email) {
	return (dispatch) => {
		dispatch({ type: LOADING_UI });
		dispatch({ type: CLEAR_ERRORS });
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PASSWORD_EMAIL}`;
		let formData = new FormData();

		formData.append("email", email);

		methodsServices
			.post(apiEndpoint, formData)
			.then((response) => {
				dispatch({ type: STOP_LOADING_UI });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "login.send.mail.success",
					severity: SERVERITY_SUCCESS,
				});
				history.push("/");
			})
			.catch((error) => {
				dispatch({
					type: SET_ERRORS,
					payload: getFirstErrorFromResponse(error),
				});
			});
	};
}

/**
 * Reset a user's password
 *
 * @param {string} token
 * @param {string} email
 * @param {string} password
 * @param {string} passwordConfirmation
 *
 * @returns function
 */
function resetPassword(token, email, password, passwordConfirmation) {
	return (dispatch) => {
		dispatch({ type: LOADING_UI });
		dispatch({ type: CLEAR_ERRORS });
		let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PASSWORD_RESET}`;
		let formData = new FormData();

		formData.append("email", email);
		formData.append("token", token);
		formData.append("password", password);
		formData.append("password_confirmation", passwordConfirmation);

		methodsServices
			.post(apiEndpoint, formData)
			.then((response) => {
				dispatch({ type: STOP_LOADING_UI });
				dispatch({
					type: OPEN_SNACKBAR,
					payload: "login.reset.password.success",
					severity: SERVERITY_SUCCESS,
				});
				history.push("/");
			})
			.catch((error) => {
				dispatch({
					type: SET_ERRORS,
					payload: getFirstErrorFromResponse(error),
				});
			});
	};
}

export function setUserDetails(user) {
	return {
		type: "LOGIN_SUCCESS",
		role: user.data.role,
		token: user.access_token,
		sections: user.data.profile ? user.data.profile.sections : [],
	};
}
export function logoutUser() {
	return {
		type: "LOGOUT_SUCCESS",
		role: false,
		token: "",
	};
}

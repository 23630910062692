import { CircularProgress, Tooltip, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import ClearIcon from "@material-ui/icons/Clear";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import ListIcon from "@material-ui/icons/List";
import VisibilityIcon from "@material-ui/icons/Visibility";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import React, { Fragment } from "react";
import { withI18n } from "react-i18next";
import { useSelector } from "react-redux";
import {
	customizeDate,
	ERROR_COLOR,
	INSCRIPTION_DATE,
	KEYS_CLIENT_ID,
	KEYS_CREATED_AT,
	KEYS_DELEGATION,
	KEYS_DESCRIPTION,
	KEYS_ENDA_AGENCY,
	KEYS_GOVERNORATE,
	KEYS_NAME,
	KEYS_ROLE,
	KEYS_PROFILE,
	KEYS_PROFILE_SECTIONS,
	KEYS_SEND_DATE,
	KEYS_STATUS,
	KEY_CLIENT,
	KEY_COUNT_BY_CLIENT,
	KEY_GOUVERNORAT_ID,
	SECONDARY_COLOR,
	TYPE_STATUS_WAITING,
	ALIGN_CENTER,
	ALIGN_LEFT,
	VARIANT_HEAD,
	PROCESSED_AT,
	TWO_DASHES,
	CLIENT_DELEGATION,
	getSectionsString,
} from "../../helpers";
import { useStyles } from "./styles";

/**
 *
 * @component
 *
 * @example
 * return (
 *   <BodyDynamicTable/>
 * )
 */
function BodyDynamicTable({
	listIcon = false,
	tbHeader,
	keys = [],
	tbBody = [],
	visibilityIcon = true,
	editIcon = true,
	deleteIcon = true,
	total,
	openUpdateFormDialog,
	openDeleteDialog,
	IdOfAdminConnected = null,
	loading,
	handleListIcon,
	t,
	handleShowBtn,
	countClientByid,
	displayRowsInfoRequestPanel = false,
	countStatueProcessed,
	countStatueWaiting,
	userOffline,
	userOnline,
	infoRequestPanelFirstRowsTitle = "informations.statistics.processed",
	infoRequestPanelSecondRowsTitle = "informations.statistics.waiting",
	infoRequestPanelThirdRowsTitle = "informations.statistics.total",
	totalInscriptions,
	displayRowsOfCreditRequestPanel,
	creditRequestPanelRowTitle,
	userEdit = false,
}) {
	const classes = useStyles();
	const clients = useSelector((state) => state.clients);

	//function contain the logic where the columns of a particular row will be returned
	const RenderRow = (props) => {
		return keys.map((key, index) => {
			switch (key) {
				//check if the key is role ( user role )
				case KEYS_ROLE:
					//if the user is an admin
					if (props.data[key] === 1)
						return (
							<TableCell align={ALIGN_CENTER} key={index}>
								<CheckBoxIcon color={SECONDARY_COLOR} />
							</TableCell>
						);
					//case : is not admin
					else return <TableCell key={index}></TableCell>;
				//check if the key is profile ( user profile )
				case KEYS_PROFILE:
					return (
						<TableCell key={index} className={classes.profileCell}>
							{props.data[key] ? props.data[key].name : ""}
						</TableCell>
					);
				//check if the key is section ( profile sections )
				case KEYS_PROFILE_SECTIONS:
					return (
						<TableCell key={index} className={classes.sectionsCell}>
							{props.data[key] && props.data[key].length > 0
								? getSectionsString(props.data[key])
								: ""}
						</TableCell>
					);
				//to display the name of delegation
				case KEYS_DELEGATION:
					return (
						props.data[key] && (
							<TableCell key={index}>
								{props.data[key][KEYS_DESCRIPTION]}
							</TableCell>
						)
					);
				// to display the name of delegation
				case KEYS_GOVERNORATE:
					return (
						props.data[key] && (
							<TableCell key={index}>
								{props.data[key][KEYS_DESCRIPTION]}
							</TableCell>
						)
					);
				//to display the name of agency
				case KEYS_ENDA_AGENCY:
					return (
						props.data[key] && (
							<TableCell key={index}>{props.data[key][KEYS_NAME]}</TableCell>
						)
					);
				//to display the type of status
				case KEYS_STATUS:
					//when the status equals 1 display "En attente"
					return props.data[key] === TYPE_STATUS_WAITING ? (
						<TableCell align={ALIGN_CENTER} key={index}>
							{t("common.waiting")}
						</TableCell>
					) : (
						//else display "Traitée"
						<TableCell align={ALIGN_CENTER} key={index}>
							{t("common.treaties")}
						</TableCell>
					);
				//check if he is a enda client or not
				case KEYS_CLIENT_ID:
					return props.data[key] ? (
						props.data[KEY_CLIENT] ? (
							<TableCell align={ALIGN_CENTER} key={index}>
								<CheckBoxIcon color={SECONDARY_COLOR} />
							</TableCell>
						) : (
							<TableCell align={ALIGN_CENTER} key={index}>
								<DeleteForeverIcon color={ERROR_COLOR} />
							</TableCell>
						)
					) : (
						<TableCell align={ALIGN_CENTER} key={index}>
							<ClearIcon color={ERROR_COLOR} />
						</TableCell>
					);
				//check if he is a enda client or not
				case CLIENT_DELEGATION:
					return props.data[KEYS_CLIENT_ID] ? (
						<TableCell key={index}>
							{props.data[KEY_CLIENT]
								? props.data[KEY_CLIENT].delegation.description
								: TWO_DASHES}
						</TableCell>
					) : (
						<TableCell key={index}>{TWO_DASHES}</TableCell>
					);
				//display the date of created_at , send_date, inscription_date
				case KEYS_CREATED_AT:
				case KEYS_SEND_DATE:
				case INSCRIPTION_DATE:
					return (
						<TableCell key={index}>{customizeDate(props.data[key])}</TableCell>
					);
				//get the name of grouvernorat_id
				case KEY_GOUVERNORAT_ID:
					return clients.gouvernorate.map((row, index) => {
						if (row.id == props.data[key])
							return <TableCell key={index}>{row.description}</TableCell>;
					});
				//number of client
				case KEY_COUNT_BY_CLIENT:
					return (
						<TableCell align={ALIGN_CENTER} key={index}>
							{getCountByClientId(props.data.id, countClientByid)}
						</TableCell>
					);
				case KEY_CLIENT:
					if (props.data[key] !== null)
						return (
							<Fragment key={index}>
								<TableCell align={ALIGN_CENTER} key={Math.random()}>
									<CheckBoxIcon color={SECONDARY_COLOR} />
								</TableCell>
								<TableCell align={ALIGN_CENTER} key={Math.random()}></TableCell>
							</Fragment>
						);
					else {
						return (
							<Fragment key={index}>
								<TableCell align={ALIGN_CENTER} key={Math.random()}></TableCell>
								<TableCell align={ALIGN_CENTER} key={Math.random()}>
									<CheckBoxIcon color={SECONDARY_COLOR} />
								</TableCell>
							</Fragment>
						);
					}
				case PROCESSED_AT:
					if (props.data[key] !== null) {
						return (
							<TableCell align={ALIGN_CENTER} key={index}>
								{customizeDate(props.data[key])}
							</TableCell>
						);
					} else {
						return (
							<TableCell align={ALIGN_CENTER} key={index}>
								{TWO_DASHES}
							</TableCell>
						);
					}
				default:
					return <TableCell key={index}>{props.data[key]}</TableCell>;
			}
		});
	};

	//function to render deleteIcon component with disbaled argument
	const renderDeleteButton = (disabled = false, id) => {
		return (
			<Tooltip title={t("common.action.delete")} aria-label="add">
				<span>
					<IconButton
						disabled={disabled}
						onClick={() => openDeleteDialog(id)}
						className={classes.deleteButton}
					>
						<DeleteIcon />
					</IconButton>
				</span>
			</Tooltip>
		);
	};
	const editIconComponent = (isDisabled, row) => {
		return (
			<Tooltip title={t("common.action.edit")} aria-label="add">
				<span>
					<IconButton
						onClick={() => openUpdateFormDialog(row)}
						disabled={isDisabled}
						className={classes.editButton}
					>
						<EditIcon />
					</IconButton>
				</span>
			</Tooltip>
		);
	};
	const renderEditIcon = (row) => {
		return editIconComponent(
			userEdit && IdOfAdminConnected != 1 && row.id == 1,
			row
		);
	};
	//return the all the row components.
	const getRowsData = () => {
		return tbBody.map((row, index) => {
			return (
				<TableRow key={index}>
					<RenderRow key={index} data={row} keys={keys} />
					{/* Display VisibilityIcon when the visibilityIcon (props) is true */}
					{listIcon && (
						<TableCell>
							<IconButton onClick={() => handleListIcon(row)}>
								<ListIcon />
							</IconButton>
						</TableCell>
					)}
					<TableCell>
						{visibilityIcon && (
							<Tooltip title={t("common.action.show")} aria-label="add">
								<span>
									<IconButton
										className={classes.displayButton}
										onClick={() => handleShowBtn(row)}
									>
										<VisibilityIcon />
									</IconButton>
								</span>
							</Tooltip>
						)}
						{/* Display EditIcon when the editIcon (props) is true */}
						{editIcon && renderEditIcon(row)}
						{/* Display DeleteIcon when the deleteIcon (props) is true */}
						{deleteIcon &&
							renderDeleteButton(
								userEdit && (row.id == 1 || row.id == IdOfAdminConnected),
								row.id
							)}
					</TableCell>
				</TableRow>
			);
		});
	};

	const reducer = (accumulator, currentValue) => accumulator + currentValue;

	// function to calculate the sum of password change in total
	const sumOfResetPassword = (countClientByid) => {
		let propertyValues = [];
		countClientByid.forEach((element) => {
			propertyValues.push(element.count);
		});

		return propertyValues.reduce(reducer);
	};

	const getCountByClientId = (id, array) => {
		const element = array.find((el) => el.clientId == id);
		return element.count;
	};

	const RenderBodyTable = () => {
		if (loading)
			return (
				<Fragment>
					<TableRow>
						<TableCell colSpan={tbHeader.length}>
							<div className={classes.spinnerStyle}>
								<CircularProgress color={SECONDARY_COLOR} />
							</div>
						</TableCell>
					</TableRow>
				</Fragment>
			);
		else if (tbBody.length > 0)
			return (
				<Fragment>
					{getRowsData()}
					{countClientByid && (
						<TableRow>
							<TableCell colSpan={tbHeader.length - 3}></TableCell>
							<TableCell variant={VARIANT_HEAD}>
								{t("resetPassword.nbr.total.changement")}
							</TableCell>
							<TableCell
								align={ALIGN_CENTER}
								variant={VARIANT_HEAD}
								className={classes.colorRuby}
							>
								{sumOfResetPassword(countClientByid)}
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					)}
					{displayRowsInfoRequestPanel && (
						<Fragment>
							<TableRow>
								<TableCell variant={VARIANT_HEAD} colSpan={tbHeader.length - 4}>
									{t(infoRequestPanelFirstRowsTitle)}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorGamboge}
								>
									{userOnline.countStatusProcessed}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorGamboge}
								>
									{userOffline.countStatusProcessed}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{countStatueProcessed}
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
							<TableRow>
								<TableCell variant={VARIANT_HEAD} colSpan={tbHeader.length - 4}>
									{t(infoRequestPanelSecondRowsTitle)}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorGamboge}
								>
									{userOnline.countStatusWaiting}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorGamboge}
								>
									{userOffline.countStatusWaiting}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{countStatueWaiting}
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
							<TableRow>
								<TableCell variant={VARIANT_HEAD} colSpan={tbHeader.length - 4}>
									{t(infoRequestPanelThirdRowsTitle)}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{userOnline.countStatusWaiting +
										userOnline.countStatusProcessed}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{userOffline.countStatusWaiting +
										userOffline.countStatusProcessed}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{countStatueProcessed + countStatueWaiting}
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</Fragment>
					)}
					{totalInscriptions && (
						<TableRow>
							<TableCell colSpan={tbHeader.length - 3}></TableCell>
							<TableCell variant={VARIANT_HEAD}>
								{t("clients.inscription.total")}
							</TableCell>
							<TableCell
								align={ALIGN_LEFT}
								variant={VARIANT_HEAD}
								className={classes.colorRuby}
							>
								{total}
							</TableCell>
							<TableCell></TableCell>
						</TableRow>
					)}
					{displayRowsOfCreditRequestPanel && (
						<Fragment>
							<TableRow>
								<TableCell colSpan={tbHeader.length - 3}></TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{userOnline.countClients}
								</TableCell>
								<TableCell
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{userOffline.countClients}
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
							<TableRow>
								<TableCell colSpan={tbHeader.length - 4}></TableCell>
								<TableCell
									align={ALIGN_LEFT}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{t(creditRequestPanelRowTitle)}
								</TableCell>
								<TableCell
									colSpan={2}
									align={ALIGN_CENTER}
									variant={VARIANT_HEAD}
									className={classes.colorRuby}
								>
									{userOffline.countClients + userOnline.countClients}
								</TableCell>
								<TableCell></TableCell>
							</TableRow>
						</Fragment>
					)}
				</Fragment>
			);
		else
			return (
				<Fragment>
					<TableRow>
						<TableCell colSpan={tbHeader.length}>
							<Typography
								className={classes.spinnerStyle}
								color={SECONDARY_COLOR}
							>
								{t("common.empty.data")}
							</Typography>
						</TableCell>
					</TableRow>
				</Fragment>
			);
	};
	return <TableBody>{RenderBodyTable()}</TableBody>;
}
const BodyDynamicTableHOC = withI18n()(BodyDynamicTable); //Higher-Order Component
export { BodyDynamicTableHOC as BodyDynamicTable };

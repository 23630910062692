import {
  DEFAULT_ORDER_DIRECTION,
  getFirstErrorFromResponse,
  TYPE_CLAIM_REQUEST,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';
import {
  ENDPOINT_CLAIM,
  ENDPOINT_STATUS,
  ORDER_BY,
  ORDER_DIRECTION,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
  SEARCH,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  CLAIM_REQUEST_LOADING_BTN,
  CLAIM_UPADTE_FORM_DIALOG,
  ENABLE_BTN_CLAIM_REAQUEST,
  GET_CLAIM_REQUEST,
  GET_CLAIM_REQUEST_FAILED,
  GET_CLAIM_REQUEST_SUCCESS,
  OPEN_SNACKBAR,
  UPDATE_CLAIM_REQUEST,
  UPDATE_CLAIM_REQUEST_FAILED,
  UPDATE_CLAIM_REQUEST_SUCCESS,
} from '../types';

/**
 * Action Creator to get all claims
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchClaimRequest(
  page,
  perPage,
  status = 0,
  search = '',
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION
) {
  return (dispatch) => {
    dispatch({ type: GET_CLAIM_REQUEST });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_CLAIM}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}${SEARCH}${search}${ENDPOINT_STATUS}${
      status ? status : 0
    }`;
    if (orderBy)
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_CLAIM_REQUEST_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_CLAIM_REQUEST_FAILED });
      });
  };
}
/**
 * Action Creator to update info request
 *
 * @param {String} statusOfRequest
 * @param {String} note
 * @param {int} id
 * @param {int} page
 * @param {int} perPage
 * @param {string} status
 *
 * @returns function contains an action
 */
export function upadteClaimRequest(
  statusOfRequest,
  note,
  page,
  perPage,
  id,
  status,
  search,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    dispatch({ type: UPDATE_CLAIM_REQUEST });
    let formData = new FormData();
    if (note) formData.append('note', note);
    formData.append('status', statusOfRequest);
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_CLAIM}/${id}`;
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(
          fetchClaimRequest(
            page,
            perPage,
            status,
            search,
            orderBy,
            orderDirection
          )
        );
        dispatch({
          type: UPDATE_CLAIM_REQUEST_SUCCESS,
          payload: response.data.data,
        });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'clients.updated',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: UPDATE_CLAIM_REQUEST_FAILED });
      });
  };
}
/**
 * Action creator to display spinner in the btn
 *
 * @returns function contains an action
 */
export const loadingBtnClaimRequest = () => {
  return { type: CLAIM_REQUEST_LOADING_BTN };
};
/**
 * Action Creator to open and close the update dialog (claimRequest)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const claimUpdateFormDialog = (value) => {
  return {
    type: CLAIM_UPADTE_FORM_DIALOG,
    payload: value,
  };
};

/**
 * Action creator to enable and disbale btn
 * @param {Boolean} value
 * @returns function contains an action
 */
export const enableBtnClaimRequest = (value) => {
  return { type: ENABLE_BTN_CLAIM_REAQUEST, payload: value };
};

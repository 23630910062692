import {
  DEFAULT_ORDER_DIRECTION,
  getFirstErrorFromResponse,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';
import {
  ENDPOINT_CREDIT,
  ENDPOINT_PACK,
  ORDER_BY,
  ORDER_DIRECTION,
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  CHANGE_DISABLED_DIALOG,
  CREDITS_CREATED_SUCCESS,
  CREDITS_FAILED,
  CREDITS_OPEN_FORM_DIALOG,
  CREDITS_UPADTE_FORM_DIALOG,
  DELETED_CREDIT_SUCCESS,
  DELETE_DIALOG_CREDIT,
  GET_CREDIT,
  GET_CREDIT_FAILED,
  GET_CREDIT_SUCCESS,
  LOADING_CREDITS,
  OPEN_SNACKBAR,
  UPDATE_CREDITS_SUCCESS,
} from '../types';
import { history } from '../../helpers';

/**
 * Action Creator to get all credit related to product
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function getCreditRelatedPack(
  id,
  page,
  perPage,
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION
) {
  return (dispatch) => {
    dispatch({ type: GET_CREDIT });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}/${id}${ENDPOINT_CREDIT}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;

    if (orderBy) {
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    }
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_CREDIT_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        if (error && error.status === 404) history.replace('/404');
        else
          dispatch({
            type: OPEN_SNACKBAR,
            payload: getFirstErrorFromResponse(error),
            severity: SERVERITY_ERROR,
          });
        dispatch({ type: GET_CREDIT_FAILED });
      });
  };
}
/**
 * Action Creator to create a new credit
 *
 * @param {int} id
 * @param {String} name
 * @param {String} description
 * @param {String} amount
 * @param {File} image
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function createCredit(
  name,
  description,
  amount,
  image,
  packId,
  page,
  perPage,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}/${packId}${ENDPOINT_CREDIT}`;
    let formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('amount', amount);
    formData.append('image', image);
    dispatch({ type: LOADING_CREDITS });
    methodsServices
      .post(apiEndpoint, formData, true)
      .then(() => {
        dispatch({ type: CREDITS_CREATED_SUCCESS });
        dispatch(
          getCreditRelatedPack(packId, page, perPage, orderBy, orderDirection)
        );
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'banners.created',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: CREDITS_FAILED });
      });
  };
}
/**
 * Action Creator to update credit
 *
 * @param {String} name
 * @param {String} description
 * @param {String} amount
 * @param {File} image
 *
 * @returns function contains an action
 */
export function updateCredit(
  name,
  description,
  amount,
  image,
  packId,
  creditId,
  page,
  perPage,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('amount', amount);
    if (image) formData.append('image', image);

    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}${ENDPOINT_CREDIT}/${creditId}`;
    dispatch({ type: LOADING_CREDITS });
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(
          getCreditRelatedPack(packId, page, perPage, orderBy, orderDirection)
        );
        dispatch({ type: UPDATE_CREDITS_SUCCESS, payload: response.data.data });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'users.uptated',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: CREDITS_FAILED });
      });
  };
}
/**
 * Action Creator to delete banner and call getCreditRelatedPack action to reload data
 *
 * @param {int} id
 * @param {int} packId
 * @param {int} currentPage
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export const deleteCredit = (
  id,
  packId,
  currentPage,
  perPage,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}${ENDPOINT_CREDIT}/${id}`;
    methodsServices
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(
          getCreditRelatedPack(
            packId,
            currentPage,
            perPage,
            orderBy,
            orderDirection
          )
        );
        dispatch({ type: DELETED_CREDIT_SUCCESS, payload: id });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'credits.deleted',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      });
  };
};
/**
 * Action Creator to open and close the Form dialog (credits)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const creditFormDialog = (value) => {
  return {
    type: CREDITS_OPEN_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the update dialog (credits)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const creditsUpdateFormDialog = (value) => {
  return {
    type: CREDITS_UPADTE_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the delete dialog (credit)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogCredit = (value) => {
  return {
    type: DELETE_DIALOG_CREDIT,
    payload: value,
  };
};

import {
  getFirstErrorFromResponse,
  ERROR_NETWORK,
  DEFAULT_ORDER_DIRECTION,
  SERVERITY_ERROR,
  SERVERITY_SUCCESS,
} from '../../helpers';
import {
  PAGE,
  ENDPOINT_PER_PAGE,
  PREFIX_ADMIN,
  ENDPOINT_PACK,
  ORDER_DIRECTION,
  ORDER_BY,
} from '../../helpers/endPoint';
import { methodsServices } from '../services';
import {
  GET_PRODUCTS_FAILED,
  GET_PRODUCTS_SUCCESS,
  OPEN_SNACKBAR,
  PRODUCT_OPEN_FORM_DIALOG,
  LOADING_PRODUCT,
  PRODUCT_CREATED_SUCCESS,
  PRODUCT_FAILED,
  PRODUCT_UPADTE_FORM_DIALOG,
  UPDATE_PRODUCT_SUCCESS,
  DELETE_DIALOG_PRODUCT,
  CHANGE_DISABLED_DIALOG,
  DELETED_PRODUCT_SUCCESS,
} from '../types';

/**
 * Action Creator to get all pack
 *
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function fetchProducts(
  page = 1,
  perPage = 5,
  orderBy = '',
  orderDirection = DEFAULT_ORDER_DIRECTION
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}${PAGE}${page}${ENDPOINT_PER_PAGE}${perPage}`;
    if (orderBy)
      apiEndpoint = `${apiEndpoint}${ORDER_BY}${orderBy}${ORDER_DIRECTION}${orderDirection}`;
    methodsServices
      .get(apiEndpoint)
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_SUCCESS,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: GET_PRODUCTS_FAILED });
      });
  };
}
/**
 * Action Creator to create a new pack
 *
 * @param {String} name
 * @param {String} description
 * @param {String} eligibilityCriteria
 * @param {String} treatmenDelays
 * @param {String} documents
 * @param {int} page
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export function CreatePack(
  name,
  description,
  eligibilityCriteria,
  treatmenDelays,
  documents,
  page,
  perPage,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}`;
    let formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('eligibility_criteria', eligibilityCriteria || '');
    formData.append('treatment_delays', treatmenDelays || '');
    formData.append('required_documents', documents || '');
    dispatch({ type: LOADING_PRODUCT });
    methodsServices
      .post(apiEndpoint, formData, true)
      .then(() => {
        dispatch({ type: PRODUCT_CREATED_SUCCESS });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'products.created',
          severity: SERVERITY_SUCCESS,
        });
        dispatch(fetchProducts(page, perPage, orderBy, orderDirection));
      })
      .catch((error) => {
        dispatch({ type: PRODUCT_FAILED });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: PRODUCT_FAILED });
      });
  };
}
/**
 * Action Creator to update pack
 *
 * @param {String} name
 * @param {String} description
 * @param {String} eligibilityCriteria
 * @param {String} treatmenDelays
 * @param {String} documents
 * @param {int} id
 *
 * @returns function contains an action
 */
export function updateProduct(
  name,
  description,
  eligibilityCriteria,
  treatmenDelays,
  documents,
  id,
  page,
  perPage,
  orderBy,
  orderDirection
) {
  return (dispatch) => {
    let formData = new FormData();
    formData.append('name', name);
    formData.append('description', description);
    formData.append('eligibility_criteria', eligibilityCriteria || '');
    formData.append('treatment_delays', treatmenDelays || '');
    formData.append('required_documents', documents || '');
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}/${id}`;
    dispatch({ type: LOADING_PRODUCT });
    methodsServices
      .post(apiEndpoint, formData, true)
      .then((response) => {
        dispatch(fetchProducts(page, perPage, orderBy, orderDirection));
        dispatch({ type: UPDATE_PRODUCT_SUCCESS, payload: response.data.data });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'users.uptated',
          severity: SERVERITY_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });

        dispatch({ type: PRODUCT_FAILED });
      });
  };
}
/**
 * Action Creator to delete pack and call fetchProducts action to reload data
 *
 * @param {int} id
 * @param {int} currentPage
 * @param {int} perPage
 *
 * @returns function contains an action
 */
export const deleteProduct = (
  id,
  currentPage,
  perPage,
  orderBy,
  orderDirection
) => {
  return (dispatch) => {
    dispatch({ type: CHANGE_DISABLED_DIALOG, payload: true });
    let apiEndpoint = `${PREFIX_ADMIN}${ENDPOINT_PACK}/${id}`;
    methodsServices
      .deleteDetail(apiEndpoint)
      .then((response) => {
        dispatch(fetchProducts(currentPage, perPage, orderBy, orderDirection));
        dispatch({ type: DELETED_PRODUCT_SUCCESS, payload: id });
        dispatch({
          type: OPEN_SNACKBAR,
          payload: 'credits.deleted',
          severity: SERVERITY_SUCCESS,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      })
      .catch((error) => {
        dispatch({
          type: OPEN_SNACKBAR,
          payload: getFirstErrorFromResponse(error),
          severity: SERVERITY_ERROR,
        });
        dispatch({ type: CHANGE_DISABLED_DIALOG, payload: false });
      });
  };
};
/**
 * Action Creator to open and close the Form dialog (pack)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const productFormDialog = (value) => {
  return {
    type: PRODUCT_OPEN_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the update dialog (pack)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const productsUpdateFormDialog = (value) => {
  return {
    type: PRODUCT_UPADTE_FORM_DIALOG,
    payload: value,
  };
};
/**
 * Action Creator to open and close the delete dialog (pack)
 *
 * @param {boolean} value
 *
 * @returns an action
 */
export const changeDeleteDialogProduct = (value) => {
  return {
    type: DELETE_DIALOG_PRODUCT,
    payload: value,
  };
};
